import { useEffect, useRef, useState } from "react";

import { getAccumulateData } from "./getAccumulateData";
import { paginate } from "./paginate";

interface UsePaginationProps<T> {
  defaultItemList?: T[];
  initPage: number;
  items: T[];
  size: number;
}

export interface UsePaginationReturnType<T> {
  previousPage: number;
  nextPage: number;
  itemList: T[];
  totalCount: number;
  loadNextPage: () => void;
  loadPreviousPage: () => void;
}

export const usePagination = <T>({
  items = [],
  initPage,
  size,
  defaultItemList,
}: UsePaginationProps<T>): UsePaginationReturnType<T> => {
  const totalItem = items.length;

  const [totalCount, setTotalCount] = useState(totalItem);
  const itemList = useRef(defaultItemList ?? []);

  const [previousPage, setPreviousPage] = useState(initPage - 1);
  const [nextPage, setNextPage] = useState(initPage + 1);

  useEffect(() => {
    setPreviousPage(initPage - 1);
    setNextPage(initPage + 1);
  }, [initPage]);

  useEffect(() => {
    const { start, end } = paginate({
      total: totalItem,
      current: initPage,
      size,
    });

    const display = totalItem > 0 ? items?.slice(start, end + 1) : [];
    itemList.current = display;

    setTotalCount(totalItem);
  }, [items, initPage, size, totalItem]);

  const loadPreviousPage = () => {
    const newItemList = getAccumulateData<T>(
      items,
      itemList.current,
      previousPage,
      size,
      false
    );
    itemList.current = newItemList;
    setPreviousPage((page) => page - 1);
  };

  const loadNextPage = () => {
    const newItemList = getAccumulateData<T>(
      items,
      itemList.current,
      nextPage,
      size
    );
    itemList.current = newItemList;
    setNextPage((page) => page + 1);
  };

  return {
    previousPage,
    nextPage,
    totalCount,
    loadNextPage,
    loadPreviousPage,
    itemList: itemList.current,
  };
};
