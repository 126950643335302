import React from "react";

import { Box, Typography, styled } from "@mui/material";
import { useParams } from "next/navigation";

import { Link } from "~/bff/types/Link";
import { CombinedLink } from "~/components/CombinedLink";
import { CTALinkButton } from "~/components/CTALinkButton";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { getLinkUrl } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { CarouselLayoutProps } from "../Carousel.types";

const PREFIX = "StyledCarouselHeader";
const classes = {
  root: `${PREFIX}-root`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
};

const StyledCarouselHeader = styled(Box, {
  shouldForwardProp: (props) => props !== "layout",
})<CarouselLayoutProps>(({ theme, layout }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      zIndex: 1000,
      display: "flex",
      position: "relative",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      alignItems: layout === "right" ? "flex-start" : "flex-end",
      paddingLeft: layout === "right" ? theme.spacing(2) : theme.spacing(0),
      paddingRight: layout === "right" ? theme.spacing(0) : theme.spacing(2),

      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: layout === "right" ? theme.spacing(4) : theme.spacing(0),
        paddingRight: layout === "right" ? theme.spacing(0) : theme.spacing(4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "block",
        maxWidth: "20%",
        position: "absolute",
        top: theme.spacing(5),
        left: layout === "right" ? theme.spacing(8) : "inherit",
        right: layout === "left" ? theme.spacing(8) : "inherit",
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
      },
      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        maxWidth: "24%",
      },
    },
    [`& .${classes.subtitle}`]: {
      zIndex: 1,
      position: "relative",
      marginBottom: theme.spacing(1),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(2),
      },
    },
    [`& .${classes.title}`]: {
      zIndex: 1,
      position: "relative",
      marginBottom: theme.spacing(1),
      ...theme.typography.h4,

      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.h3,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(2),
        ...theme.typography.h3,
      },
    },
  };
});

export interface CarouselHeaderProps extends CarouselLayoutProps {
  subtitle?: Nullable<string>;
  title?: Nullable<string>;
  link?: Nullable<Link>;
}

export const CarouselHeader = ({
  link,
  title,
  subtitle,
  layout = "right",
}: CarouselHeaderProps) => {
  const params = useParams();

  return (
    <StyledCarouselHeader
      layout={layout}
      className={classes.root}
      {...getTestAutomationProps("carousel-header")}
    >
      <Typography
        variant="body1"
        className={classes.subtitle}
        {...getTestAutomationProps("carousel-header-subtitle")}
      >
        {subtitle}
      </Typography>
      <Typography
        className={classes.title}
        variant="h2"
        {...getTestAutomationProps("carousel-header-title")}
      >
        {title}
      </Typography>
      {Boolean(link) && (
        <CombinedLink
          href={getLinkUrl(params?.locale as string, link?.url as string)}
          underline="none"
        >
          <CTALinkButton
            className={classes.link}
            typographyProps={{ variant: "h3" }}
            {...getTestAutomationProps("carousel-header-link")}
          >
            {link?.label ?? ""}
          </CTALinkButton>
        </CombinedLink>
      )}
    </StyledCarouselHeader>
  );
};
