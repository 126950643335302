import React from "react";

import { Box, Typography } from "@mui/material";

import { HeroBannerProps } from "~/bff/components/HeroBanner";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { CTALinkButton } from "~/components/CTALinkButton";
import { Image } from "~/components/Image";
import { TextColor } from "~/theme/constants/typography";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { IMAGE_TEMPLATES } from "./HeroBanner.constants";
import { StyledHeroBanner, CLASSES } from "./HeroBanner.styles";

export const HeroBanner: React.FC<HeroBannerProps> = ({
  title,
  text,
  description,
  link,
  image,
  textColor,
}) => {
  return (
    <StyledHeroBanner
      color={textColor as string}
      className={CLASSES.root}
      {...getTestAutomationProps("hero-banner")}
    >
      <Box className={CLASSES.banner}>
        <CombinedLink
          href={link?.url ?? ""}
          className={CLASSES.backgroundLink}
          underline="none"
          display="block"
        >
          <Image
            className={CLASSES.backgroundImage}
            loading="lazy"
            src={getImageSrc(image?.source as ImageSource)}
            alt={prepareTextAttribute(image?.alt as string, true)}
            title={prepareTextAttribute(image?.title as string)}
            imageTemplates={IMAGE_TEMPLATES}
            {...prepareImageA11yProps(image)}
          />
        </CombinedLink>
        <Box className={CLASSES.textBlock} {...getTestAutomationProps(`info-block`)}>
          <CombinedLink
            className={CLASSES.infoLink}
            href={link?.url ?? ""}
            underline="none"
            display="block"
          >
            <Typography
              variant="h1ExtraSmall"
              className={CLASSES.title}
              {...getTestAutomationProps(`category-title`)}
            >
              {title}
            </Typography>
            <Typography
              {...getTestAutomationProps(`title`)}
              variant="h2"
              className={CLASSES.header}
            >
              {text}
            </Typography>
            <Typography
              component="p"
              variant="h6"
              {...getTestAutomationProps(`description`)}
            >
              {description}
            </Typography>
          </CombinedLink>

          <CTALinkButton
            href={link?.url ?? ""}
            linkTextClassName={CLASSES.ctaLinkText}
            textColor={textColor as TextColor}
          >
            {link?.label ?? ""}
          </CTALinkButton>
        </Box>
      </Box>
    </StyledHeroBanner>
  );
};
