import React, { useMemo, useCallback } from "react";

import { styled, Grid } from "@mui/material";
import { useSearchParams, usePathname } from "next/navigation";

import { MediaAsset } from "~/bff/types/MediaAsset";
import { LoadMoreButton } from "~/components/LoadMoreButton";
import { LoadPreviousButton } from "~/components/LoadPreviousButton";
import { DEFAULT_PAGE_SIZE } from "~/constants/common";
import { usePagination } from "~/hooks/usePagination";
import { getTestAutomationProps } from "~/utils/testUtils";
import { updatePageNumberOnUrl } from "~/utils/updatePageNumberOnUrl";

import { AssetTile } from "./AssetTile";

interface AssetsProps {
  loadMoreButtonText?: string;
  loadPreviousButtonText?: string;
  progressBarText?: string;
  assets: MediaAsset[];
  defaultAssets: MediaAsset[];
  initPage: number;
}

const PREFIX = "StyledAssets";
const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
};

const StyledAssets = styled(Grid)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      paddingTop: theme.spacing(4),
    },
  };
});

export const Assets = ({
  assets,
  defaultAssets,
  initPage,
  loadMoreButtonText,
  loadPreviousButtonText,
  progressBarText,
}: AssetsProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {
    totalCount,
    itemList,
    previousPage,
    nextPage,
    loadNextPage,
    loadPreviousPage,
  } = usePagination<MediaAsset>(
    useMemo(
      () => ({
        defaultItemList: defaultAssets,
        initPage,
        items: assets,
        size: DEFAULT_PAGE_SIZE,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [assets, initPage]
    )
  );

  const handleLoadPreviousAssets = () => {
    loadPreviousPage();
    updatePageNumberOnUrl({
      pageNumber: previousPage,
      pathname,
      searchParams,
    });
  };

  const loadMoreAssets = useCallback(() => {
    loadNextPage();
    updatePageNumberOnUrl({
      pageNumber: nextPage,
      pathname,
      searchParams,
    });
  }, [loadNextPage, nextPage, pathname, searchParams]);

  return (
    <>
      {previousPage > 0 && (
        <LoadPreviousButton
          loadPreviousButtonText={loadPreviousButtonText}
          pageSize={DEFAULT_PAGE_SIZE}
          onClick={handleLoadPreviousAssets}
          currentPage={previousPage + 1}
        />
      )}
      <StyledAssets
        container
        spacing={3}
        className={classes.root}
        {...getTestAutomationProps("media-assets")}
      >
        {itemList?.map((asset) => (
          <AssetTile
            key={`${nextPage + String(previousPage)}-${asset?._meta?.deliveryId}`}
            {...asset.props}
          />
        ))}
      </StyledAssets>
      <LoadMoreButton
        totalCount={totalCount}
        pageSize={DEFAULT_PAGE_SIZE}
        loadMoreButtonText={loadMoreButtonText}
        progressBarText={progressBarText}
        onClick={loadMoreAssets}
        currentPage={nextPage - 1}
      />
    </>
  );
};
