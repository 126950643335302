import React from "react";

import { Box, styled } from "@mui/material";

import { Image } from "~/bff/types/Image";
import { GlobalNavigationLandscape } from "~/components/GlobalNavigation/components/GlobalNavigationLandscape";
import { LinkedLogo } from "~/components/Logo/components/LinkedLogo";
import { ROUTES } from "~/constants/routes";
import { COLOR_WHITE } from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { HeaderExtendedProps } from "../../Header.types";
import { HeaderNavLinksLandscape } from "../HeaderNavLinksLandscape/HeaderNavLinksLandscape";

const PREFIX = "StyledHeaderLandscape";
const classes = {
  root: `${PREFIX}-root`,
  logoWrapper: `${PREFIX}-logoWrapper`,
};

const StyledHeaderLandscape = styled("header")(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "none",
      alignItems: "stretch",
      flexDirection: "column",
      backgroundColor: COLOR_WHITE,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(3),

      [`${formatMedia.BREAKPOINT_DESKTOP} and (orientation: landscape)`]: {
        display: "flex",
      },

      [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${classes.logoWrapper}`]: {
      height: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

export const HeaderLandscape = ({
  logo,
  navigationLinks,
  globalNavigation,
}: HeaderExtendedProps) => {
  return (
    <StyledHeaderLandscape
      className={classes.root}
      {...getTestAutomationProps("header-landscape")}
    >
      <HeaderNavLinksLandscape links={navigationLinks} />
      <Box className={classes.logoWrapper}>
        <LinkedLogo
          size="large"
          href={ROUTES.HOME}
          image={logo as Image}
          testAutomationId="header-logo"
        />
      </Box>
      <GlobalNavigationLandscape globalNavigation={globalNavigation} />
    </StyledHeaderLandscape>
  );
};
