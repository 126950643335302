import React, { SyntheticEvent, useCallback, useState, useMemo } from "react";

import {
  styled,
  Container,
  Box,
  Typography,
  MenuItem,
  TextField,
  Button,
  Slide,
} from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

import { MediaEnquiriesProps } from "~/bff/components/MediaEnquiries";
import { ImageSource } from "~/bff/types/ImageSource";
import { DropdownControl } from "~/components/DropdownControl";
import { Image } from "~/components/Image";
import { Toast } from "~/components/Toast";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledMediaEnquiries";
const classes = {
  root: `${PREFIX}-root`,
  titles: `${PREFIX}-titles`,
  headerImage: `${PREFIX}-headerImage`,
  container: `${PREFIX}-container`,
  formContainer: `${PREFIX}-formContainer`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  emailInput: `${PREFIX}-emailInput`,
};

const StyledMediaEnquiries = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: "relative",
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${classes.headerImage}`]: {
      flex: 1,
      display: "flex",
      width: "100%",
      [formatMedia.BREAKPOINT_TABLET]: {
        position: "absolute",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        aspectRatio: "4 / 3",
        [formatMedia.BREAKPOINT_TABLET]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
        [formatMedia.BREAKPOINT_DESKTOP]: {
          height: 550,
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
    },
    [`& .${classes.formContainer}`]: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      marginTop: theme.spacing(3),
      backgroundColor: COLOR_PRIMARY_WHITE,
      zIndex: 1,
      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(4),
        maxWidth: 462,
        padding: `calc(${theme.spacing(4)} - 2px)`,
        border: `2px solid ${COLOR_ADDITIONAL_WARM_GRAY_3U}`,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginTop: theme.spacing(6),
        width: "50%",
        maxWidth: 644,
        padding: `calc(${theme.spacing(6)} - 2px)`,
        border: `2px solid ${COLOR_ADDITIONAL_WARM_GRAY_3U}`,
      },
    },
    [`& .${classes.title}`]: {
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(1),
      },
    },
    [`& .${classes.description}`]: {
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_SMALL}`
      ] as CSSProperties),
      [formatMedia.BREAKPOINT_TABLET]: {
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_LARGE}`
        ] as CSSProperties),
      },
    },
    [`& .${classes.emailInput}`]: {
      marginBottom: theme.spacing(1),
      "& input": {
        textFillColor: COLOR_PRIMARY_BLACK,
      },
    },
  };
});

export const MediaEnquiries = ({
  description,
  title,
  image,
  enquiryType,
  market,
  email,
  enquiries,
  buttonText,
  toastMessageText,
  testAutomationId = "media-enquiries",
}: MediaEnquiriesProps) => {
  const [enquiryTypeValue, setEnquiryTypeValue] = useState("");
  const [enquiryMarketValue, setEnquiryMarketValue] = useState("");
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const markets = useMemo(
    () =>
      enquiries?.find((enquiry) => enquiry?.enquiryType === enquiryTypeValue)
        ?.markets,
    [enquiries, enquiryTypeValue]
  );

  const emailValue = useMemo(
    () =>
      markets?.find((market) => market?.marketName === enquiryMarketValue)?.email ??
      "",
    [markets, enquiryMarketValue]
  );

  const handleChangeEnquiryType = useCallback(
    (event: SyntheticEvent) => {
      setEnquiryTypeValue((event.target as HTMLInputElement).value);
      setEnquiryMarketValue("");
    },
    [setEnquiryTypeValue]
  );

  const handleChangeMarket = useCallback(
    (event: SyntheticEvent) => {
      setEnquiryMarketValue((event.target as HTMLInputElement).value);
    },
    [setEnquiryMarketValue]
  );

  const copyEmailToClipboard = useCallback(() => {
    navigator.clipboard.writeText(emailValue).then(() => {
      setIsToastOpen(true);
    });
  }, [emailValue]);

  return (
    <StyledMediaEnquiries
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      <Toast
        open={isToastOpen}
        onClose={() => setIsToastOpen(false)}
        autoHideDuration={5000}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Typography>{toastMessageText}</Typography>
      </Toast>
      <Box className={classes.headerImage}>
        <Image
          src={getImageSrc(image?.props?.source as ImageSource)}
          alt={prepareTextAttribute(image?.props?.alt as string, true)}
          title={prepareTextAttribute(image?.props?.title as string)}
          {...prepareImageA11yProps(image?.props)}
        />
      </Box>
      <Box className={classes.formContainer}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
        <DropdownControl
          inputProps={getTestAutomationProps("input-enquiry-type")}
          name={enquiryType?.name as string}
          label={enquiryType?.name}
          placeholder={enquiryType?.placeholderText as string}
          SelectProps={{
            SelectDisplayProps: { "aria-label": enquiryType?.name as string },
          }}
          value={enquiryTypeValue}
          onChange={handleChangeEnquiryType}
        >
          {enquiries?.map((enquiry) => (
            <MenuItem
              value={enquiry?.enquiryType as string}
              key={enquiry?.enquiryType as string}
            >
              {enquiry?.enquiryType}
            </MenuItem>
          ))}
        </DropdownControl>
        <DropdownControl
          inputProps={getTestAutomationProps("input-market")}
          name={market?.name as string}
          label={market?.name as string}
          placeholder={market?.placeholderText as string}
          SelectProps={{
            SelectDisplayProps: { "aria-label": market?.name as string },
          }}
          value={enquiryMarketValue}
          onChange={handleChangeMarket}
        >
          {markets?.map((market) => (
            <MenuItem
              value={market?.marketName as string}
              key={market?.marketName as string}
            >
              {market?.marketName}
            </MenuItem>
          )) ?? <MenuItem value="" key="" />}
        </DropdownControl>
        <TextField
          inputProps={getTestAutomationProps("input-email")}
          name={email?.name as string}
          label={email?.name as string}
          placeholder={email?.placeholderText as string}
          value={emailValue}
          disabled
          className={classes.emailInput}
        />
        <Button
          {...getTestAutomationProps("button-copy-email")}
          variant="contained"
          size="medium"
          disabled={!emailValue}
          onClick={copyEmailToClipboard}
        >
          {buttonText}
        </Button>
      </Box>
    </StyledMediaEnquiries>
  );
};
