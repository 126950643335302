import { makeVar, useReactiveVar } from "@apollo/client";

import type { ExtraSeoMetaTagsProps } from "../SeoMetaTags.types";

export const seoMetaTagsReactiveVar = makeVar<ExtraSeoMetaTagsProps>({});

export const useSeoMetaTags = () => {
  const sharedData = useReactiveVar(seoMetaTagsReactiveVar);
  return sharedData;
};
