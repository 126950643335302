import React, { MouseEventHandler, useCallback } from "react";

import { Box, IconButton, styled, Typography } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

import { COLOR_ADDITIONAL_WARM_GRAY_3U } from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { Download, Pdf } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { downloadAsset } from "~/utils/downloadAsset";

const PREFIX = "StyledItem";
const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  titleWrapper: `${PREFIX}-titleWrapper`,
  title: `${PREFIX}-title`,
  downloadButton: `${PREFIX}-downloadButton`,
  buttonLabel: `${PREFIX}-buttonLabel`,
};

const StyledItem = styled("a")(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      display: "flex",
      gap: theme.spacing(2),
      padding: `${theme.spacing(1.125)} ${theme.spacing(1.75)}`,
      alignItems: "center",
      border: `2px solid ${COLOR_ADDITIONAL_WARM_GRAY_3U}`,
      justifyContent: "space-between",
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingBlock: theme.spacing(1.75),
      },
    },
    [`& .${classes.titleWrapper}`]: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    [`& .${classes.title}`]: {
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_SMALL}`
      ] as CSSProperties),
      [formatMedia.BREAKPOINT_TABLET]: {
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_LARGE}`
        ] as CSSProperties),
      },
    },
    [`& .${classes.downloadButton}`]: {
      padding: theme.spacing(0),
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    [`& .${classes.buttonLabel}`]: {
      fontSize: 0,
      height: 1,
      overflow: "hidden",
      display: "block",
    },
  };
});

export interface ItemProps {
  name?: Nullable<string>;
  title?: Nullable<string>;
  url?: Nullable<string>;
  fileSize?: Nullable<string>;
  type?: Nullable<string>;
}

export const Item = ({ name, type, fileSize, title, url }: ItemProps) => {
  const handleDownload: MouseEventHandler = useCallback(
    (e) => {
      const fullFileName = `${title}.${type?.toLowerCase()}`;
      const downloadUrl =
        type?.toLowerCase() === "pdf"
          ? String(url)
          : `${url}.${type?.toLowerCase()}`;

      e.preventDefault();

      downloadAsset(fullFileName, downloadUrl);
    },
    [title, type, url]
  );

  return (
    <StyledItem className={classes.root} href={url as string} target="_blank">
      <Box className={classes.titleWrapper}>
        <Pdf />
        <Typography className={classes.title}>
          {title}, {fileSize}MB
        </Typography>
      </Box>
      <IconButton className={classes.downloadButton} onClick={handleDownload}>
        <Download />
        <Typography className={classes.buttonLabel}>Download {name}</Typography>
      </IconButton>
    </StyledItem>
  );
};
