import React from "react";

import {
  Accordion as BaseAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  styled,
} from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";

import { AccordionProps } from "~/bff/components/Accordion";
import { Markdown } from "~/components/Markdown";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const StyledMarkdown = styled(Markdown)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    ...(theme.typography.body1[
      `&.${TypographyBodyClass.ARTICLE_SMALL}`
    ] as CSSProperties),
    [formatMedia.BREAKPOINT_TABLET]: {
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_LARGE}`
      ] as CSSProperties),
    },
  };
});

export const Accordion = ({ sections }: AccordionProps) => {
  return (
    <Container {...getTestAutomationProps("accordion-container")}>
      {sections?.map((section, idx) => (
        <BaseAccordion key={idx}>
          <AccordionSummary>
            <Typography>{section?.header}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledMarkdown text={section?.description ?? ""} />
          </AccordionDetails>
        </BaseAccordion>
      ))}
    </Container>
  );
};
