import React, {
  Children,
  KeyboardEvent,
  MouseEvent,
  useMemo,
  useState,
  FocusEvent,
  useCallback,
} from "react";

import { cx } from "@emotion/css";
import { styled, IconButton, TextField, TextFieldProps } from "@mui/material";
import { merge } from "lodash";

import { COLOR_PRIMARY_BLACK } from "~/theme/constants/colors";
import { Down24, Up24 } from "~/theme/icons";
import { Optional } from "~/types/utility.types";

export type DropdownControlProps = Omit<
  TextFieldProps & {
    anchorVerticalOrigin?: number;
    rect?: DOMRect;
    isTopVariant?: boolean;
  },
  "select" | "variant"
>;

const PREFIX = "StyledDropdownControl";
const classes = {
  root: `${PREFIX}-root`,
  visibleInput: `${PREFIX}-visibleInput`,
  paper: `${PREFIX}-paper`,
};

const StyledDropdownControl = styled(TextField, {
  shouldForwardProp: (prop: string) => prop !== "rect" && prop !== "isTopVariant",
})<DropdownControlProps>(({ theme, rect, isTopVariant }) => {
  let paperRules = {};
  if (rect) {
    const { top, height, width } = rect;
    const bottom = window.innerHeight - top - height;
    paperRules = {
      maxHeight: (isTopVariant ? top : bottom) - 40,
      width,
    };
  }
  return {
    [`&.${classes.root}`]: {
      "& .MuiFormLabel-root": {
        paddingBottom: theme.spacing(0.5),
      },
      "& .MuiSelect-icon": {
        top: `calc(50% - ${theme.spacing(2.5)})`,
        color: COLOR_PRIMARY_BLACK,
        right: theme.spacing(0.5),
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "40px",
      },
    },
    [`&.${classes.visibleInput}`]: {
      "& .MuiSelect-nativeInput": {
        height: "100%",
        width: `calc(100% - ${theme.spacing(4)}px)`,
        paddingLeft: theme.spacing(1.75),
        opacity: 1,
        border: "none",
        "&::placeholder": { fontSize: 16 },
      },
    },
    [`.${classes.paper}:not(& *)`]: paperRules,
  };
});

export const DropdownControl = ({ ...props }: DropdownControlProps) => {
  const childrenCount = useMemo(
    () => Children.count(props.children),
    [props.children]
  );
  const {
    id,
    className,
    SelectProps,
    anchorVerticalOrigin = 48,
    onBlur,
    onKeyDown,
    onMouseDown,
    ...restProps
  } = props;
  const [rect, setRect] = useState<Optional<DOMRect>>();
  const isTopVariant = useMemo(() => {
    if (!rect) {
      return false;
    }
    const { top, height } = rect;
    const approximatelyItemHeight = 40;
    const bottom = window.innerHeight - top - height;
    return (
      bottom - (childrenCount + 1) * approximatelyItemHeight < 0 && top > bottom
    );
  }, [rect, childrenCount]);

  const menuId = `menu-${props.id}`;
  const isPlaceholderVisible = props.value === "";

  const baseSelectProps = {
    inputProps: {
      id: props?.id,
    },
    MenuProps: {
      id: menuId,
      anchorOrigin: {
        vertical: isTopVariant ? -4 : anchorVerticalOrigin,
        horizontal: "left",
      },
      transformOrigin: {
        vertical: isTopVariant ? "bottom" : "top",
        horizontal: "left",
      },
      PopoverClasses: {
        paper: classes.paper,
      },
    },
    // eslint-disable-next-line react/no-multi-comp
    IconComponent: (props: { isOpen: boolean }) => {
      const { isOpen, ...rest } = props;
      return (
        <IconButton {...rest} aria-hidden="true" tabIndex={-1}>
          {isOpen ? <Up24 /> : <Down24 />}
        </IconButton>
      );
    },
  };
  const mergedSelectProps = merge(baseSelectProps, SelectProps);

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.code === "Enter" || event.code === "Space") {
        setRect(event.currentTarget.getBoundingClientRect());
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [onKeyDown]
  );

  const handleOnMouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setRect(event.currentTarget.getBoundingClientRect());
      if (onMouseDown) {
        onMouseDown(event);
      }
    },
    [onMouseDown]
  );

  return (
    <StyledDropdownControl
      {...restProps}
      rect={rect}
      isTopVariant={isTopVariant}
      className={cx(className, classes.root, {
        [classes.visibleInput]: isPlaceholderVisible,
      })}
      select
      onBlur={handleOnBlur}
      SelectProps={mergedSelectProps}
      onKeyDown={handleOnKeyDown}
      onMouseDown={handleOnMouseDown}
    />
  );
};
