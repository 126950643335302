import React from "react";

import { HeaderLandscape } from "./components/HeaderLandscape";
import { HeaderPortrait } from "./components/HeaderPortrait";
import { HeaderProvider } from "./Header.hooks";
import { HeaderExtendedProps } from "./Header.types";

export const Header = (props: HeaderExtendedProps) => {
  return (
    <HeaderProvider>
      <HeaderLandscape {...props} />
      <HeaderPortrait {...props} />
    </HeaderProvider>
  );
};
