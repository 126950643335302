import { ComponentsConfig, Definition } from "~/bff/ComponentsConfig";

import { SeoMetaTags, SeoExtended } from "./SeoMetaTags";

interface ExtendedComponentConfigs extends ComponentsConfig {
  SeoMetaTags: Definition<(props: SeoExtended) => React.ReactElement>;
}

export const SEO_META_TAGS_COMP_NAME = "SeoMetaTags";

export const SEO_META_TAGS_COMPONENT_CONFIG: ExtendedComponentConfigs = {
  [SEO_META_TAGS_COMP_NAME]: { component: SeoMetaTags },
};
