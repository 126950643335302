import React from "react";

import isPropValid from "@emotion/is-prop-valid";
import { IconButton, Toolbar, styled } from "@mui/material";

import { Image } from "~/bff/types/Image";
import { HEADER_HEIGHT_PORTRAIT } from "~/components/Header/Header.constants";
import { DIMENSIONS } from "~/components/Logo";
import { LinkedLogo } from "~/components/Logo/components/LinkedLogo";
import { ROUTES } from "~/constants/routes";
import { Close24, Menu24 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledHeaderToolbar";
const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
};

const StyledHeaderToolbar = styled(Toolbar, { shouldForwardProp: isPropValid })<{
  isInSideMenu?: boolean;
}>(({ theme, isInSideMenu }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      position: "relative",
      padding: `0 ${theme.spacing(1)}`,
      minHeight: HEADER_HEIGHT_PORTRAIT,

      [formatMedia.BREAKPOINT_TABLET]: {
        padding: isInSideMenu ? `0 ${theme.spacing(1)}` : `0 ${theme.spacing(3)}`,
      },
    },
    [`& .${classes.logo}`]: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      height: DIMENSIONS.height.medium,
      width: DIMENSIONS.width.medium,
      left: "50%",
      top: "50%",

      [formatMedia.BREAKPOINT_DESKTOP]: {
        height: DIMENSIONS.height.large,
        width: DIMENSIONS.width.large,
      },
    },
  };
});

export interface HeaderToolbarProps {
  logo?: Image;
  isMenuOpen?: boolean;
  onMenuOpen?: () => void;
  isInSideMenu?: boolean;
}

export const HeaderToolbar = ({
  logo,
  isMenuOpen,
  onMenuOpen,
  isInSideMenu = false,
}: HeaderToolbarProps) => {
  return (
    <StyledHeaderToolbar
      className={classes.root}
      isInSideMenu={isInSideMenu}
      {...getTestAutomationProps("header-toolbar")}
    >
      <IconButton
        color="inherit"
        onClick={onMenuOpen}
        aria-label={!isMenuOpen ? "open menu" : "close menu"}
        {...getTestAutomationProps("header-menu-button")}
      >
        {isMenuOpen ? <Close24 /> : <Menu24 />}
      </IconButton>
      <LinkedLogo
        size="medium"
        href={ROUTES.HOME}
        image={logo as Image}
        className={classes.logo}
        testAutomationId="header-logo"
      />
    </StyledHeaderToolbar>
  );
};
