import { ResponsiveImageConfig } from "~/components/Image";
import { AmplienceDynamicImageConfigKeys } from "~/components/Image/Image.types";

export const RESPONSIVE_IMAGE_CONFIG: ResponsiveImageConfig = {
  wideDesktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 1000,
  },
  desktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 750,
  },
  tablet: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 560,
  },
  mobile: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 320,
  },
};
