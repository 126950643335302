import React from "react";

import { BffComponent, ComponentsConfig } from "~/bff/ComponentsConfig";

import { UniversalComponent } from "./UniversalComponent";

export const prepareProps = (
  object: unknown,
  componentsConfigs: ComponentsConfig[],
  key?: string | number
): unknown => {
  const type = Object.prototype.toString.call(object).slice(8, -1);

  if (type === "Array") {
    const result: unknown[] = [];

    for (let index = 0; index < (object as unknown[]).length; index++) {
      result.push(
        prepareProps((object as unknown[])[index], componentsConfigs, index)
      );
    }

    return result;
  }

  if (type === "Object") {
    const bffComponent = object as BffComponent;

    if (bffComponent.component) {
      const { component, _meta, children, props } = bffComponent;

      return (
        <UniversalComponent
          key={key}
          meta={_meta}
          props={props}
          elements={children}
          component={component}
          componentsConfigs={componentsConfigs}
        />
      );
    }

    const result: Record<string, unknown> = {};

    for (const key in object as Record<string, unknown>) {
      if (!Object.prototype.hasOwnProperty.call(object, key)) {
        continue;
      }

      result[key] = prepareProps(
        (object as Record<string, unknown>)[key],
        componentsConfigs
      );
    }

    return result;
  }

  return object;
};
