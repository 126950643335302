"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { cx } from "@emotion/css";
import { Box, Button, styled } from "@mui/material";

import { ScrollDirection, useWindowScroll } from "~/hooks/useWindowScroll";
import { useWindowState } from "~/hooks/useWindowState";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_GREYSCALE_BLACK_5,
  COLOR_GREYSCALE_BLACK_8,
} from "~/theme/constants/colors";
import { Up16 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledBackToTopButton";
const classes = {
  root: `${PREFIX}-root`,
  isVisible: `${PREFIX}-isVisible`,
  isHidden: `${PREFIX}-isHidden`,
  up: `${PREFIX}-up`,
  caption: `${PREFIX}-caption`,
};

const StyledBackToTopButton = styled(Button)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      display: "none",
      position: "fixed",
      bottom: 32,
      right: 0,
      width: 48,
      height: 48,
      zIndex: 99,
      backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
      "&:hover": {
        backgroundColor: COLOR_GREYSCALE_BLACK_5,
      },
      "&:active": {
        backgroundColor: COLOR_GREYSCALE_BLACK_8,
      },
      "&.MuiButtonBase-root:before": {
        content: "none",
      },
      cursor: "pointer",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "block",
        transition: "300ms",
        transitionTimingFunction: theme.transitions.easing.easeOut,
      },
    },
    [`&.${classes.isVisible}`]: {
      visibility: "visible",
      right: 0,
    },
    [`&.${classes.isHidden}`]: {
      visibility: "hidden",
      right: -48,
    },
    [`& .${classes.up}`]: {
      display: "flex",
      justifyContent: "center",
    },
    [`& .${classes.caption}`]: {
      top: 28,
      left: 0,
      right: 0,
      position: "absolute",
      textAlign: "center",
      userSelect: "none",
    },
  };
});

const HEADER_HEIGHT = 244; //TODO calc header height dynamically
const SCROLL_OFFSET_THRESHOLD_COEF = 2;
export interface BackToTopButtonProps {
  shouldBeShownAlways?: boolean;
}

export const BackToTopButton = ({ shouldBeShownAlways = false }) => {
  const [isInitiated, setIsInitiated] = useState(false);

  const { scrollTop, scrollDirection } = useWindowScroll();

  const { height: windowHeight } = useWindowState();

  const scrollOffsetThreshold = useMemo(() => {
    return windowHeight * SCROLL_OFFSET_THRESHOLD_COEF;
  }, [windowHeight]);

  const isScrollingUp = useMemo(() => {
    return scrollDirection === ScrollDirection.UP;
  }, [scrollDirection]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (isInitiated) {
      return;
    }
    if (scrollTop > scrollOffsetThreshold && isScrollingUp) {
      setIsInitiated(true);
    }
  }, [scrollTop, isScrollingUp, isInitiated, scrollOffsetThreshold]);

  const shouldBeShown = useMemo(() => {
    return shouldBeShownAlways || isInitiated;
  }, [shouldBeShownAlways, isInitiated]);

  useEffect(() => {
    if (scrollTop < HEADER_HEIGHT || !isScrollingUp) {
      setIsInitiated(false);
    }
  }, [scrollTop, isScrollingUp]);

  return (
    <StyledBackToTopButton
      aria-label="back to top"
      className={cx(
        classes.root,
        { [classes.isVisible]: shouldBeShown },
        { [classes.isHidden]: !shouldBeShown }
      )}
      onClick={scrollToTop}
      {...getTestAutomationProps("back-to-top-button")}
    >
      <Box className={classes.up}>
        <Up16 />
      </Box>
    </StyledBackToTopButton>
  );
};
