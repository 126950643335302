import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation.react-server";

import { PARAMS } from "~/constants/request";

export const updateRouterQuery = ({
  selectedTab,
  allTabText,
  currentPage = 1,
  searchParams,
  pathname,
}: {
  selectedTab: string;
  allTabText: string;
  searchParams: ReadonlyURLSearchParams;
  currentPage?: number;
  pathname: string;
}) => {
  const query = new URLSearchParams(Array.from(searchParams.entries()));

  query.delete(PARAMS.TAB);
  query.delete(PARAMS.PAGE);

  if (selectedTab !== allTabText) {
    query.set(PARAMS.TAB, selectedTab);
  }
  if (currentPage > 1) {
    query.set(PARAMS.PAGE, String(currentPage));
  }

  // Using window.history.pushState instead of router.push (https://github.com/vercel/next.js/discussions/48110)
  window.history.pushState({ as: pathname }, "", `?${query.toString()}`);
};
