import React from "react";

import { styled, Container, Box, Typography } from "@mui/material";

import { GenericHeadingProps } from "~/bff/components/GenericHeading";
import { ImageSource } from "~/bff/types/ImageSource";
import { Image } from "~/components/Image";
import { PageTitle } from "~/components/PageTitle";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledGenericHeading";
const classes = {
  root: `${PREFIX}-root`,
  titles: `${PREFIX}-titles`,
  headerImage: `${PREFIX}-headerImage`,
  container: `${PREFIX}-container`,
  titlesContainer: `${PREFIX}-titlesContainer`,
  subTitle: `${PREFIX}-subTitle`,
};

const StyledGenericHeading = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      marginBottom: theme.spacing(7),
      padding: theme.spacing(0),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(8),
      },
      [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    [`& .${classes.container}`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "stretch",
      flexDirection: "column",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        flexDirection: "row",
      },
    },
    [`& .${classes.titlesContainer}`]: {
      backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
      position: "relative",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
      display: "flex",
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: `calc(50% + ${theme.spacing(1.5)})`,
        padding: theme.spacing(0),
      },
    },
    [`& .${classes.titles}`]: {
      backgroundColor: COLOR_PRIMARY_WHITE,
      flex: 1,
      padding: theme.spacing(3),
      transform: `translateY(-${theme.spacing(3)})`,
      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(6),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: theme.spacing(3),
        marginLeft: theme.spacing(-3),
        transform: "translateY(0)",
      },
    },
    [`& .${classes.subTitle}`]: {
      marginBottom: theme.spacing(2),
    },
    [`& .${classes.headerImage}`]: {
      flex: 1,
      display: "flex",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        aspectRatio: "4 / 3",
        [formatMedia.BREAKPOINT_DESKTOP]: {
          aspectRatio: "1 / 1",
        },
      },
    },
  };
});

export const GenericHeading = ({
  subtitle,
  title,
  image,
  testAutomationId = "generic-heading",
}: GenericHeadingProps) => {
  return (
    <StyledGenericHeading
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      <Box className={classes.container}>
        <Box className={classes.headerImage}>
          <Image
            src={getImageSrc(image?.props?.source as ImageSource)}
            alt={prepareTextAttribute(image?.props?.alt as string, true)}
            title={prepareTextAttribute(image?.props?.title as string)}
            {...prepareImageA11yProps(image?.props)}
          />
        </Box>
        <Box className={classes.titlesContainer}>
          <Box className={classes.titles}>
            <Typography variant="subtitle1" className={classes.subTitle}>
              {subtitle}
            </Typography>
            <PageTitle>{title}</PageTitle>
          </Box>
        </Box>
      </Box>
    </StyledGenericHeading>
  );
};
