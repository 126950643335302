import { Box, styled } from "@mui/material";

import {
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_WHITE,
} from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";

interface StyledHeroBannerProps {
  color?: string;
}

const PREFIX = "StyledHeroBanner";
export const CLASSES = {
  root: `${PREFIX}-root`,
  banner: `${PREFIX}-banner`,
  title: `${PREFIX}-title`,
  textBlock: `${PREFIX}-textBlock`,
  header: `${PREFIX}-header`,
  backgroundLink: `${PREFIX}-backgroundLink`,
  infoLink: `${PREFIX}-infoLink`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  ctaLinkText: `${PREFIX}-ctaLinkText`,
};

export const StyledHeroBanner = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})<StyledHeroBannerProps>(({ theme, color }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${CLASSES.root}`]: {
      marginBottom: theme.spacing(7),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(9),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(16),
      },
    },
    [`& .${CLASSES.banner}`]: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: "500px",
      backgroundColor: color === "light" ? COLOR_GREYSCALE_BLACK_75 : COLOR_WHITE,

      [formatMedia.BREAKPOINT_TABLET]: {
        height: "550px",
      },
    },
    [`& .${CLASSES.title}`]: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(0.5),
    },
    [`& .${CLASSES.textBlock}`]: {
      zIndex: 1,
      marginLeft: theme.spacing(2),

      color: color === "light" ? COLOR_WHITE : COLOR_BLACK,

      [formatMedia.BREAKPOINT_TABLET]: {
        marginLeft: theme.spacing(4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginLeft: "12.4%",
      },
      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        marginLeft: "16.1%",
      },

      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),

        [formatMedia.BREAKPOINT_TABLET]: {
          marginBottom: theme.spacing(3),
        },
      },
    },
    [`& .${CLASSES.header}`]: {
      ...theme.typography.h3,
      textTransform: "inherit",
      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.h2,
        overflowWrap: "normal",
        textTransform: "inherit",
      },
    },
    [`& .${CLASSES.backgroundLink}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [`& .${CLASSES.infoLink}`]: {
      zIndex: 1,
    },
    [`& .${CLASSES.backgroundImage}`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& .${CLASSES.ctaLinkText}`]: {
      fontSize: 18,
    },
  };
});
