import React from "react";

import { styled } from "@mui/material";

import { FooterSocialLinksItemProps } from "~/bff/components/FooterSocialLinksItem";
import { CombinedLink } from "~/components/CombinedLink";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const StyledLinkItem = styled(CombinedLink)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    ...theme.typography.body2,

    "& + &": {
      marginTop: theme.spacing(3.5),
    },

    [formatMedia.BREAKPOINT_DESKTOP]: {
      "& + &": {
        marginTop: theme.spacing(2),
      },
    },
  };
});

export const LinkItem = ({
  link,
}: FooterSocialLinksItemProps): React.ReactElement => {
  return (
    <StyledLinkItem href={link?.url ?? ""} {...getTestAutomationProps(`link`)}>
      {link?.label ?? ""}
    </StyledLinkItem>
  );
};
