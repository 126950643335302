import React, { useCallback, useEffect, useState } from "react";

import { cx } from "@emotion/css";
import { Box, Button, ClickAwayListener, styled, Tooltip } from "@mui/material";
import { noop } from "lodash";

import {
  ShareTooltip,
  ShareTooltipProps,
} from "~/components/Share/components/ShareTooltip";
import { MOBILE_USER_AGENTS_REGEX } from "~/constants/regex";
import {
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_BLACK,
} from "~/theme/constants/colors";
import { Share24 } from "~/theme/icons";
import { TooltipType } from "~/theme/Tooltip";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledShare";
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledShare = styled(Box)(() => ({
  [`&.${classes.root}`]: {
    lineHeight: 0,
  },
  [`& .${classes.button}`]: {
    textDecoration: "none",

    "&:hover": {
      color: COLOR_GREYSCALE_BLACK_75,
      textDecoration: "underline",
    },

    "&:active, &:focus": {
      color: COLOR_PRIMARY_BLACK,
      textDecoration: "underline",
    },
  },
}));

export interface ShareProps extends ShareTooltipProps {
  label?: string;
}

export const Share = ({ url, label = "Share", ...props }: ShareProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isNativeShareAvailable, setIsNativeShareAvailable] = useState(false);

  const closeTooltip = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const openTooltip = useCallback(() => {
    if (!isNativeShareAvailable) {
      setIsTooltipOpen(!isTooltipOpen);
    } else {
      navigator.share({ url }).catch(noop);
    }
  }, [isNativeShareAvailable, isTooltipOpen, url]);

  const onEscKeyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeTooltip();
      }
    },
    [closeTooltip]
  );

  useEffect(() => {
    if (isTooltipOpen) {
      window.addEventListener("keyup", onEscKeyUpHandler);
    } else {
      window.removeEventListener("keyup", onEscKeyUpHandler);
    }

    return () => {
      window.removeEventListener("keyup", onEscKeyUpHandler);
    };
  }, [isTooltipOpen, onEscKeyUpHandler]);

  useEffect(() => {
    const isMobileDevice = MOBILE_USER_AGENTS_REGEX.test(navigator.userAgent);

    if (isMobileDevice && navigator["share"]) {
      setIsNativeShareAvailable(true);
    }
  }, []);

  return (
    <StyledShare className={classes.root} {...getTestAutomationProps("share")}>
      <ClickAwayListener onClickAway={closeTooltip} touchEvent={false}>
        <Tooltip
          classes={{ tooltip: TooltipType.LIGHT, arrow: TooltipType.LIGHT }}
          title={<ShareTooltip url={url} {...props} />}
          onClose={closeTooltip}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
        >
          <Button
            variant="text"
            id="share-button"
            onClick={openTooltip}
            startIcon={<Share24 />}
            className={cx(classes.button, "body2")}
            {...getTestAutomationProps("share-button")}
          >
            {label}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </StyledShare>
  );
};
