import { isValidElement, ReactElement } from "react";

import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { ImageSource } from "~/bff/types/ImageSource";
import { JsonLdConfig } from "~/components/JsonLd/JsonLd.types";
import { getImageSrc } from "~/utils/image";

export const getJsonLdConfig = ({
  author,
  publishDate,
  title,
  image,
}: Partial<ArticlePageProps>): JsonLdConfig => {
  const imageSource: ImageSource = isValidElement(image)
    ? (image as ReactElement)?.props?.props?.source
    : image?.props?.source;

  const authorName: string = isValidElement(author)
    ? (author as ReactElement)?.props?.props?.name
    : author?.props?.name;

  return {
    placeholders: {
      headline: title ?? "",
      datePublished: publishDate ?? "",
      "author.name": authorName,
      image: getImageSrc(imageSource),
    },
  };
};
