import { Image } from "~/bff/types/Image";
import { ImagePropsExtended } from "~/components/Image";

export const prepareImageA11yProps = (
  imageProps: Image | undefined | null
): Partial<ImagePropsExtended> => {
  if (!imageProps) {
    return {};
  }

  const { ariaDetails, ariaDetailsDescription } = imageProps;
  const hasAriaDetails = ariaDetails && ariaDetailsDescription;

  return {
    ...(hasAriaDetails && {
      "aria-details": ariaDetails,
      ariaDetailsDescription,
    }),
  };
};
