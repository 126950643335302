import React from "react";

import { Box, Grid, styled } from "@mui/material";

import { PartnerLogoRichTextProps } from "~/bff/components/PartnerLogoRichText";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { Image } from "~/components/Image";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledPartnerLogoRichText";
const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  image: `${PREFIX}-image`,
  imageContainer: `${PREFIX}-imageContainer`,
  richText: `${PREFIX}-richText`,
};

const StyledPartnerLogoRichText = styled(Grid)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      flexDirection: "column",
      marginTop: theme.spacing(4.25),
      marginBottom: theme.spacing(7),
      columnGap: theme.spacing(6),
      rowGap: theme.spacing(3.25),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        flexDirection: "row",
        padding: theme.spacing(8),
        marginTop: theme.spacing(8.25),
        marginBottom: theme.spacing(16.5),
      },
    },
    [`& .${classes.logo}`]: {
      display: "flex",
      flexGrow: 1,
      flexBasis: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    [`& .${classes.richText}`]: {
      display: "flex",
      flexGrow: 1,
      flexBasis: 0,
      alignItems: "center",
      justifyContent: "center",
      "& > div": {
        padding: 0,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginTop: 0,
      },
    },
    [`& .${classes.imageContainer}`]: {
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(6),
      },
    },
    [`& .${classes.image}`]: {
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: 374,
      },
      width: "100%",
      height: "100%",
    },
  };
});

export const PartnerLogoRichText = ({
  image,
  richText,
  testAutomationId = "partner-logo-rich-text",
}: PartnerLogoRichTextProps) => {
  return (
    <StyledPartnerLogoRichText
      {...getTestAutomationProps(testAutomationId ?? "partner-logo-rich-text")}
      className={classes.root}
    >
      {image?.source && (
        <Box className={classes.logo}>
          <CombinedLink
            className={classes.imageContainer}
            href={image?.url as string}
            underline="none"
          >
            <Image
              className={classes.image}
              src={getImageSrc(image?.source as ImageSource)}
              alt={image?.alt ?? ""}
              title={image?.title ?? ""}
              {...prepareImageA11yProps(image)}
            />
          </CombinedLink>
        </Box>
      )}
      <Box className={classes.richText}>{richText as React.ReactElement[]}</Box>
    </StyledPartnerLogoRichText>
  );
};
