"use client";
import React, { useCallback, useMemo, useEffect, useState } from "react";

import { Box, Container, TabsProps, Tabs, Tab, styled } from "@mui/material";
import { useSearchParams, usePathname } from "next/navigation";

import { NewsroomPageProps } from "~/bff/components/NewsroomPage";
import { ArticlePage } from "~/bff/types/ArticlePage";
import { Theme } from "~/bff/types/Theme";
import { LoadPreviousButton } from "~/components/LoadPreviousButton";
import { PageTitle } from "~/components/PageTitle";
import { DEFAULT_PAGE_SIZE } from "~/constants/common";
import { COMPONENTS_CONFIG } from "~/constants/componentsConfig";
import { PARAMS } from "~/constants/request";
import { usePagination } from "~/hooks/usePagination";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { getQueryFromUrl } from "~/utils/getQueryFromUrl";
import { isSamePage } from "~/utils/isSamePage";
import { getTestAutomationProps } from "~/utils/testUtils";
import { updatePageNumberOnUrl } from "~/utils/updatePageNumberOnUrl";
import { updateRouterQuery } from "~/utils/updateRouterQuery";

import { LoadMoreButton } from "../LoadMoreButton";
import {
  SEO_META_TAGS_COMP_NAME,
  SEO_META_TAGS_COMPONENT_CONFIG,
} from "../SeoMetaTags/SeoMetaTags.constants";
import { UniversalComponent } from "../UniversalComponent";

import { ArticleTiles } from "./components/ArticleTiles";
import { filterArticlesByTheme } from "./helper/filterArticlesByTheme";
import { DEFAULT_ALL_TAB_TEXT } from "./NewsRoomPage.constants";

const PREFIX = "StyledNewsroomPage";
const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};
const StyledNewsroomPage = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
    },
    [`& .${classes.title}`]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
    },
  };
});
export const NewsRoomPage = ({
  seo,
  title,
  themeLinks,
  articlesData,
  progressBarText,
  loadMoreButtonText,
  loadPreviousButtonText,
  localizedPages,
  allTabText: defaultAllTabText,
  testAutomationId = "newsroom-page",
}: NewsroomPageProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const tabParam = searchParams.get(PARAMS.TAB);
  const pageParam = searchParams.get(PARAMS.PAGE);
  const allTabText = defaultAllTabText ?? DEFAULT_ALL_TAB_TEXT;
  const { docs: fullArticleList = [] } = articlesData?.response ?? {};

  const [activeTheme, setActiveTheme] = useState(String(tabParam ?? allTabText));
  const [articleList, setArticleList] = useState<Nullable<ArticlePage>[]>(
    filterArticlesByTheme({
      list: fullArticleList ?? [],
      theme: activeTheme,
      allTabText,
    })
  );
  const [initPage, setInitPage] = useState(Number(pageParam ?? 1));

  const start = (initPage - 1) * DEFAULT_PAGE_SIZE;
  const end = Math.min(start + DEFAULT_PAGE_SIZE, articleList?.length ?? 0);
  const defaultArticles = articleList?.slice(start, end);

  const {
    itemList,
    totalCount,
    previousPage,
    nextPage,
    loadNextPage,
    loadPreviousPage,
  } = usePagination<Nullable<ArticlePage>>(
    useMemo(
      () => ({
        defaultItemList: defaultArticles,
        initPage,
        items: articleList,
        size: DEFAULT_PAGE_SIZE,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [articleList, initPage]
    )
  );

  const preparedThemes = useMemo(
    () =>
      (themeLinks?.props?.links as Theme[])?.map(({ props, _meta }) => (
        <Tab
          key={_meta?.deliveryId}
          value={props?.title}
          label={props?.title}
          {...getTestAutomationProps(`theme-${props?.title}`)}
        />
      )),
    [themeLinks?.props?.links]
  );

  const handleTabChange = useCallback<Required<TabsProps>["onChange"]>(
    (_, selectedTab) => {
      setActiveTheme(selectedTab);
      setArticleList(
        filterArticlesByTheme({
          list: fullArticleList ?? [],
          theme: selectedTab,
          allTabText,
        })
      );
      setInitPage(1);
      updateRouterQuery({ selectedTab, allTabText, searchParams, pathname });
    },
    [allTabText, fullArticleList, searchParams, pathname]
  );

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    const popstateHandler = (event: PopStateEvent) => {
      const {
        state: { as },
      } = event ?? {};
      if (!isSamePage(as, pathname)) {
        return;
      }

      const { tab, page } = getQueryFromUrl(window.location.href);
      const currentTab = String(tab ?? allTabText);
      const currentPage = Number(page ?? 1);
      setActiveTheme(currentTab);
      setInitPage(currentPage);
      setArticleList(
        filterArticlesByTheme({
          list: fullArticleList ?? [],
          theme: currentTab,
          allTabText,
        })
      );

      if (document?.activeElement instanceof HTMLElement) {
        document?.activeElement?.blur();
      }
    };

    window.addEventListener("popstate", popstateHandler);
    return () => {
      window.removeEventListener("popstate", popstateHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickPrevious = () => {
    loadPreviousPage();
    updatePageNumberOnUrl({
      pageNumber: previousPage,
      searchParams,
      pathname,
    });
  };

  const handleClickMore = () => {
    loadNextPage();
    updatePageNumberOnUrl({
      pageNumber: nextPage,
      searchParams,
      pathname,
    });
  };

  return (
    <StyledNewsroomPage
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      {Boolean(seo) && (
        <UniversalComponent
          props={{ ...seo, localizedPages }}
          component={SEO_META_TAGS_COMP_NAME}
          componentsConfigs={[
            {
              ...COMPONENTS_CONFIG,
              ...SEO_META_TAGS_COMPONENT_CONFIG,
            },
          ]}
        />
      )}
      <PageTitle className={classes.title}>{title}</PageTitle>
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={activeTheme}
        onChange={handleTabChange}
      >
        <Tab
          value={allTabText}
          label={allTabText}
          {...getTestAutomationProps(`theme-${allTabText}`)}
        />
        {preparedThemes}
      </Tabs>
      {previousPage > 0 && (
        <LoadPreviousButton
          loadPreviousButtonText={loadPreviousButtonText ?? ""}
          pageSize={DEFAULT_PAGE_SIZE}
          onClick={handleClickPrevious}
          currentPage={previousPage + 1}
        />
      )}
      <ArticleTiles articles={itemList} fullArticleList={articleList ?? []} />
      <Box>
        <LoadMoreButton
          totalCount={totalCount}
          pageSize={DEFAULT_PAGE_SIZE}
          loadMoreButtonText={loadMoreButtonText}
          progressBarText={progressBarText}
          onClick={handleClickMore}
          currentPage={nextPage - 1}
        />
      </Box>
    </StyledNewsroomPage>
  );
};
