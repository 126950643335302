import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Fade, MenuList, Paper, Popper, styled } from "@mui/material";

import { NavItemList } from "~/components/GlobalNavigation/GlobalNavigation.types";
import { filterNavItems } from "~/components/GlobalNavigation/helper/filterNavItems";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_GREYSCALE_BLACK_15,
} from "~/theme/constants/colors";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

import { NavMenuLandscapeContext } from "./NavMenuLandscapeContext";
import { SubMenuItem } from "./SubMenuItem";
import { SubMenuTrigger } from "./SubMenuTrigger";

const PREFIX = "StyledNavMenuLandscape";
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  menuList: `${PREFIX}-menuList`,
  menuItem: `${PREFIX}-menuItem`,
};

const StyledNavMenuLandscape = styled(Popper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    zIndex: 1300,
  },
  [`& .${classes.paper}`]: {
    width: "320px",
    borderRadius: 0,
    overflow: "initial",
    boxShadow: `${theme.spacing(0, 1, 3)} ${COLOR_GREYSCALE_BLACK_15}`,

    '&[data-placement="right-start"]': {
      transform: "translate(-12px, -12px)",
    },

    '&[data-placement="right-end"]': {
      transform: "translate(-12px, 12px)",
    },

    '&[data-placement="left-start"]': {
      transform: "translate(12px, -12px)",
    },

    '&[data-placement="left-end"]': {
      transform: "translate(12px, 12px)",
    },
  },
  [`& .${classes.menuList}`]: {
    padding: `${theme.spacing(1.5)} 0`,
  },
  [`& .${classes.menuItem}`]: {
    height: "auto",
    minHeight: "48px",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    justifyContent: "space-between",
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,

    '&:hover, &:focus, &.Mui-focusVisible, &[data-menu-active="true"]': {
      backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
      textDecoration: "underline",
    },
  },
}));

interface NavMenuLandscapeProps {
  level?: number;
  isOpen: boolean;
  anchorId: string;
  itemList?: NavItemList;
  anchorEl: Nullable<HTMLElement>;
  onMenuClose: (event: React.SyntheticEvent) => void;
}

export const NavMenuLandscape = memo(
  ({
    isOpen,
    itemList,
    anchorEl,
    anchorId,
    onMenuClose,
    level = 1,
  }: NavMenuLandscapeProps) => {
    const [activeSubMenu, setActiveSubMenu] = useState<Nullable<string>>(null);
    const menuListRef = useRef<HTMLUListElement>();
    const { navigationMode, setNavigationMode } = useContext(
      NavMenuLandscapeContext
    );

    const menuId = `nav-menu-level-${level}`;
    const isRoot = level <= 1;

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        setNavigationMode("keyboard");
        event.stopPropagation();

        if (event.code === "Tab") {
          event.preventDefault();
          onMenuClose(event);
        } else if (event.code === "Escape") {
          onMenuClose(event);
        }

        if (level > 1 && event.code === "ArrowLeft") {
          onMenuClose(event);
        }
      },
      [level, onMenuClose, setNavigationMode]
    );

    useEffect(() => {
      if (isOpen && navigationMode === "keyboard") {
        // Due to unknown reasons, the first MenuItem in some of the Level 2 menus cannot be focused in any way
        // so a "setTimeout" with small delay is used here (a zero time delay doesn't seem to work either)
        setTimeout(() => {
          (menuListRef.current?.firstElementChild as HTMLElement)?.focus();
        }, 50);
      }
    }, [isOpen, navigationMode]);

    if (!itemList?.length) {
      return null;
    }

    return (
      <StyledNavMenuLandscape
        transition
        open={isOpen}
        role={undefined}
        anchorEl={anchorEl}
        className={classes.root}
        placement={isRoot ? "bottom-start" : "right-start"}
        {...getTestAutomationProps(`nav-menu-landscape-level-${level}`)}
      >
        {({ TransitionProps, placement }) => (
          <Fade {...TransitionProps}>
            <Paper className={classes.paper} data-placement={placement}>
              <MenuList
                id={menuId}
                onKeyDown={handleKeyDown}
                aria-labelledby={anchorId}
                className={classes.menuList}
                ref={menuListRef as unknown as React.RefObject<HTMLUListElement>}
                {...getTestAutomationProps("nav-menu-item-list")}
              >
                {filterNavItems(itemList)?.map((item, index) => {
                  const { id } = item?.props ?? {};
                  const itemId = id ?? String(index);
                  const anchorId = `nav-anchor-${itemId}-level-${level + 1}`;
                  const hasSubMenu = Boolean(filterNavItems(item?.children)?.length);

                  return hasSubMenu ? (
                    <SubMenuTrigger
                      key={anchorId}
                      level={level + 1}
                      onMenuClose={onMenuClose}
                      isOpen={activeSubMenu === anchorId}
                      setActiveSubMenu={setActiveSubMenu}
                      className={classes.menuItem}
                      anchorId={anchorId}
                      data={item}
                    />
                  ) : (
                    <SubMenuItem
                      key={anchorId}
                      onMenuClose={onMenuClose}
                      className={classes.menuItem}
                      anchorId={anchorId}
                      data={item}
                    />
                  );
                })}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </StyledNavMenuLandscape>
    );
  }
);

NavMenuLandscape.displayName = "NavMenuLandscape";
