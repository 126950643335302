import React from "react";

import { Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { CombinedLink } from "../CombinedLink";

import { Table } from "./components/Table";

export interface MarkdownProps {
  text: string;
  className?: string;
}

export const Markdown = ({ text, className }: MarkdownProps) => {
  return (
    <ReactMarkdown
      components={{
        /* @ts-expect-error Due to undefined*/
        a: CombinedLink,
        table: Table,
        h1: ({ children }) => <Typography variant="h1Medium">{children}</Typography>,
      }}
      skipHtml
      className={className}
      remarkPlugins={[remarkGfm]}
    >
      {text}
    </ReactMarkdown>
  );
};
