export enum ScrollDirection {
  UP = "UP",
  DOWN = "DOWN",
  NO = "NO",
}

export interface WindowScrollState {
  scrollTop: number;
  scrollDirection: ScrollDirection;
}
