import React, { useMemo } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import { FallbackProps } from "react-error-boundary";

import { isDebug } from "~/utils/isDebug";

interface ExtraOptions {
  componentStack?: string;
}

const PREFIX = "StyledErrorFallback";
const classes = {
  root: `${PREFIX}-root`,
  message: `${PREFIX}-message`,
  text: `${PREFIX}-text`,
  button: `${PREFIX}-button`,
};

const StyledErrorFallback = styled(Alert)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  [`&.${classes.message}`]: {
    padding: 0,
  },
  [`& .${classes.text}`]: {
    paddingBottom: theme.spacing(3),
  },
  [`& .${classes.button}`]: {
    padding: theme.spacing(3, 0, 3, 2),
  },
}));

export const ErrorFallback = ({
  error,
  componentStack,
  resetErrorBoundary,
}: FallbackProps & ExtraOptions) => {
  const stack = useMemo(
    () =>
      `${error.stack ?? ""}`
        .split(/[\n\r]+/)
        .slice(1)
        .join("\n"),
    [error]
  );

  if (isDebug()) {
    return null;
  }

  return (
    <StyledErrorFallback
      classes={{ root: classes.root, message: classes.message }}
      severity="error"
    >
      <AlertTitle>{error.message}</AlertTitle>
      <Typography component="pre" className={classes.text}>
        {stack}
      </Typography>
      {componentStack && (
        <>
          <Divider />
          <Typography component="pre" className={classes.text}>
            {componentStack}
          </Typography>
        </>
      )}
      <Divider />
      <Typography className={classes.button}>
        <Button
          variant="text"
          className="body1 underlined"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Typography>
    </StyledErrorFallback>
  );
};
