import { BffComponentType as Component } from "~/bff/ComponentsConfig";
import { Copyright } from "~/bff/types/Copyright";
import { CountrySelector } from "~/bff/types/CountrySelector";
import { Footer } from "~/bff/types/Footer";
import { FooterNavChildren } from "~/bff/types/FooterNavChildren";
import { FooterNavigation } from "~/bff/types/FooterNavigation";
import { FooterSocialLinks } from "~/bff/types/FooterSocialLinks";
import { Nullable } from "~/types/utility.types";

import { ConvertedFooterProps, NavItems } from "./Footer.types";

const findComponentByName = <T extends { component?: Nullable<string> }>(
  data: Footer,
  name: string
): T => data?.children?.find((child) => (child as T)?.component === name) as T;

export const convertFooterToProps = (footer: Footer): ConvertedFooterProps => {
  if (!footer) {
    return {};
  }

  return {
    countriesData: {
      countries:
        findComponentByName<CountrySelector>(
          footer,
          Component.COUNTRY_SELECTOR
        )?.children?.map((child) => child?.props ?? {}) ?? [],
      countrySelectorHeader: {
        ...findComponentByName<CountrySelector>(footer, Component.COUNTRY_SELECTOR)
          .props,
      },
    },
    socialLinks: findComponentByName<FooterSocialLinks>(
      footer,
      Component.FOOTER_SOCIAL_LINKS
    )?.children?.map((child) => {
      return {
        ...child?.props?.link,
        ...child?.props?.icon?.props,
      };
    }),
    footerNavigation: findComponentByName<FooterNavigation>(
      footer,
      Component.FOOTER_NAVIGATION
    )?.children?.reduce((accumulator, navItem) => {
      const { props, children } = navItem as FooterNavChildren;

      return [
        ...accumulator,
        {
          headline: props?.title ?? "",
          links: children?.map((item) => item?.props ?? {}) ?? [],
        },
      ];
    }, [] as NavItems),
    copyright:
      findComponentByName<Copyright>(footer, Component.COPYRIGHT)?.props?.title ??
      "",
  };
};
