import { SocialMedia } from "~/constants/socialMedia";

export interface GetSocialMediaLinkArgs {
  url: string;
  description: string;
  facebookAppId?: string;
  socialMedia: SocialMedia;
}

export const getSocialMediaLink = ({
  url,
  description,
  socialMedia,
  facebookAppId,
}: GetSocialMediaLinkArgs): string => {
  switch (socialMedia) {
    case SocialMedia.FACEBOOK: {
      return facebookAppId
        ? `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${url}&redirect_uri=${url}&quote=${description}`
        : `https://www.facebook.com/sharer.php?u=${url}&quote=${description}`;
    }
    case SocialMedia.TWITTER: {
      return `https://twitter.com/intent/tweet?text=${description}&url=${url}`;
    }
    case SocialMedia.WHATSAPP: {
      return `https://web.whatsapp.com/send?text=${description} ${url}`;
    }
    case SocialMedia.PINTEREST: {
      return `https://pinterest.com/pin/create/link/?url=${url}&description=${description}`;
    }
    default: {
      return "";
    }
  }
};
