"use client";
import React, { useCallback, useContext, useMemo, useState } from "react";

interface HeaderHelpers {
  isMenuOpen: boolean;
  handleMenuClose?: () => void;
  handleMenuOpen?: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HeaderContext = React.createContext<HeaderHelpers>({
  isMenuOpen: false,
});

export const HeaderProvider = ({ children }: React.PropsWithChildren) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);

  const handleMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const helpers = useMemo<HeaderHelpers>(
    () => ({
      isMenuOpen: isMenuOpen,
      handleMenuClose: handleMenuClose,
      handleMenuOpen: handleMenuOpen,
    }),
    [isMenuOpen, handleMenuClose, handleMenuOpen]
  );

  return <HeaderContext.Provider value={helpers}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = (): HeaderHelpers => {
  return useContext(HeaderContext);
};
