"use client";
import React, { useState } from "react";

import { styled, Box, Typography, Button } from "@mui/material";

import { PrimarkJourneyProps } from "~/bff/components/PrimarkJourney";
import {
  COLOR_PRIMARK_JOURNEY_BLUE,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { DEFAULT_FONT_FAMILY } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { Markdown } from "../Markdown";

const PREFIX = "StyledPrimarkJourney";
export const PRIMARK_JOURNEY_CLASSES = {
  root: `${PREFIX}-root`,
  yearContent: `${PREFIX}-yearContent`,
  yearContentTitle: `${PREFIX}-yearContentTitle`,
  yearContentYear: `${PREFIX}-yearContentYear`,
  yearContentText: `${PREFIX}-yearContentText`,
  yearList: `${PREFIX}-yearList`,
  yearContainer: `${PREFIX}-yearContainer`,
  yearListItem: `${PREFIX}-yearListItem`,
  yearListBtn: `${PREFIX}-yearListBtn`,
  yearListDot: `${PREFIX}-yearListDot`,
  yearListText: `${PREFIX}-yearListText`,
};

const StyledPrimarkJourney = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${PRIMARK_JOURNEY_CLASSES.root}`]: {
      marginBottom: theme.spacing(3.75),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(7),
      },
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearList}`]: {
      display: "flex",
      justifyContent: "space-between",
      listStyleType: "none",
      maxWidth: 1200,
      padding: 0,
      margin: "0 auto",
      overflowX: "auto",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContent}`]: {
      textAlign: "center",
      maxWidth: 430,
      margin: "0 auto",
      color: "#275db8",
      marginBottom: `${theme.spacing(3)}`,
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContentTitle}`]: {
      fontSize: 16,
      letterSpacing: 1.2,
      lineHeight: "20px",
      textAlign: "center",
      fontFamily: DEFAULT_FONT_FAMILY,
      marginBottom: `${theme.spacing(2)}`,
      textTransform: "uppercase",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContentYear}`]: {
      fontSize: 86,
      lineHeight: "64px",
      marginBottom: `${theme.spacing(3.75)}`,
      letterSpacing: 0,
      fontFamily: DEFAULT_FONT_FAMILY,
      display: "block",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContentText}`]: {
      fontSize: 16,
      letterSpacing: 0.4,
      lineHeight: "28px",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContainer}:after`]: {
      content: '""',
      width: "100%",
      height: 2,
      backgroundColor: COLOR_PRIMARK_JOURNEY_BLUE,
      opacity: 0.4,
      position: "absolute",
      top: theme.spacing(4.25),
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearListBtn}`]: {
      padding: 0,
      backgroundColor: "transparent",
      border: 0,
      position: "relative",
      cursor: "pointer",
      height: "auto",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      [formatMedia.BREAKPOINT_TABLET]: {
        display: "inline-block",
      },

      '&[data-selected="true"], &:hover, &:focus, &:active': {
        backgroundColor: "transparent",
        [`.${PRIMARK_JOURNEY_CLASSES.yearListDot}`]: {
          backgroundColor: COLOR_PRIMARK_JOURNEY_BLUE,
        },
      },
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearListDot}`]: {
      boxSizing: "border-box",
      height: 24,
      width: 24,
      border: `1px solid ${COLOR_PRIMARK_JOURNEY_BLUE}`,
      borderRadius: "50%",
      backgroundColor: COLOR_PRIMARY_WHITE,
      display: "block",
      margin: `${theme.spacing(1)} auto`,
      position: "relative",
      zIndex: 1,
      transition: "ease-in 0.3s",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearListItem}:nth-of-type(odd) .${PRIMARK_JOURNEY_CLASSES.yearListBtn}`]:
      {
        top: `-${theme.spacing(2)}`,
        [formatMedia.BREAKPOINT_TABLET]: {
          top: 0,
        },
        [`& .${PRIMARK_JOURNEY_CLASSES.yearListDot}`]: {
          order: 2,
          [formatMedia.BREAKPOINT_TABLET]: {
            order: "unset",
          },
        },
      },

    [`& .${PRIMARK_JOURNEY_CLASSES.yearListItem}`]: {
      flex: "1 0 auto",
      padding: `${theme.spacing(2)} ${theme.spacing(0.5)} 0`,
      textAlign: "center",
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearListText}`]: {
      fontSize: 14,
      letterSpacing: 1,
      color: COLOR_PRIMARK_JOURNEY_BLUE,
      textAlign: "center",
      lineHeight: theme.spacing(2),
    },
    [`& .${PRIMARK_JOURNEY_CLASSES.yearContainer}`]: {
      position: "relative",
      overflowX: "hidden",
    },
  };
});

export const PrimarkJourney = ({ dates, title }: PrimarkJourneyProps) => {
  const [activedDate, setActivedDate] = useState<number>(0);

  const handleSelectYear = (i: number) => {
    setActivedDate(i);
  };

  return (
    <StyledPrimarkJourney
      className={PRIMARK_JOURNEY_CLASSES.root}
      {...getTestAutomationProps("primark-journey")}
    >
      <Box className={PRIMARK_JOURNEY_CLASSES.yearContent}>
        <Typography
          variant="h3"
          className={PRIMARK_JOURNEY_CLASSES.yearContentTitle}
          {...getTestAutomationProps("content-title")}
        >
          {title}
        </Typography>
        <Box
          className={PRIMARK_JOURNEY_CLASSES.yearContentYear}
          {...getTestAutomationProps("content-year")}
        >
          {dates?.[activedDate]?.year}
        </Box>
        <Markdown
          className={PRIMARK_JOURNEY_CLASSES.yearContentText}
          text={dates?.[activedDate]?.description as string}
        />
      </Box>
      <Box className={PRIMARK_JOURNEY_CLASSES.yearContainer}>
        <ul
          className={PRIMARK_JOURNEY_CLASSES.yearList}
          {...getTestAutomationProps("year-option-list")}
        >
          {dates?.map((date, index) => (
            <li className={PRIMARK_JOURNEY_CLASSES.yearListItem} key={index}>
              <Button
                className={`${PRIMARK_JOURNEY_CLASSES.yearListBtn}`}
                onClick={() => handleSelectYear(index)}
                data-selected={index === activedDate}
                {...getTestAutomationProps("year-option-button")}
              >
                <Box className={PRIMARK_JOURNEY_CLASSES.yearListDot} />
                {index === activedDate ? (
                  <Box
                    className={PRIMARK_JOURNEY_CLASSES.yearListText}
                    {...getTestAutomationProps("selected-year-option")}
                  >
                    {date?.year}
                  </Box>
                ) : (
                  <Box
                    className={PRIMARK_JOURNEY_CLASSES.yearListText}
                    {...getTestAutomationProps("year-option")}
                  >
                    {date?.year}
                  </Box>
                )}
              </Button>
            </li>
          ))}
        </ul>
      </Box>
    </StyledPrimarkJourney>
  );
};
