export const DESKTOP_TILE_PATTERN = [1, 3, 1, 3, 3];

export const computeDesktopTileLayout = <T>(list: T[]): T[][] => {
  let tail = 0;
  let counter = 0;
  const groups = [];

  while (tail < list.length) {
    const amount = DESKTOP_TILE_PATTERN[counter % 5];
    const end = Math.min(tail + amount, list.length);

    groups[counter] = list.slice(tail, end);
    counter += 1;
    tail = end;
  }

  return groups;
};

export const computeTabletTileLayout = <T>(list: T[]): T[][] => {
  const pairs = new Array(Math.ceil(list.length / 2)).fill([]);

  return pairs.map((_, index) => [list[index * 2], list[index * 2 + 1]]);
};
