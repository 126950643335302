import { ArticlePage } from "~/bff/types/ArticlePage";
import { Theme } from "~/bff/types/Theme";
import { Nullable } from "~/types/utility.types";

interface FilterArticlesByThemeProps {
  list: Nullable<ArticlePage>[];
  allTabText: string;
  theme: string;
}

export const filterArticlesByTheme = ({
  list,
  theme,
  allTabText,
}: FilterArticlesByThemeProps): Nullable<ArticlePage>[] => {
  if (theme === allTabText) {
    return list;
  }

  return list?.filter((article) => {
    return (article?.props?.articleTheme as Theme).props?.title === theme;
  });
};
