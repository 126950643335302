import React from "react";

import type { Seo } from "~/bff/types/Seo";

import { seoMetaTagsReactiveVar } from "./hooks/useSeoMetaTags";
import type { ExtraSeoMetaTagsProps } from "./SeoMetaTags.types";
export interface SeoExtended extends Seo {
  extraProps?: ExtraSeoMetaTagsProps;
}

export const SeoMetaTags = ({ jsonLd, extraProps = {} }: SeoExtended) => {
  seoMetaTagsReactiveVar({
    ...extraProps,
  });

  return (
    <>
      {[jsonLd].reduce((validElements, current, key) => {
        if (React.isValidElement(current)) {
          const newElement = React.cloneElement(current as JSX.Element, { key });
          validElements.push(newElement);
        }
        return validElements;
      }, [] as JSX.Element[])}
    </>
  );
};
