export enum PARAMS {
  SLUG = "slug",
  VISUALIZATION = "/visualization",
  CONTENT_ID = "contentId",
  LOCALE = "locale",
  VSE = "vse",
  PAGE = "page",
  TAB = "tab",
}

export enum VARIABLES {
  SLUG = "slug",
  VISUALIZATION = "/visualization",
  CONTENT_ID = "contentId",
  LOCALE = "locale",
  VSE = "vse",
}

export enum Headers {
  PAGE_LOCALE = "x-page-locale",
}
