import React from "react";

import { Box, styled, Typography } from "@mui/material";

import { ImagesPropsImages } from "~/bff/types/ImagesPropsImages";
import { Image } from "~/components/Image";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { RESPONSIVE_IMAGE_CONFIG } from "./ImageCard.constants";

const PREFIX = "StyledImageCard";

const classes = {
  caption: `${PREFIX}-caption`,
  image: `${PREFIX}-image`,
};

const StyledImageCard = styled(Box, {
  shouldForwardProp: (props) => props !== "isSingle",
})<{ isSingle?: boolean }>(({ theme, isSingle }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`& .${classes.caption}`]: {
      ...theme.typography.body2,
      paddingTop: theme.spacing(1),
      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.body1,
        paddingTop: theme.spacing(2),
      },
    },
    [`& .${classes.image}`]: {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      aspectRatio: isSingle ? "auto" : "3/4",
    },
  };
});

interface ImageCardPropsExtended extends ImagesPropsImages {
  isSingle?: boolean;
}

export const ImageCard = ({
  title,
  source,
  alt,
  caption,
  isSingle,
  ...props
}: ImageCardPropsExtended) => {
  return (
    <StyledImageCard isSingle={isSingle} {...getTestAutomationProps("image-card")}>
      <Image
        className={classes.image}
        responsiveImageConfig={source ? RESPONSIVE_IMAGE_CONFIG : undefined}
        src={source ? getImageSrc(source) : "/assets/images/no-image.png"}
        alt={prepareTextAttribute(alt, true)}
        title={prepareTextAttribute(title)}
        {...prepareImageA11yProps(props)}
        {...getTestAutomationProps("image")}
      />
      {!!caption && (
        <Typography
          className={classes.caption}
          {...getTestAutomationProps("caption")}
        >
          {caption}
        </Typography>
      )}
    </StyledImageCard>
  );
};
