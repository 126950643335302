import React, { CSSProperties } from "react";

import { Box, styled } from "@mui/material";
import { useParams } from "next/navigation";

import { MiddleTextLinkProps } from "~/bff/components/MiddleTextLink";
import { CTALinkButton } from "~/components/CTALinkButton";
import { FontWeights, TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getLinkUrl } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { CombinedLink } from "../CombinedLink";
import { Markdown } from "../Markdown";

const PREFIX = "StyledMiddleTextLink";
const classes = {
  root: `${PREFIX}-mtlWrapper`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  link: `${PREFIX}-link`,
};

const StyledMiddleTextLink = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      position: "relative",
      backgroundSize: "cover",
      paddingTop: theme.spacing(9.5),
      paddingBottom: theme.spacing(9.5),
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    [`& .${classes.description}`]: {
      width: 343,
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: 644,
      },
      "& h1": {
        ...theme.typography.h2,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h1,
          textAlign: "center",
          marginBottom: theme.spacing(2),
        },
      },
      "& h2": {
        ...theme.typography.h3,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h2,
          textAlign: "center",
          marginBottom: theme.spacing(2),
        },
      },
      "& h3": {
        ...theme.typography.h4,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h3,
          textAlign: "center",
          marginBottom: theme.spacing(2),
        },
      },
      "& h4": {
        ...theme.typography.h5,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h4,
          textAlign: "center",
          marginBottom: theme.spacing(2),
        },
      },
      "& p, & ul, & ol": {
        textAlign: "center",
        margin: 0,
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL}`
        ] as CSSProperties),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE}`
          ] as CSSProperties),
        },
      },
      "& p": {
        textAlign: "center",
        marginBottom: theme.spacing(2),
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& a": {
        textAlign: "center",
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL_LINK}`
        ] as CSSProperties),
        color: "inherit",
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE_LINK}`
          ] as CSSProperties),
        },
      },
      "& ul": {
        textAlign: "center",
        listStyle: "none",
      },
      "& ul, & ol": {
        textAlign: "center",
        paddingLeft: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: 13,
        marginTop: 15,
        marginBottom: theme.spacing(2),
        [formatMedia.BREAKPOINT_TABLET]: {
          gap: 15,
        },
      },
      "& ul li": {
        textAlign: "center",
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          width: 8,
          height: 8,
          display: "block",
          border: "2px solid #000",
          borderRadius: "50%",
          top: 9,
          left: -24,
          [formatMedia.BREAKPOINT_TABLET]: {
            top: 12,
          },
        },
      },
      "& > :last-child": {
        textAlign: "center",
        marginBottom: 0,
      },
    },
    [`& .${classes.link}`]: {
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(1.5),
      padding: "0 !important",
      fontWeight: FontWeights.FONT_WEIGHT_BOLD,
    },
  };
});

export const MiddleTextLink = ({
  description,
  link,
  testAutomationId = "middle-text-link",
}: MiddleTextLinkProps) => {
  const params = useParams();

  return (
    <StyledMiddleTextLink
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      {description && (
        <Markdown className={classes.description} text={description} />
      )}
      <CombinedLink
        href={getLinkUrl(params?.locale as string, link?.url as string)}
        underline="none"
      >
        <CTALinkButton className={classes.link} typographyProps={{ variant: "h4" }}>
          {link?.label as string}
        </CTALinkButton>
      </CombinedLink>
    </StyledMiddleTextLink>
  );
};
