import React, { useMemo } from "react";

import { Box, styled } from "@mui/material";
import { useParams, usePathname, useSearchParams } from "next/navigation";

import { CombinedButton } from "~/components/CombinedButton";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getPaginateUrl } from "~/utils/getPaginateUrl";
import { getPageLocale } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { DEFAULT_LOAD_PREVIOUS_BUTTON_TEXT } from "./LoadPreviousButton.constants";

export interface LoadPreviousButtonProps {
  disabled?: boolean;
  pageSize: number;
  onClick?: () => void;
  loadPreviousButtonText?: string;
  currentPage: number;
}

const StyledBox = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    textAlign: "center",
    marginBlock: theme.spacing(2, -2),

    [formatMedia.BREAKPOINT_TABLET]: {
      marginBlock: theme.spacing(4, 0),
    },
  };
});

export const LoadPreviousButton = ({
  disabled,
  pageSize,
  onClick,
  loadPreviousButtonText,
  currentPage,
}: LoadPreviousButtonProps) => {
  const { locale } = useParams();
  const pageLocale = getPageLocale(locale as string);
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const fullPath = `${pathName}?${searchParams.toString()}`;

  const loadPreviousButtonTextUpdated = useMemo(
    () =>
      (loadPreviousButtonText ?? DEFAULT_LOAD_PREVIOUS_BUTTON_TEXT).replace(
        "{{number}}",
        pageSize.toString()
      ),
    [loadPreviousButtonText, pageSize]
  );

  const handleLinkClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClick?.();
  };

  const buttonHref = getPaginateUrl({
    currentPage,
    direction: "prev",
    fullPath,
    pageLocale,
  });

  return (
    <StyledBox>
      <CombinedButton
        size="large"
        variant="outlined"
        disabled={disabled}
        onClick={handleLinkClick}
        href={buttonHref}
        {...getTestAutomationProps("load-previous-button")}
      >
        {loadPreviousButtonTextUpdated}
      </CombinedButton>
    </StyledBox>
  );
};
