import React from "react";

import { Box, Grid } from "@mui/material";

import { ArticleCardsProps } from "~/bff/components/ArticleCards";
import { getTestAutomationProps } from "~/utils/testUtils";

export const ArticleCards = ({ articles, testAutomationId }: ArticleCardsProps) => {
  if (!articles?.length) {
    return null;
  }

  return (
    <Box
      sx={{ paddingX: { xs: 2, sm: 8 } }}
      {...getTestAutomationProps(testAutomationId ?? "article-cards")}
    >
      <Grid container spacing={{ xs: 2, sm: 6 }}>
        {articles.map((article, index) => (
          <Grid key={index} item xs={12} sm={6} sx={{ padding: { xs: 0, sm: 0 } }}>
            {article as React.ReactElement}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
