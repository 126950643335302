import { ImageComponent } from "~/bff/types/ImageComponent";
import { MediaAsset } from "~/bff/types/MediaAsset";

import { IMAGE_COMPONENT_NAME } from "./MediaLibrary.constants";

export const filterAssets = (
  assets: MediaAsset[],
  category: string,
  allAssetsTabText: string
): MediaAsset[] => {
  return assets?.filter((asset) => {
    const isSameCategory = asset.props?.assetCategory?.props?.title === category;
    const isAllCategory = allAssetsTabText === category;
    const isImage =
      (asset.props?.dynamicAsset as ImageComponent)?.component ===
      IMAGE_COMPONENT_NAME;
    const isPdf = asset.props?.pdfProps?.url !== "";

    return (isSameCategory || isAllCategory) && (isImage || isPdf);
  });
};
