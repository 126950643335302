import { useEffect, useState } from "react";

import { nanoid } from "nanoid";
import { useDebounce } from "use-debounce";

import { WindowState } from "./useWindowState.types";

export const useWindowState = (): WindowState => {
  const [windowState, setWindowState] = useState<WindowState>(() => ({
    width: 0,
    height: 0,
    token: nanoid(),
  }));
  const [windowStateCached] = useDebounce(windowState, 300);

  useEffect(() => {
    const handleResize = () =>
      setWindowState({
        width: window.innerWidth,
        height: window.innerHeight,
        token: nanoid(),
      });
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowStateCached;
};
