import React, { ReactElement } from "react";

import { cx } from "@emotion/css";
import { Box, IconButton, IconButtonProps, styled } from "@mui/material";

import { Left32, Right32 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { CarouselLayoutProps } from "../Carousel.types";

const PREFIX = "StyledCarouselNavButtons";
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  buttonLeft: `${PREFIX}-buttonLeft`,
  buttonRight: `${PREFIX}-buttonRight`,
};

const StyledCarouselNavButtons = styled(Box, {
  shouldForwardProp: (props) => props !== "layout",
})<CarouselLayoutProps>(({ theme, layout }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      zIndex: 1001,
      textAlign: layout,
      whiteSpace: "nowrap",
      display: "inline-block",
      width: theme.spacing(2),
      minWidth: theme.spacing(2),
      paddingBottom: theme.spacing(15),

      [formatMedia.BREAKPOINT_TABLET]: {
        width: theme.spacing(4),
        minWidth: theme.spacing(4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: "28%",
        minWidth: "28%",
      },
    },
    [`& .${classes.button}`]: {
      display: "none",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "inline-flex",
      },
    },
    [`& .${classes.buttonLeft}`]: {
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginLeft: layout === "left" ? theme.spacing(3.5) : "unset",
      },
    },
    [`& .${classes.buttonRight}`]: {
      marginLeft: theme.spacing(1),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginRight: layout === "right" ? theme.spacing(3.5) : "unset",
      },
    },
  };
});

interface CarouselNavButtonsProps extends CarouselLayoutProps {
  leftButtonIcon?: ReactElement;
  rightButtonIcon?: ReactElement;
  leftButtonProps?: IconButtonProps;
  rightButtonProps?: IconButtonProps;
  className?: string;
}

export const CarouselNavButtons = ({
  className,
  leftButtonProps,
  rightButtonProps,
  leftButtonIcon = <Left32 />,
  rightButtonIcon = <Right32 />,
  layout = "right",
}: CarouselNavButtonsProps) => {
  return (
    <StyledCarouselNavButtons
      layout={layout}
      className={cx(classes.root, className)}
      {...getTestAutomationProps("carousel-nav-buttons")}
    >
      <IconButton
        aria-label="Previous"
        className={cx(classes.button, classes.buttonLeft)}
        {...getTestAutomationProps("carousel-nav-button-left")}
        {...leftButtonProps}
      >
        {leftButtonIcon}
      </IconButton>
      <IconButton
        aria-label="Next"
        className={cx(classes.button, classes.buttonRight)}
        {...getTestAutomationProps("carousel-nav-button-right")}
        {...rightButtonProps}
      >
        {rightButtonIcon}
      </IconButton>
    </StyledCarouselNavButtons>
  );
};
