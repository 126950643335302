import React, { useMemo } from "react";

import { Box, styled, Typography } from "@mui/material";

import { NUMBER_SLUG } from "~/constants/common";
import {
  COLOR_PRIMARY_BLACK,
  COLOR_GREYSCALE_BLACK_15,
} from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";

import { DEFAULT_PROGRESS_BAR_TEXT } from "./ProgressBar.constants";

export interface ProgressBarProps {
  progressBarText?: Nullable<string>;
  current: number;
  total: number;
}

const PREFIX = "StyledProgressBar";
const classes = {
  root: `${PREFIX}-root`,
  centered: `${PREFIX}-centered`,
  bar: `${PREFIX}-bar`,
  indicator: `${PREFIX}-indicator`,
};

const StyledProgressBar = styled(Box, {
  shouldForwardProp: (prop: string) => !["current", "total"].includes(prop),
})<ProgressBarProps>(({ theme, current, total }) => {
  const formatMedia = getFormatMedia(theme);
  const normalizedCurrentProgress = Math.min(1, current / total);

  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),

      [formatMedia.BREAKPOINT_TABLET]: {
        width: "66%",
        maxWidth: 462,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: "50%",
        maxWidth: 504,
      },
    },
    [`& .${classes.centered}`]: {
      textAlign: "center",
    },
    [`& .${classes.bar}`]: {
      position: "relative",
      height: 4,
      backgroundColor: COLOR_GREYSCALE_BLACK_15,
    },
    [`& .${classes.indicator}`]: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: `calc((1 - ${normalizedCurrentProgress}) * 100%)`,
      backgroundColor: COLOR_PRIMARY_BLACK,
    },
  };
});

export const ProgressBar = ({
  progressBarText,
  current,
  total,
}: ProgressBarProps) => {
  const processedCaption = useMemo(() => {
    return (progressBarText ?? DEFAULT_PROGRESS_BAR_TEXT)
      .replace(NUMBER_SLUG, current?.toString())
      .replace(NUMBER_SLUG, total?.toString());
  }, [progressBarText, current, total]);

  return (
    <StyledProgressBar className={classes.root} current={current} total={total}>
      <Box className={classes.bar}>
        <Box className={classes.indicator} />
      </Box>
      <Typography className={classes.centered} variant="body2">
        {processedCaption}
      </Typography>
    </StyledProgressBar>
  );
};
