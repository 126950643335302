import React, { useMemo } from "react";

import { JsonLdProps } from "~/bff/components/JsonLd";
import { useSeoMetaTags } from "~/components/SeoMetaTags/hooks/useSeoMetaTags";
import { replacePlaceholders } from "~/utils/jsonLd";

export const JsonLd = ({ data }: JsonLdProps) => {
  const { jsonLDConfig } = useSeoMetaTags();

  const result = useMemo(() => {
    if (!data) {
      return null;
    }

    let object = JSON.parse(data);
    object = replacePlaceholders(object, jsonLDConfig);

    return JSON.stringify(object, null, 2);
  }, [data, jsonLDConfig]);

  if (!result) {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};
