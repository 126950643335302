import { ResponsiveImageConfig } from "~/components/Image";
import { AmplienceDynamicImageConfigKeys } from "~/components/Image/Image.types";

export const RESPONSIVE_IMAGE_CONFIG: ResponsiveImageConfig = {
  wideDesktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 1200,
  },
  desktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 1200,
  },
  tablet: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 1024,
  },
  mobile: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 700,
  },
};
