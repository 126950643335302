import React from "react";

import { Typography, Grid, styled } from "@mui/material";

import { CountrySelectorChildrenProps } from "~/bff/components/CountrySelectorChildren";
import { FooterSocialLinksItemProps } from "~/bff/components/FooterSocialLinksItem";
import {
  CountrySelector,
  CountrySelectorPropsExtended,
} from "~/components/CountrySelector";
import { getTestAutomationProps } from "~/utils/testUtils";

import { LinkItem } from "../LinkItem";

interface GroupedLinksColumnProps {
  headline: string;
  setSelectedCountry: CountrySelectorPropsExtended["onChange"];
  showCountrySelector: boolean;
  countries: CountrySelectorChildrenProps[];
  selectedCountry?: CountrySelectorChildrenProps;
  links: FooterSocialLinksItemProps[];
  title?: string;
  description?: string;
}

const PREFIX = "StyledGroupedLinksColumn";
const classes = {
  headline: `${PREFIX}-headline`,
  countrySelector: `${PREFIX}-countrySelector`,
};

const StyledGroupedLinksColumn = styled(Grid)(({ theme }) => ({
  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(4),
    textTransform: "uppercase",
  },
  [`& .${classes.countrySelector}`]: {
    maxWidth: 240,
    marginTop: theme.spacing(4),
  },
}));

export const GroupedLinksColumn = ({
  headline,
  links,
  selectedCountry,
  setSelectedCountry,
  showCountrySelector,
  countries,
  title,
  description,
}: GroupedLinksColumnProps) => {
  return (
    <StyledGroupedLinksColumn
      item
      lg={3}
      md={3}
      sm={3}
      {...getTestAutomationProps("footer-links-group")}
    >
      <Typography
        variant="body1"
        className={classes.headline}
        {...getTestAutomationProps("title")}
      >
        {headline.toUpperCase()}
      </Typography>
      {links.map(({ link }, index) => (
        <LinkItem key={index} link={link} />
      ))}
      {showCountrySelector && (
        <CountrySelector
          className={classes.countrySelector}
          value={selectedCountry}
          onChange={setSelectedCountry}
          countries={countries}
          title={title}
          description={description}
        />
      )}
    </StyledGroupedLinksColumn>
  );
};
