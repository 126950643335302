import React from "react";

import { cx } from "@emotion/css";
import { Stack, styled } from "@mui/material";

import { HeaderProps } from "~/bff/components/Header";
import { CombinedButton } from "~/components/CombinedButton";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledHeaderNavLinksLandscape";
const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
};

const StyledHeaderNavLinksLandscape = styled(Stack)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: "40px",
    columnGap: theme.spacing(4),
  },
  [`& .${classes.link}.body2`]: {
    ...theme.typography.body2,
    height: "20px",
  },
}));

export interface HeaderNavLinksLandscapeProps {
  links: HeaderProps["navigationLinks"];
}

export const HeaderNavLinksLandscape = ({ links }: HeaderNavLinksLandscapeProps) => {
  return (
    <StyledHeaderNavLinksLandscape
      spacing={4}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.root}
      {...getTestAutomationProps("header-nav-links-landscape")}
    >
      {links?.map((item, index) => (
        <CombinedButton
          key={index}
          variant="text"
          href={item?.link?.url ?? ""}
          className={cx(classes.link, "body2")}
          {...getTestAutomationProps("header-nav-link")}
        >
          {item?.link?.label}
        </CombinedButton>
      ))}
    </StyledHeaderNavLinksLandscape>
  );
};
