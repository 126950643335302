import { paginate } from "./paginate";

export function getAccumulateData<T>(
  allItems: T[],
  currentItems: T[],
  page: number,
  size: number,
  isAppend = true
): T[] {
  const { start, end } = paginate({
    total: allItems?.length,
    current: page,
    size,
  });
  const display = allItems?.length > 0 ? allItems?.slice(start, end + 1) : [];

  return isAppend ? [...currentItems, ...display] : [...display, ...currentItems];
}
