import React, { MouseEventHandler, useCallback, useMemo } from "react";

import { styled, Grid, Box, Typography, IconButton } from "@mui/material";
import dayjs from "dayjs";

import { MediaAssetProps } from "~/bff/components/MediaAsset";
import { ImageComponent } from "~/bff/types/ImageComponent";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { Image } from "~/components/Image";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_PRIMARY_BLACK,
} from "~/theme/constants/colors";
import { Download } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { downloadAsset } from "~/utils/downloadAsset";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { getTestAutomationProps } from "~/utils/testUtils";

import {
  IMAGE_COMPONENT_NAME,
  IMAGE_EXT,
  PDF_EXT,
  PDF_IMAGE_PATH,
  PUBLISH_DATE_FORMAT,
  RESPONSIVE_IMAGE_CONFIG,
} from "../MediaLibrary.constants";

const PREFIX = "StyledAssetTile";
const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  pdf: `${PREFIX}-pdf`,
  info: `${PREFIX}-info`,
  download: `${PREFIX}-download`,
  title: `${PREFIX}-title`,
  buttonLabel: `${PREFIX}-buttonLabel`,
};

const StyledAssetTile = styled(Grid)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      display: "flex",
      flexDirection: "column",
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(5),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(5),
      },
      "& a": {
        textDecoration: "none",
      },
    },
    [`& .${classes.image}`]: {
      width: "100%",
      maxWidth: "100%",
      aspectRatio: "1/1",
      objectFit: "contain",
      display: "block",
    },
    [`& .${classes.pdf}`]: {
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
      aspectRatio: "1/1",
      border: `${theme.spacing(0.25)} solid ${COLOR_ADDITIONAL_WARM_GRAY_3U}`,
    },
    [`& .${classes.info}`]: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      color: COLOR_PRIMARY_BLACK,
    },
    [`& .${classes.download}`]: {
      padding: theme.spacing(0),
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [`& .${classes.buttonLabel}`]: {
      fontSize: 0,
      height: 1,
      overflow: "hidden",
      display: "block",
    },
  };
});

export const AssetTile = (props: MediaAssetProps) => {
  const { title, dynamicAsset, pdfProps, publishDate } = props;

  const asset = dynamicAsset?.[0] as ImageComponent;
  const isImageComponent = asset?.component === IMAGE_COMPONENT_NAME;

  const href = useMemo(
    () =>
      isImageComponent
        ? getImageSrc(asset?.props?.source as ImageSource)
        : pdfProps?.url ?? "",
    [isImageComponent, asset, pdfProps]
  );
  const assetExt = isImageComponent ? IMAGE_EXT : PDF_EXT;
  const imageSrc = isImageComponent ? href : PDF_IMAGE_PATH;

  const handleDownload: MouseEventHandler = useCallback(
    (e) => {
      const fullFileName = `${title}.${assetExt?.toLowerCase()}`;
      const downloadUrl =
        assetExt.toLowerCase() === "pdf"
          ? href
          : `${href}.${assetExt?.toLowerCase()}`;

      e.preventDefault();

      downloadAsset(fullFileName, downloadUrl);
    },
    [title, assetExt, href]
  );

  return (
    <StyledAssetTile
      item
      md={3}
      sm={6}
      xs={12}
      className={classes.root}
      {...getTestAutomationProps("asset-tile")}
    >
      <CombinedLink href={href} target="_blank">
        <Box>
          <Image
            className={isImageComponent ? classes.image : classes.pdf}
            responsiveImageConfig={
              asset?.props?.source && isImageComponent
                ? RESPONSIVE_IMAGE_CONFIG
                : undefined
            }
            src={imageSrc}
            alt={title ?? ""}
            title={title ?? ""}
            {...prepareImageA11yProps(asset?.props)}
            {...getTestAutomationProps("asset-image")}
          />
        </Box>
        <Box className={classes.info}>
          <Box>
            <Typography
              className={classes.title}
              component="p"
              variant="body1"
              {...getTestAutomationProps("title")}
            >
              {title}
            </Typography>
            <Typography
              className={classes.title}
              component="p"
              variant="body1"
              color={COLOR_GREYSCALE_BLACK_54}
              {...getTestAutomationProps("publish-date")}
            >
              {dayjs(publishDate).format(PUBLISH_DATE_FORMAT)}
            </Typography>
          </Box>
          <IconButton className={classes.download} onClick={handleDownload}>
            <Download />
            <Typography className={classes.buttonLabel}>Download {title}</Typography>
          </IconButton>
        </Box>
      </CombinedLink>
    </StyledAssetTile>
  );
};
