import React from "react";

import { cx } from "@emotion/css";
import {
  Button,
  ButtonProps,
  Typography,
  styled,
  TypographyProps,
} from "@mui/material";

import { TRANSITION_DURATION_200 } from "~/constants/common";
import {
  COLOR_PRIMARY_WHITE,
  COLOR_PRIMARY_BLACK,
  COLOR_BLUE_BRAND_50,
  COLOR_PRIMARY,
} from "~/theme/constants/colors";
import { TextColor, ARIAL_BLACK_FONT_FAMILY } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

interface CTALinkButtonProps extends ButtonProps {
  textColor?: TextColor;
  linkTextClassName?: string;
  typographyProps?: TypographyProps;
}

const PREFIX = "StyledCTALinkButton";
const classes = {
  root: `${PREFIX}-root`,
  linkText: `${PREFIX}-linkText`,
};

const StyledCTALinkButton = styled(Button)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(1.5)} 6px ${theme.spacing(1.5)} 0px`,
      height: "unset",
      width: "fit-content",
      "&:hover": {
        background: "none",
      },
    },
    [`& .${classes.linkText}`]: {
      position: "relative",
      width: "fit-content",
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold as number,
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      color: COLOR_PRIMARY_BLACK,
      zIndex: 2,
      lineHeight: 1.3333,
      [formatMedia.BREAKPOINT_TABLET]: { fontSize: 18 },

      "&::after": {
        position: "absolute",
        content: '" "',
        bottom: 0,
        right: -6,
        width: "100%",
        height: "8px",
        background: COLOR_BLUE_BRAND_50,
        zIndex: -1,
        transition: TRANSITION_DURATION_200,
      },

      "&:hover": {
        "&::after": {
          height: "100%",
        },
      },

      "&:active": {
        "&::after": {
          background: COLOR_PRIMARY,
        },
      },
    },
  };
});

export const CTALinkButton = React.forwardRef<HTMLButtonElement, CTALinkButtonProps>(
  (props: CTALinkButtonProps, ref) => {
    const {
      className,
      linkTextClassName,
      textColor,
      typographyProps,
      ...baseProps
    } = props;
    const currentTextColor = textColor === "light" ? COLOR_PRIMARY_WHITE : "unset";

    return (
      <StyledCTALinkButton
        ref={ref}
        className={cx(classes.root, className)}
        {...getTestAutomationProps("cta-link-button")}
        {...baseProps}
      >
        <Typography
          style={{ color: currentTextColor }}
          className={cx(classes.linkText, linkTextClassName)}
          {...typographyProps}
        >
          {props.children}
        </Typography>
      </StyledCTALinkButton>
    );
  }
);

CTALinkButton.displayName = "CTALinkButton";
