import React, { useCallback, memo, useContext } from "react";

import { MenuItem } from "@mui/material";

import { NavItem } from "~/bff/types/NavItem";
import { CombinedLink } from "~/components/CombinedLink";
import { getNavUrl } from "~/components/GlobalNavigation/helper/getNavUrl";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

import { NavMenuLandscapeContext } from "../NavMenuLandscapeContext";

interface SubMenuItemProps {
  anchorId: string;
  className?: string;
  data: Nullable<NavItem>;
  onMenuClose: (event: React.SyntheticEvent) => void;
}

export const SubMenuItem = memo(
  ({ className, anchorId, data, onMenuClose }: SubMenuItemProps) => {
    const { setNavigationMode } = useContext(NavMenuLandscapeContext);
    const { title } = data?.props ?? {};
    const navUrl = getNavUrl(data?.props);

    const handleKeyUp = useCallback((event: React.KeyboardEvent) => {
      // Prevent "Space" bar keyup event from triggering click event
      if (event.code === "Space") {
        event.preventDefault();
      }
    }, []);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        setNavigationMode("mouse");
        event.currentTarget.focus();
      },
      [setNavigationMode]
    );

    return (
      <MenuItem
        id={anchorId}
        disableRipple
        disableTouchRipple
        onClick={onMenuClose}
        onKeyUp={handleKeyUp}
        className={className}
        onMouseEnter={handleMouseEnter}
        href={navUrl}
        component={CombinedLink}
        {...getTestAutomationProps("sub-menu-item")}
      >
        {title ?? ""}
      </MenuItem>
    );
  }
);

SubMenuItem.displayName = "SubMenuItem";
