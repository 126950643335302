"use client";
import React from "react";

import { Grid, Box, styled } from "@mui/material";
import { useParams } from "next/navigation";

import { PartnerLogosProps } from "~/bff/components/PartnerLogos";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { Image } from "~/components/Image";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_BLACK,
} from "~/theme/constants/colors";
import { getImageSrc } from "~/utils/image";
import { getLinkUrl } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

const StyledImage = styled(Image)({
  maxWidth: "100%",
  aspectRatio: "1/1",
  objectFit: "scale-down",
  display: "block",
});

export const PartnerLogos = ({ logos, testAutomationId }: PartnerLogosProps) => {
  const { locale } = useParams();

  if (!logos?.length) {
    return null;
  }

  return (
    <Box
      sx={{ paddingX: { xs: 2, md: 21.875 } }}
      {...getTestAutomationProps(testAutomationId ?? "partners-logo")}
    >
      <Grid container spacing={0.5}>
        {logos.map(({ image, url }, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={4}
            md={3}
            {...getTestAutomationProps("partner-logo")}
          >
            <CombinedLink
              href={getLinkUrl(locale as string, url as string)}
              underline="none"
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
                  padding: {
                    xs: 3,
                    md: 6,
                  },
                  "&:hover": {
                    border: 2,
                    borderColor: COLOR_BLACK,
                  },
                }}
              >
                <StyledImage
                  src={getImageSrc(image?.props?.source as ImageSource)}
                  alt={image?.props?.alt ?? ""}
                />
              </Box>
            </CombinedLink>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
