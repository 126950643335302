type callback = () => void;

const subscriptions: callback[] = [];

export function onComplete() {
  subscriptions.forEach((subscription) => {
    subscription();
  });
}

export const onRouteChangeComplete = (handler: callback) => {
  subscriptions.push(handler);
};

export const offRouteChangeComplete = (handler: callback) => {
  subscriptions.push(handler);
};
