import React, { ReactElement } from "react";

import { Box, styled } from "@mui/material";

import { HomePageProps } from "~/bff/components/HomePage";
import { useScrollRestoration } from "~/hooks/useScrollRestoration";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { HeadingBanner } from "../HeadingBanner";
import { SeoMetaTags } from "../SeoMetaTags";

const PREFIX = "StyledHomePage";
const classes = { root: `${PREFIX}-root` };

const StyledHomePage = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      paddingTop: 0,
    },

    "&>:not([hidden])~:not([hidden])": {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
      },
    },
  };
});

export const HomePage = ({
  children,
  seo,
  localizedPages,
  heroBanner,
  testAutomationId,
  ...headingBannerProps
}: HomePageProps) => {
  useScrollRestoration();

  return (
    <StyledHomePage
      className={classes.root}
      {...getTestAutomationProps("home-page")}
    >
      {seo && <SeoMetaTags {...seo} />}
      {heroBanner ? (
        (heroBanner as ReactElement)
      ) : (
        <HeadingBanner {...headingBannerProps} />
      )}
      {children as Array<React.ReactElement>}
    </StyledHomePage>
  );
};
