"use client";
import React from "react";

import { styled, Box, Typography } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import { useParams } from "next/navigation";

import { TwoColumnContentSectionProps } from "~/bff/components/TwoColumnContentSection";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { CTALinkButton } from "~/components/CTALinkButton";
import { Image } from "~/components/Image";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { getLinkUrl } from "~/utils/locales";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledTwoColumnContentSection";
const classes = {
  root: `${PREFIX}-root`,
  imageWrapper: `${PREFIX}-image`,
  infoWrapper: `${PREFIX}-infoWrapper`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
};

const StyledTwoColumnContentSection = styled(Box)<TwoColumnContentSectionProps>(
  ({ theme }) => {
    const formatMedia = getFormatMedia(theme);
    return {
      [`&.${classes.root}`]: {
        width: "100%",
        display: "grid",
        gap: theme.spacing(3.25),
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",

        "&:not(:last-child)": {
          marginBottom: theme.spacing(5.5),
        },

        [formatMedia.BREAKPOINT_TABLET]: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: theme.spacing(6),

          "&:nth-of-type(2n)": {
            flexDirection: "row-reverse",
          },
        },
      },
      [`& .${classes.imageWrapper}`]: {
        flex: 1,
        gridRow: "1 / 2",
        gridColumn: "1 / 1",

        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          aspectRatio: "1.3 / 1",
        },
      },
      [`& .${classes.infoWrapper}`]: {
        flex: 1,
        gridRow: "2 / 3",
        gridColumn: "1 / 1",
      },
      [`& .${classes.title}`]: {
        marginBottom: theme.spacing(3.25),
        ...theme.typography.h4,

        [formatMedia.BREAKPOINT_TABLET]: {
          marginBottom: theme.spacing(4),
          ...theme.typography.h3,
        },
      },
      [`& .${classes.description}`]: {
        textAlign: "left",
        marginBottom: theme.spacing(3.25),
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL}`
        ] as CSSProperties),

        [formatMedia.BREAKPOINT_TABLET]: {
          textAlign: "left",
          marginBottom: theme.spacing(4),
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE}`
          ] as CSSProperties),
        },
      },
    };
  }
);

export const TwoColumnContentSection = ({
  image,
  link,
  title,
  description,
  testAutomationId = "TwoColumnContentSection",
}: TwoColumnContentSectionProps) => {
  const params = useParams();
  return (
    <StyledTwoColumnContentSection
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      <Box className={classes.imageWrapper}>
        <Image
          src={getImageSrc(image?.source as ImageSource)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
          {...prepareImageA11yProps(image)}
        />
      </Box>
      <Box className={classes.infoWrapper}>
        <Typography
          className={classes.title}
          variant="h2"
          {...getTestAutomationProps("title")}
        >
          {title}
        </Typography>
        <Typography
          className={classes.description}
          align="center"
          component="p"
          {...getTestAutomationProps("description")}
        >
          {description}
        </Typography>
        <CombinedLink
          href={getLinkUrl(params?.locale as string, link?.url as string)}
          underline="none"
        >
          <CTALinkButton textColor="black" typographyProps={{ variant: "h3" }}>
            {link?.label}
          </CTALinkButton>
        </CombinedLink>
      </Box>
    </StyledTwoColumnContentSection>
  );
};
