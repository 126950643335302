import React from "react";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";

import { FooterSocialLinksItemProps } from "~/bff/components/FooterSocialLinksItem";

import { LinkItem } from "../LinkItem";

const PREFIX = "StyledFooterAccordion";
const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
};

const StyledFooterAccordion = styled(Accordion)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      "&:last-child": {
        "&:after": {
          display: "none",
        },

        [`& .${classes.content}`]: {
          paddingBottom: theme.spacing(3),
        },
      },
    },
    [`& .${classes.header}`]: {
      paddingRight: 0,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),

      "&.Mui-expanded": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3.75),
      },

      "& .MuiAccordionSummary-content .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
        textTransform: "uppercase",
        fontWeight: 400,
      },

      "& .MuiAccordionSummary-expandIconWrapper": {
        paddingTop: 0,
      },
    },
    [`& .${classes.content}`]: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.02em",
      paddingBottom: theme.spacing(3.75),

      "& .MuiTypography-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.02em",
      },
    },
  };
});

interface GroupedLinksColumnMobileProps {
  headline: string;
  links: FooterSocialLinksItemProps[];
}

export const GroupedLinksColumnMobile = ({
  headline,
  links,
}: GroupedLinksColumnMobileProps) => {
  return (
    <StyledFooterAccordion className={classes.root}>
      <AccordionSummary className={classes.header}>
        <Typography>{headline.toUpperCase()}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>
        {links.map(({ link }, index) => (
          <LinkItem key={index} link={link} />
        ))}
      </AccordionDetails>
    </StyledFooterAccordion>
  );
};
