"use client";
import { Header as HeaderComponent } from "~/bff/components/Header";
import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { Accordion } from "~/components/Accordion";
import { ArticleCards } from "~/components/ArticleCards";
import { ArticleCard } from "~/components/ArticleCards/components/ArticleCard";
import { ArticlePage } from "~/components/ArticlePage";
import { ArticlesCarousel } from "~/components/ArticlesCarousel";
import { Author } from "~/components/Author";
import { Banner } from "~/components/Banner";
import { DidYouKnowComponent } from "~/components/DidYouKnowComponent";
import { Footer } from "~/components/Footer";
import { GcpPage } from "~/components/GcpPage";
import { GenericHeading } from "~/components/GenericHeading";
import { GlobalLayout } from "~/components/GlobalLayout";
import { Header } from "~/components/Header";
import { HeroBanner } from "~/components/HeroBanner";
import { HomePage } from "~/components/HomePage";
import { Images } from "~/components/Images";
import { JsonLd } from "~/components/JsonLd";
import { MediaEnquiries } from "~/components/MediaEnquiries";
import { MediaLibrary } from "~/components/MediaLibrary";
import { MiddleTextLink } from "~/components/MiddleTextLink";
import { NewsRoomPage } from "~/components/NewsRoomPage/NewsRoomPage";
import { Page404 } from "~/components/Page404";
import { PartnerLogoRichText } from "~/components/PartnerLogoRichText";
import { PartnerLogos } from "~/components/PartnerLogos";
import { PdfDownload } from "~/components/PdfDownload";
import { PrimarkJourney } from "~/components/PrimarkJourney";
import { RichTextBlock } from "~/components/RichTextBlock";
import { SeoJsonLdContainer } from "~/components/SeoMetaTags/components/SeoJsonLdContainer";
import {
  TwoColumnContentSection,
  TwoColumnContent,
} from "~/components/TwoColumnContent";
import { Video } from "~/components/Video";

export const COMPONENTS_CONFIG: ComponentsConfig = {
  // LAYOUT:
  [Component.GLOBAL_LAYOUT]: {
    component: GlobalLayout,
  },

  // PAGES:
  [Component.HOME_PAGE]: {
    component: HomePage,
  },

  [Component.NOT_FOUND]: {
    component: Page404,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    shouldStopChildrenPreparation: true,
  },
  [Component.GCP]: {
    component: GcpPage,
  },

  // COMPONENTS
  [Component.BANNER]: {
    component: Banner,
  },
  [Component.HEADER]: {
    component: Header as HeaderComponent,
    shouldStopChildrenPreparation: true,
  },
  [Component.TWO_COLUMN_CONTENT]: {
    component: TwoColumnContent,
  },
  [Component.TWO_COLUMN_CONTENT_SECTION]: {
    component: TwoColumnContentSection,
  },
  [Component.VIDEO]: {
    component: Video,
  },
  [Component.IMAGES]: {
    component: Images,
  },
  [Component.ARTICLE_PAGE]: {
    component: ArticlePage,
  },
  [Component.ARTICLE_CARDS]: {
    component: ArticleCards,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: ArticleCard,
        shouldStopChildrenPreparation: true,
      },
    },
  },
  [Component.ARTICLES_CAROUSEL]: {
    component: ArticlesCarousel,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: ArticleCard,
        shouldStopChildrenPreparation: true,
      },
    },
  },

  [Component.FOOTER]: {
    component: Footer,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    shouldStopChildrenPreparation: true,
  },
  [Component.ACCORDION]: {
    component: Accordion,
  },
  [Component.RICH_TEXT_BLOCK]: {
    component: RichTextBlock,
  },
  [Component.DID_YOU_KNOW]: {
    component: DidYouKnowComponent,
  },
  [Component.MIDDLE_TEXT_LINK]: {
    component: MiddleTextLink,
  },
  [Component.PARTNER_LOGOS]: {
    component: PartnerLogos,
    shouldStopChildrenPreparation: true,
  },
  [Component.GENERIC_HEADING]: {
    component: GenericHeading,
    shouldStopChildrenPreparation: true,
  },
  [Component.AUTHOR]: {
    component: Author,
  },
  [Component.MEDIA_ENQUIRIES]: {
    component: MediaEnquiries,
    shouldStopChildrenPreparation: true,
  },
  [Component.PDF_DOWNLOAD]: {
    component: PdfDownload,
    shouldStopChildrenPreparation: true,
  },
  [Component.JSON_LD_CONTAINER]: {
    component: SeoJsonLdContainer,
  },
  [Component.JSON_LD]: {
    component: JsonLd,
  },
  [Component.PARTNER_LOGO_RICH_TEXT]: {
    component: PartnerLogoRichText,
  },
  [Component.NEWSROOM_PAGE]: {
    component: NewsRoomPage,
    shouldStopChildrenPreparation: true,
  },
  [Component.GENERIC_MEDIA_LIBRARY]: {
    component: MediaLibrary,
    shouldStopChildrenPreparation: true,
  },
  [Component.PRIMARK_JOURNEY]: {
    component: PrimarkJourney,
  },
  [Component.HERO_BANNER]: {
    component: HeroBanner,
  },
};
