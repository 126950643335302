import React, { useMemo } from "react";

import { styled, Box } from "@mui/material";
import { useParams, usePathname, useSearchParams } from "next/navigation";

import { CombinedButton } from "~/components/CombinedButton";
import { NUMBER_SLUG } from "~/constants/common";
import { Nullable } from "~/types/utility.types";
import { getPaginateUrl } from "~/utils/getPaginateUrl";
import { getPageLocale } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { ProgressBar } from "./components/ProgressBar";
import { DEFAULT_LOAD_MORE_BUTTON_TEXT } from "./LoadMoreButton.constants";

export interface LoadMoreButtonProps {
  disabled?: boolean;
  totalCount: number;
  onClick?: () => void;
  progressBarText?: Nullable<string>;
  loadMoreButtonText?: Nullable<string>;
  pageSize: number;
  currentPage: number;
}

const PREFIX = "StyledLoadMoreButton";
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  progressBar: `${PREFIX}-progressBar`,
};

const StyledLoadMoreButton = styled(Box)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(10),
    },
    [`& .${classes.button}`]: {
      marginTop: theme.spacing(4),
    },
  };
});

export const LoadMoreButton = ({
  onClick,
  disabled,
  pageSize,
  totalCount,
  progressBarText,
  loadMoreButtonText,
  currentPage = 0,
}: LoadMoreButtonProps) => {
  const { locale } = useParams();
  const pageLocale = getPageLocale(locale as string);
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const fullPath = `${pathName}?${searchParams.toString()}`;

  const showCount = useMemo(
    () => Math.min(currentPage * pageSize, totalCount),
    [totalCount, currentPage, pageSize]
  );

  const remainingCount = useMemo(
    () => Math.min(totalCount - showCount, pageSize),
    [totalCount, showCount, pageSize]
  );

  const totalPages = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize]
  );

  const loadMoreButtonTextParsed = useMemo(() => {
    const text = loadMoreButtonText?.includes(NUMBER_SLUG)
      ? loadMoreButtonText
      : DEFAULT_LOAD_MORE_BUTTON_TEXT;

    return text.replace(NUMBER_SLUG, remainingCount.toString());
  }, [remainingCount, loadMoreButtonText]);

  const handleLinkClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClick?.();
  };

  const hasNextPage = remainingCount > 0 && currentPage < totalPages;
  const buttonHref = getPaginateUrl({
    currentPage,
    direction: "next",
    fullPath,
    pageLocale,
  });

  return (
    <StyledLoadMoreButton className={classes.root}>
      <ProgressBar
        total={totalCount}
        current={showCount}
        progressBarText={progressBarText}
      />
      {hasNextPage && (
        <CombinedButton
          size="large"
          variant="contained"
          onClick={handleLinkClick}
          disabled={disabled}
          className={classes.button}
          href={buttonHref}
          {...getTestAutomationProps("load-more-button")}
        >
          {loadMoreButtonTextParsed}
        </CombinedButton>
      )}
    </StyledLoadMoreButton>
  );
};
