import React, { memo, useCallback, useContext, useMemo, useRef } from "react";

import { cx } from "@emotion/css";
import { Button } from "@mui/material";
import { usePathname, useParams } from "next/navigation";

import { NavItem } from "~/bff/types/NavItem";
import { CombinedButton } from "~/components/CombinedButton";
import { getNavUrl } from "~/components/GlobalNavigation/helper/getNavUrl";
import { DEFAULT_LOCALE } from "~/constants/i18n";
import { TypographyButtonClass } from "~/theme/constants/typography";
import { Nullable } from "~/types/utility.types";
import { getLinkUrl } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { NavMenuLandscape } from "../NavMenuLandscape";
import { NavMenuLandscapeContext } from "../NavMenuLandscape/NavMenuLandscapeContext";

interface MenuTriggerProps {
  isOpen: boolean;
  anchorId: string;
  className: string;
  data: Nullable<NavItem>;
  setActiveNavMenu: React.Dispatch<React.SetStateAction<Nullable<string>>>;
}

export const MenuTrigger = memo(
  ({ className, data, isOpen, anchorId, setActiveNavMenu }: MenuTriggerProps) => {
    const { setNavigationMode } = useContext(NavMenuLandscapeContext);
    const anchorRef = useRef<Nullable<HTMLButtonElement>>(null);
    const pathname = usePathname();
    const { locale } = useParams();

    const { props, children } = data ?? {};
    const { title, urlSlug } = props ?? {};
    const navUrl = getNavUrl(props);

    const routeUrl = getLinkUrl((locale as string) ?? DEFAULT_LOCALE, pathname);
    const itemUrl = getLinkUrl((locale as string) ?? DEFAULT_LOCALE, urlSlug ?? "");
    const isRouteActive = urlSlug && routeUrl === itemUrl;

    const MenuTrigger = useMemo(() => (navUrl ? CombinedButton : Button), [navUrl]);

    const handleMenuClose = useCallback(
      (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setActiveNavMenu(null);

        if (["Escape", "Tab"].includes((event as React.KeyboardEvent).code)) {
          setNavigationMode("keyboard");
          anchorRef.current?.focus();
        }
      },
      [setActiveNavMenu, setNavigationMode]
    );

    const handleMouseEnter = useCallback(() => {
      setNavigationMode("mouse");
      setActiveNavMenu(anchorId);
    }, [anchorId, setActiveNavMenu, setNavigationMode]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        setNavigationMode("keyboard");
        event.stopPropagation();

        switch (event.code) {
          case "Enter": {
            setActiveNavMenu(anchorId);
            return;
          }
          case "Space":
          case "ArrowDown": {
            event.preventDefault();
            setActiveNavMenu(anchorId);
            return;
          }
          case "ArrowRight": {
            const { nextElementSibling, parentElement } = event.currentTarget;

            if (nextElementSibling) {
              (nextElementSibling as HTMLElement)?.focus();
            } else {
              (parentElement?.firstElementChild as HTMLElement)?.focus();
            }

            return;
          }
          case "ArrowLeft": {
            const { previousElementSibling, parentElement } = event.currentTarget;

            if (previousElementSibling) {
              (previousElementSibling as HTMLElement)?.focus();
            } else {
              (parentElement?.lastElementChild as HTMLElement)?.focus();
            }

            return;
          }
          case "Home": {
            const { firstElementChild } = event.currentTarget.parentElement ?? {};

            event.preventDefault();
            (firstElementChild as HTMLElement)?.focus();
            return;
          }

          case "End": {
            const { lastElementChild } = event.currentTarget.parentElement ?? {};

            event.preventDefault();
            (lastElementChild as HTMLElement)?.focus();
            return;
          }
          default:
            return;
        }
      },
      [anchorId, setActiveNavMenu, setNavigationMode]
    );

    return (
      <MenuTrigger
        variant="text"
        role="menuitem"
        id={anchorId}
        key={anchorId}
        ref={anchorRef}
        data-menu-active={isOpen}
        className={cx(className, {
          [TypographyButtonClass.MOBILE_LIGHT]: !isRouteActive,
        })}
        onKeyDown={handleKeyDown}
        onMouseLeave={handleMenuClose}
        onMouseEnter={handleMouseEnter}
        aria-haspopup="menu"
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={isOpen ? "nav-menu-level-1" : undefined}
        {...getTestAutomationProps("menu-trigger")}
        {...(navUrl && { href: navUrl })}
      >
        {title ?? ""}
        <NavMenuLandscape
          isOpen={isOpen}
          anchorId={anchorId}
          itemList={children ?? []}
          anchorEl={anchorRef.current}
          onMenuClose={handleMenuClose}
        />
      </MenuTrigger>
    );
  }
);

MenuTrigger.displayName = "MenuTrigger";
