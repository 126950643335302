export const isSamePage = (baseUrl: string, checkingUrl: string): boolean => {
  if (!baseUrl || !checkingUrl) {
    return false;
  }

  const basePage = baseUrl?.split("?")?.[0];
  const checkingPage = checkingUrl?.split("?")?.[0];

  return basePage.includes(checkingPage);
};
