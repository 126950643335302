"use client";
import React, { useRef, useState } from "react";

import { Box, styled } from "@mui/material";

import { COLOR_BLACK } from "~/theme/constants/colors";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

import { GlobalNavigationProps } from "../../GlobalNavigation.types";
import { filterNavItems } from "../../helper/filterNavItems";

import { MenuTrigger } from "./MenuTrigger";
import { NavMenuLandscapeProvider } from "./NavMenuLandscape/NavMenuLandscapeContext";

const PREFIX = "StyledGlobalNavigationLandscape";
const classes = {
  root: `${PREFIX}-root`,
  menuTrigger: `${PREFIX}-menuTrigger`,
};

const StyledGlobalNavigationLandscape = styled(Box)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: theme.spacing(1.5),
    },
    [`& .${classes.menuTrigger}`]: {
      height: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: `0 ${theme.spacing(2)}`,
      position: "relative",
      ...theme.typography.button,

      "&:hover, &:active": {
        background: "none",
      },

      // Due to unknown reasons, MUI's focusVisible state is stale even when the element is
      // already blurred. The element is stuck with a blue outline both on keyboard navigation
      // and on mouse hover. So these CSS rules are to make sure the blue outline only appear when
      // the user is focusing the element with the keyboard.
      "&.Mui-focusVisible:before": {
        display: "none",
      },

      "&.Mui-focusVisible:focus:before": {
        display: "block",
      },

      '&[data-menu-active="false"]:after': {
        width: 0,
        content: "''",
        height: "2px",
        transition: `width ${theme.transitions.duration.leavingScreen}ms`,
        backgroundColor: COLOR_BLACK,
        transform: "translateX(-50%)",
        position: "absolute",
        left: "50%",
        bottom: 0,
      },

      '&[data-menu-active="true"]:after': {
        content: "''",
        height: "2px",
        width: `calc(100% - ${theme.spacing(4)})`,
        transition: `width ${theme.transitions.duration.enteringScreen}ms`,
        backgroundColor: COLOR_BLACK,
        transform: "translateX(-50%)",
        position: "absolute",
        left: "50%",
        bottom: 0,
      },
    },
  };
});

export const GlobalNavigationLandscape = ({
  globalNavigation,
}: GlobalNavigationProps) => {
  const [activeNavMenu, setActiveNavMenu] = useState<Nullable<string>>(null);
  const menubarRef = useRef<HTMLDivElement>();

  return (
    <StyledGlobalNavigationLandscape
      role="menubar"
      ref={menubarRef}
      className={classes.root}
      {...getTestAutomationProps("global-nav-landscape")}
    >
      <NavMenuLandscapeProvider>
        {filterNavItems(globalNavigation)?.map((item, index) => {
          const { id } = item?.props ?? {};
          const anchorId = `nav-anchor-${id ?? String(index)}-level-1`;

          return (
            <MenuTrigger
              data={item}
              key={anchorId}
              anchorId={anchorId}
              className={classes.menuTrigger}
              isOpen={activeNavMenu === anchorId}
              setActiveNavMenu={setActiveNavMenu}
            />
          );
        })}
      </NavMenuLandscapeProvider>
    </StyledGlobalNavigationLandscape>
  );
};
