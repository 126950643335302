import React from "react";

import { cx } from "@emotion/css";
import { Drawer, styled } from "@mui/material";

import { Image } from "~/bff/types/Image";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { HeaderToolbar } from "../HeaderToolbar";

const PREFIX = "StyledHeaderSlideMenu";
const classes = {
  drawer: `${PREFIX}-drawer`,
};
const StyledHeaderSlideMenu = styled(Drawer)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`& .${classes.drawer}`]: {
      width: "100%",
      [formatMedia.BREAKPOINT_TABLET]: {
        width: 400,
      },
    },
  };
});

export interface HeaderSideMenuProps extends React.PropsWithChildren {
  className?: string;
  logo?: Image;
  isOpen?: boolean;
  onClose?: () => void;
}

export const HeaderSideMenu = ({
  className,
  logo,
  isOpen,
  onClose,
  children,
}: HeaderSideMenuProps) => {
  return (
    <StyledHeaderSlideMenu
      anchor="left"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: cx(classes.drawer, className),
        ...getTestAutomationProps("header-side-menu"),
      }}
    >
      <HeaderToolbar
        logo={logo}
        isMenuOpen={isOpen}
        onMenuOpen={onClose}
        isInSideMenu={true}
      />
      {children}
    </StyledHeaderSlideMenu>
  );
};
