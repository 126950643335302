import { NotFound } from "~/bff/types/NotFound";
import { NotFoundChildren } from "~/bff/types/NotFoundChildren";
import { ImageProps } from "~/components/Image";
import { Nullable } from "~/types/utility.types";
import { getImageSrc } from "~/utils/image";

export interface Page404Props {
  image: ImageProps<string>;
  title: string;
  text: string;
  buttonLabel: string;
}

export const convertNotFoundToProps = (
  data: Nullable<NotFound>
): Nullable<Page404Props> => {
  if (!data) {
    return null;
  }

  try {
    const { image, title, text } = data.children?.[0]?.props ?? {};
    const { label } =
      (data.children?.[0] as NotFoundChildren)?.children?.[0]?.props ?? {};

    return {
      title: title ?? "",
      text: text ?? "",
      buttonLabel: label ?? "",
      image: {
        alt: image?.alt ?? "",
        title: image?.title ?? image?.alt ?? "",
        src: getImageSrc(image?.source ?? {}),
      },
    };
  } catch (e) {
    return null;
  }
};
