import { useEffect } from "react";

export const useWindowEvent = (
  event: string,
  handler: (event: unknown) => void,
  passive = false
): void => {
  useEffect(() => {
    window.addEventListener(event, handler, passive);

    return () => window.removeEventListener(event, handler);
  });
};
