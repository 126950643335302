import { useCallback, useEffect, useState } from "react";

import SwiperCore from "swiper";

import { useWindowState } from "~/hooks/useWindowState";
import { Optional } from "~/types/utility.types";

export const SWIPE_ANIMATION_SPEED = 500;

interface ReturnType {
  setSwiper: (swiper?: SwiperCore) => void;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  windowWidth: number;
  handleSlideChange: (swiper: SwiperCore) => void;
  handleNextClick: () => void;
  handlePrevClick: () => void;
  swiper?: SwiperCore;
}

export const useSwiper = (
  slideStep: number,
  initialIsNextEnabled = false,
  initialIsPrevEnabled = false
): ReturnType => {
  const [swiper, setSwiper] = useState<Optional<SwiperCore>>();
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(
    () => !!initialIsNextEnabled
  );
  const [isPrevEnabled, setIsPrevEnabled] = useState<boolean>(
    () => !!initialIsPrevEnabled
  );
  const { width: windowWidth } = useWindowState();

  useEffect(() => {
    if (swiper) {
      setIsNextEnabled(!swiper.isEnd);
      setIsPrevEnabled(!swiper.isBeginning);
    }
  }, [swiper]);

  const handleSlideChange = useCallback((swiper: SwiperCore) => {
    setIsNextEnabled(!swiper.isEnd);
    setIsPrevEnabled(!swiper.isBeginning);
  }, []);

  const handleNextClick = useCallback(() => {
    for (let index = 0; index < slideStep; index++) {
      swiper?.slideNext(SWIPE_ANIMATION_SPEED);
    }
  }, [swiper, slideStep]);

  const handlePrevClick = useCallback(() => {
    for (let index = 0; index < slideStep; index++) {
      swiper?.slidePrev(SWIPE_ANIMATION_SPEED);
    }
  }, [swiper, slideStep]);

  return {
    setSwiper,
    isNextEnabled,
    isPrevEnabled,
    windowWidth,
    handleSlideChange,
    handleNextClick,
    handlePrevClick,
    swiper,
  };
};
