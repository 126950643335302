import { PARAMS } from "~/constants/request";

export interface ShallowUpdatePageNumberOnUrlProps {
  pageNumber: number;
  searchParams: URLSearchParams;
  pathname: string;
}

export const updatePageNumberOnUrl = ({
  pageNumber,
  searchParams,
  pathname,
}: ShallowUpdatePageNumberOnUrlProps): void => {
  const queryWithPageParam = new URLSearchParams(Array.from(searchParams.entries()));
  queryWithPageParam.set(PARAMS.PAGE, String(pageNumber));

  const queryWithoutPageParam = new URLSearchParams(
    Array.from(searchParams.entries())
  );
  queryWithoutPageParam.delete(PARAMS.PAGE);

  const shouldHide = pageNumber === 1;

  const params = shouldHide ? queryWithoutPageParam : queryWithPageParam;

  // Using window.history.pushState instead of router.push (https://github.com/vercel/next.js/discussions/48110)
  window.history.pushState({ as: pathname }, "", `?${params.toString()}`);
};
