import React from "react";

import { Box, styled } from "@mui/material";

import { VideoProps } from "~/bff/components/Video";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";
import { getVideoSrc } from "~/utils/video";

const VideoElement = styled("video")(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    width: "100%",
    objectFit: "contain",
    aspectRatio: "16/9",
    [formatMedia.BREAKPOINT_DESKTOP]: { width: "60%" },
  };
});

export const Video = ({
  video,
  title,
  previewImage,
  testAutomationId,
}: VideoProps) => {
  if (!video) {
    return null;
  }

  return (
    <Box
      {...getTestAutomationProps(testAutomationId ?? "content-video")}
      sx={{
        paddingX: { xs: 2, sm: 4 },
        display: "grid",
        placeItems: "center",
      }}
    >
      <VideoElement
        controls
        poster={getImageSrc(previewImage ?? undefined)}
        src={getVideoSrc(video)}
        title={prepareTextAttribute(title)}
      />
    </Box>
  );
};
