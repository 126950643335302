import type { ArticlePageProps } from "~/bff/components/ArticlePage";
import { Image } from "~/bff/types/Image";

interface ParseCardDataReturn {
  image: Image;
  title: string;
  description: string;
}

export const parseCardData = ({
  image,
  title,
  description,
  linkedComponents,
}: Partial<ArticlePageProps>): ParseCardDataReturn => {
  return {
    image: ((linkedComponents?.image?.props ?? image?.props) as Image) ?? {},
    title: (linkedComponents?.title ?? title ?? "") as string,
    description: (linkedComponents?.description ?? description ?? "") as string,
  };
};
