import React, { useMemo, MouseEvent, useCallback } from "react";

import { Button, ButtonProps, LinkProps } from "@mui/material";
import NextLink from "next/link";
import { useParams } from "next/navigation";

import { useSaveScrollPosition } from "~/hooks/useSaveScrollPosition";
import { getLinkUrl, getPageLocale } from "~/utils/locales";
import { prepareLinkProps } from "~/utils/prepareLinkProps";
import { getTestAutomationProps } from "~/utils/testUtils";

export interface ExtendedButtonProps extends ButtonProps {
  target?: string;
}

export const CombinedButton = React.forwardRef<
  HTMLButtonElement,
  ExtendedButtonProps
>(({ children, ...props }: ExtendedButtonProps, ref) => {
  const { locale } = useParams();
  const saveScrollPos = useSaveScrollPosition();
  const { href, className, onClick } = props;

  const linkLocale = useMemo(
    () => getPageLocale(locale as string).toLowerCase(),
    [locale]
  );

  const { props: buttonProps, children: buttonChildren } = useMemo(
    () => prepareLinkProps(props as unknown as LinkProps, children),
    [props, children]
  );

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (buttonProps?.target !== "_blank") {
        saveScrollPos();
      }
    },
    [onClick, buttonProps?.target, saveScrollPos]
  );

  const buttonComponent = useMemo(() => {
    return (
      <Button
        ref={ref}
        className={className}
        onClick={handleOnClick}
        {...getTestAutomationProps("button")}
        {...(buttonProps as unknown as ExtendedButtonProps)}
        suppressHydrationWarning={true}
      >
        {buttonChildren}
      </Button>
    );
  }, [buttonChildren, buttonProps, className, handleOnClick, ref]);

  return href ? (
    <NextLink
      passHref
      locale={false}
      prefetch={false}
      href={getLinkUrl(linkLocale, href)}
      legacyBehavior
    >
      {buttonComponent}
    </NextLink>
  ) : (
    buttonComponent
  );
});

CombinedButton.displayName = "CombinedButton";
