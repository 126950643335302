import React from "react";

import { Grid, Box, styled } from "@mui/material";
import Link from "next/link";

import { Image } from "~/components/Image";
import { COLOR_PRIMARY_BLACK } from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { SocialLinks } from "../../Footer.types";

interface SocialLinksProps {
  socialLinks: SocialLinks;
}

const PREFIX = "StyledSocialNetworkLinks";
const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
  iconItem: `${PREFIX}-iconItem`,
};

const StyledSocialNetworkLinks = styled(Grid)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(4),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(8),
      },
    },
    [`& .${classes.link}`]: {
      width: 24,
      height: 24,
      display: "block",
      color: COLOR_PRIMARY_BLACK,

      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: 28,
        height: 28,
      },
    },
    [`& .${classes.iconItem}`]: {
      width: "100%",
    },
  };
});

export const SocialNetworkLinks = ({ socialLinks }: SocialLinksProps) => {
  return (
    <StyledSocialNetworkLinks
      item
      xs={10}
      sm={6}
      md={5}
      lg={4}
      className={classes.root}
      {...getTestAutomationProps("social-links-group")}
    >
      {socialLinks.map((link, index) => (
        <Box key={index} {...getTestAutomationProps("social-link")}>
          <Link
            href={link?.url ?? ""}
            passHref
            className={classes.link}
            target="_blank"
            rel="noreferrer"
            title={link?.label ?? ""}
          >
            <Image
              className={classes.iconItem}
              src={getImageSrc(link?.source ?? undefined)}
              alt={prepareTextAttribute(link?.alt, true)}
              title={prepareTextAttribute(link?.title)}
            />
          </Link>
        </Box>
      ))}
    </StyledSocialNetworkLinks>
  );
};
