import React from "react";

import { cx } from "@emotion/css";
import { Box, useTheme, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { useSwiper } from "../../hooks/useSwiper";

import { CAROUSEL_SLIDE_STEP } from "./Carousel.constants";
import { CarouselLayoutProps } from "./Carousel.types";
import { CarouselHeader, CarouselHeaderProps } from "./CarouselHeader";
import { CarouselNavButtons } from "./CarouselNavButtons";

import "swiper/swiper.min.css";

const PREFIX = "StyledCarousel";
const classes = {
  root: `${PREFIX}-root`,
  swiper: `${PREFIX}-swiper`,
  slider: `${PREFIX}-slider`,
  container: `${PREFIX}-container`,
  children: `${PREFIX}-children`,
  child: `${PREFIX}-child`,
};

const StyledCarousel = styled(Box, {
  shouldForwardProp: (props) => props !== "layout",
})<CarouselLayoutProps>(({ theme, layout }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      position: "relative",
      marginBottom: theme.spacing(7),

      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(9),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(16),
      },
    },

    [`& .${classes.swiper}`]: {
      display: "flex",
      alignItems: "flex-end",
    },
    [`& .${classes.slider}`]: {
      height: "auto",
      width: "calc(100% / 2)",

      [formatMedia.BREAKPOINT_TABLET]: {
        width: "calc(100% / 3)",
      },
    },
    [`& .${classes.container}`]: {
      overflow: "hidden",
      flexGrow: 1,
    },
    [`& .${classes.children}`]: {
      width: "160%",
      transform: layout === "left" ? "translate(-38%, 0)" : "none",

      "& .swiper-wrapper": {
        alignItems: "stretch",
        height: "auto !important",
      },

      [formatMedia.BREAKPOINT_TABLET]: {
        width: "120%",
        transform: layout === "left" ? "translate(-18%, 0)" : "none",
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        width: "120%",
        transform: layout === "left" ? "translate(-18%, 0)" : "none",
      },
    },
    [`& .${classes.child}`]: {
      height: "100%",
      padding: theme.spacing(0, 2),
      marginLeft: layout === "right" ? theme.spacing(-2) : "unset",
      marginRight: layout === "left" ? theme.spacing(-2) : "unset",

      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(0, 3),
        marginLeft: layout === "right" ? theme.spacing(-3) : "unset",
        marginRight: layout === "left" ? theme.spacing(-3) : "unset",
      },
    },
  };
});

export interface CarouselProps extends CarouselHeaderProps {
  className?: string;
  children: React.ReactNode;
  testAutomationId?: string;
}

export const Carousel = ({
  link,
  title,
  subtitle,
  children,
  className,
  layout = "right",
  testAutomationId = "carousel",
}: CarouselProps) => {
  const theme = useTheme();

  const {
    setSwiper,
    windowWidth,
    isNextEnabled,
    isPrevEnabled,
    handleNextClick,
    handlePrevClick,
    handleSlideChange,
  } = useSwiper(CAROUSEL_SLIDE_STEP, true);

  return (
    <StyledCarousel
      layout={layout}
      className={cx(classes.root, className)}
      {...getTestAutomationProps(testAutomationId)}
    >
      <CarouselHeader
        link={link}
        title={title}
        subtitle={subtitle}
        layout={layout}
      />
      <Box className={classes.swiper}>
        {layout === "right" && (
          <CarouselNavButtons
            layout={layout}
            leftButtonProps={{
              disabled: !isPrevEnabled,
              onClick: handlePrevClick,
            }}
            rightButtonProps={{
              disabled: !isNextEnabled,
              onClick: handleNextClick,
            }}
          />
        )}
        <Box className={classes.container}>
          <Box
            className={classes.children}
            {...getTestAutomationProps("carousel-children")}
          >
            <Swiper
              spaceBetween={0}
              slidesPerView={2}
              key={windowWidth}
              onSwiper={setSwiper}
              onSlideChange={handleSlideChange}
              initialSlide={layout === "left" ? React.Children.count(children) : 0}
              breakpoints={{
                [theme.breakpoints.values.sm]: {
                  slidesPerView: 3,
                },
              }}
            >
              {layout === "left" && (
                <SwiperSlide
                  className={classes.slider}
                  {...getTestAutomationProps("carousel-child")}
                />
              )}
              {React.Children.map(children, (child) => (
                <SwiperSlide className={classes.slider}>
                  <Box
                    className={classes.child}
                    {...getTestAutomationProps("carousel-child")}
                  >
                    {child}
                  </Box>
                </SwiperSlide>
              ))}
              {layout === "right" && (
                <SwiperSlide
                  className={classes.slider}
                  {...getTestAutomationProps("carousel-child")}
                />
              )}
            </Swiper>
          </Box>
        </Box>
        {layout === "left" && (
          <CarouselNavButtons
            layout={layout}
            leftButtonProps={{
              disabled: !isPrevEnabled,
              onClick: handlePrevClick,
            }}
            rightButtonProps={{
              disabled: !isNextEnabled,
              onClick: handleNextClick,
            }}
          />
        )}
      </Box>
    </StyledCarousel>
  );
};
