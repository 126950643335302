import React from "react";

import { Box, styled } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";

import { RichTextBlockProps } from "~/bff/components/RichTextBlock";
import { Markdown } from "~/components/Markdown";
import {
  COLOR_BLACK,
  COLOR_PRIMARK_JOURNEY_BLUE,
  COLOR_WIDE_TABLE_BACKGROUND_EVEN,
  COLOR_WIDE_TABLE_BACKGROUND_ODD,
  COLOR_WIDE_TABLE_BORDER,
} from "~/theme/constants/colors";
import { FontWeights, TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

export interface RichTextProps {
  heading: Nullable<string>;
  text: Nullable<string>;
}

const PREFIX = "StyledRichTextBlock";
export const RICH_TEXT_BLOCK_CLASSES = {
  richText: `${PREFIX}-richText`,
};

const StyledRichTextBlock = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    padding: "0 !important",
    [`& .${RICH_TEXT_BLOCK_CLASSES.richText}`]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(0),
      marginLeft: "auto",
      marginRight: "auto",
      marginInline: "auto",
      overflowWrap: "break-word",

      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        maxWidth: "644px",
      },

      "& h2": {
        ...theme.typography.h4,
        marginBottom: theme.spacing(3),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h3,
          marginBottom: theme.spacing(4),
        },
      },
      "& h3": {
        marginBottom: theme.spacing(3),
        ...theme.typography.h5,
        [formatMedia.BREAKPOINT_TABLET]: {
          marginBottom: theme.spacing(4),
          ...theme.typography.h4,
        },
      },
      "& h4": {
        ...theme.typography.h6,
        marginBottom: theme.spacing(3),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h5,
          marginBottom: theme.spacing(4),
        },
      },
      "& p:not(table *), & ul:not(table *), & ol:not(table *)": {
        margin: 0,
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL}`
        ] as CSSProperties),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE}`
          ] as CSSProperties),
        },
      },
      "& p:not(table *)": {
        marginBottom: theme.spacing(4),
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
      },
      "& strong": {
        display: "inline-block",
      },
      "& a:not(table *)": {
        display: "inline-block",
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL_LINK}`
        ] as CSSProperties),
        color: "inherit",
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE_LINK}`
          ] as CSSProperties),
        },
      },
      "& ul:not(table *)": {
        listStyle: "none",
      },
      "& ul:not(table *), & ol:not(table *)": {
        paddingLeft: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: 13,
        marginTop: 15,
        marginBottom: theme.spacing(4),
        [formatMedia.BREAKPOINT_TABLET]: {
          gap: 15,
        },
      },
      "& ul:not(table *) li": {
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          width: 8,
          height: 8,
          display: "block",
          border: "2px solid #000",
          borderRadius: "50%",
          top: 9,
          left: -24,
          [formatMedia.BREAKPOINT_TABLET]: {
            top: 12,
          },
        },
      },
      "& > :last-child": {
        marginBottom: 0,
      },
      "& table": {
        border: `3px solid ${COLOR_WIDE_TABLE_BORDER}`,
        color: COLOR_BLACK,
        borderCollapse: "collapse",
        width: `calc(100vw - ${theme.spacing(4)})`,
        position: "relative",
        left: `calc(-50vw + 50% + ${theme.spacing(2)})`,
        tableLayout: "fixed",

        "& ul, & ol": {
          paddingLeft: theme.spacing(3),
        },

        "& a": {
          display: "inline-block",
          color: COLOR_PRIMARK_JOURNEY_BLUE,
          fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
        },

        [formatMedia.BREAKPOINT_TABLET]: {
          width: `calc(100vw - ${theme.spacing(8)})`,
          position: "relative",
          left: `calc(-50vw + 50% + ${theme.spacing(4)})`,
          tableLayout: "auto",

          "& ul, & ol": {
            paddingLeft: theme.spacing(5),
          },
        },
        [formatMedia.BREAKPOINT_DESKTOP]: {
          width: `calc(100vw - ${theme.spacing(16)})`,
          position: "relative",
          left: `calc(-50vw + 50% + ${theme.spacing(8)})`,

          tableLayout: "auto",

          "& ul, & ol": {
            paddingLeft: theme.spacing(5),
          },
        },

        "& thead tr": {
          borderBottom: `3px solid ${COLOR_WIDE_TABLE_BORDER}`,
          textAlign: "center",
        },

        "& tbody": {
          "& tr": {
            backgroundColor: COLOR_WIDE_TABLE_BACKGROUND_ODD,
          },
          "& tr:nth-of-type(even)": {
            backgroundColor: COLOR_WIDE_TABLE_BACKGROUND_EVEN,
          },
        },

        "& th, & td": {
          padding: `0 ${theme.spacing(0.5)}`,
          borderLeft: `1px solid ${COLOR_WIDE_TABLE_BORDER}`,
        },
      },
    },
  };
});

export const RichTextBlock = ({ heading, text }: RichTextBlockProps) => {
  return (
    <StyledRichTextBlock {...getTestAutomationProps("rich-text-block")}>
      {heading && (
        <Markdown className={RICH_TEXT_BLOCK_CLASSES.richText} text={heading} />
      )}
      {text && <Markdown className={RICH_TEXT_BLOCK_CLASSES.richText} text={text} />}
    </StyledRichTextBlock>
  );
};
