import React, { useMemo, useCallback } from "react";

import { Box, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useParams } from "next/navigation";

import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { ImageSource } from "~/bff/types/ImageSource";
import { Author as AuthorComponent } from "~/components/Author";
import { CombinedLink } from "~/components/CombinedLink";
import { Image } from "~/components/Image";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_GREYSCALE_BLACK_54,
} from "~/theme/constants/colors";
import { DEFAULT_FONT_FAMILY, FontWeights } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { getLinkUrl } from "~/utils/locales";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { parseCardData } from "../../helpers/parse-card-data";

import { RESPONSIVE_IMAGE_CONFIG } from "./ArticleCard.constants";

const PREFIX = "StyledArticleCard";
export const ARTICLE_CARD_CLASSES = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  body: `${PREFIX}-body`,
  content: `${PREFIX}-content`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  author: `${PREFIX}-author`,
  footer: `${PREFIX}-footer`,
};

const StyledArticleCard = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${ARTICLE_CARD_CLASSES.root}`]: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    [`& .${ARTICLE_CARD_CLASSES.image}`]: {
      width: `calc(100% + ${theme.spacing(0.5)})`,
      maxWidth: `calc(100% + ${theme.spacing(0.5)})`,
      marginLeft: theme.spacing(-0.25),
      aspectRatio: "1/1",
      objectFit: "cover",
      display: "block",
    },
    [`& .${ARTICLE_CARD_CLASSES.content}`]: {
      paddingLeft: theme.spacing(1.75),
      paddingRight: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingTop: theme.spacing(2),
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2.75),
        paddingBottom: theme.spacing(2.75),
        paddingRight: theme.spacing(2.75),
      },
    },
    [`& .${ARTICLE_CARD_CLASSES.body}`]: {
      border: `2px solid ${COLOR_ADDITIONAL_WARM_GRAY_3U}`,
      borderTop: "0px",
      flexGrow: 1,
    },
    [`& .${ARTICLE_CARD_CLASSES.subtitle}`]: {
      display: "block",
      textAlign: "start",
      fontFamily: DEFAULT_FONT_FAMILY,
      fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
    },
    [`& .${ARTICLE_CARD_CLASSES.title}`]: {
      textTransform: "capitalize",
      marginTop: theme.spacing(1),

      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(2),
      },
    },
    [`& .${ARTICLE_CARD_CLASSES.description}`]: {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      marginTop: theme.spacing(1),
      display: "-webkit-box !important",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "3",

      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(2),
      },
    },
    [`& .${ARTICLE_CARD_CLASSES.author}`]: {
      marginTop: theme.spacing(1),

      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(2),
      },
    },
    [`& .${ARTICLE_CARD_CLASSES.footer}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),

      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(3),
      },
    },
  };
});

export interface ArticleCardExtendedProps extends ArticlePageProps {
  mode?: "compact" | "full";
  onClick?: (deliveryId: string) => void;
  restorationRef?: React.RefObject<HTMLDivElement>;
}

export const ArticleCard = (props: ArticleCardExtendedProps) => {
  const {
    meta,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dateDisplay,
    author,
    subtitle,
    publishDate,
    readingTime,
    mode = "compact",
    restorationRef,
    onClick,
  } = props;

  const params = useParams();
  const { title, image, description } = parseCardData(props);
  const { source, alt, title: imageTitle, ...imageProps } = image;

  const articleLink = meta?.deliveryKey
    ? getLinkUrl(params?.locale as string, meta.deliveryKey as string)
    : undefined;

  const footerText = useMemo(() => {
    let result = "";

    if (dateDisplay && publishDate) {
      result += dayjs(publishDate).format("MMM D, YYYY");
    }

    if (dateDisplay && publishDate && readingTime) {
      result += " · ";
    }

    if (readingTime) {
      result += `${readingTime} min read`;
    }

    return result;
  }, [dateDisplay, publishDate, readingTime]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(meta?.deliveryId as string);
    }
  }, [onClick, meta?.deliveryId]);

  return (
    <StyledArticleCard
      className={ARTICLE_CARD_CLASSES.root}
      ref={restorationRef}
      {...getTestAutomationProps("article-card")}
    >
      <Box className={ARTICLE_CARD_CLASSES.body}>
        <CombinedLink
          underline="none"
          href={articleLink}
          onClick={handleOnClick}
          {...getTestAutomationProps("article-card-link")}
        >
          <Image
            loading="lazy"
            className={ARTICLE_CARD_CLASSES.image}
            alt={prepareTextAttribute(alt, true)}
            title={prepareTextAttribute(imageTitle)}
            src={getImageSrc(source as ImageSource)}
            responsiveImageConfig={source ? RESPONSIVE_IMAGE_CONFIG : undefined}
            {...prepareImageA11yProps(imageProps)}
            {...getTestAutomationProps("article-card-image")}
          />
          <Box className={ARTICLE_CARD_CLASSES.content}>
            {subtitle && (
              <Typography
                variant="button"
                className={ARTICLE_CARD_CLASSES.subtitle}
                {...getTestAutomationProps("article-card-subtitle")}
              >
                {subtitle}
              </Typography>
            )}
            <Typography
              variant="h4"
              className={ARTICLE_CARD_CLASSES.title}
              {...getTestAutomationProps("article-card-title")}
            >
              {title}
            </Typography>
            {description && (
              <Typography
                variant="body1"
                className={ARTICLE_CARD_CLASSES.description}
                {...getTestAutomationProps("article-card-description")}
              >
                {description}
              </Typography>
            )}
          </Box>
        </CombinedLink>
        {mode === "full" && (
          <Box className={ARTICLE_CARD_CLASSES.content}>
            {author ? (
              <Box className={ARTICLE_CARD_CLASSES.author}>
                <AuthorComponent {...author.props} onLinkClick={handleOnClick} />
              </Box>
            ) : null}
            {footerText && (
              <Box className={ARTICLE_CARD_CLASSES.footer}>
                <Typography
                  variant="body2"
                  color={COLOR_GREYSCALE_BLACK_54}
                  {...getTestAutomationProps("article-card-footer-text")}
                >
                  {footerText}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </StyledArticleCard>
  );
};
