import React, { useCallback, useState } from "react";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Slide,
  styled,
  Typography,
} from "@mui/material";
import { createPortal } from "react-dom";

import { CombinedButton } from "~/components/CombinedButton";
import { CombinedLink } from "~/components/CombinedLink";
import { NavItemList } from "~/components/GlobalNavigation/GlobalNavigation.types";
import { filterNavItems } from "~/components/GlobalNavigation/helper/filterNavItems";
import { getNavUrl } from "~/components/GlobalNavigation/helper/getNavUrl";
import { HEADER_HEIGHT_PORTRAIT } from "~/components/Header/Header.constants";
import { useHeaderContext } from "~/components/Header/Header.hooks";
import { COLOR_WHITE } from "~/theme/constants/colors";
import { TypographyButtonClass } from "~/theme/constants/typography";
import { Back24, Right24 } from "~/theme/icons";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledNavMenuPortrait";
const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-backButton`,
  navMenuHeader: `${PREFIX}-navMenuHeader`,
  navMenuList: `${PREFIX}-navMenuList`,
  subNavItem: `${PREFIX}-subNavItem`,
};

const StyledNavMenuPortrait = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    zIndex: 1300,
    display: "flex",
    position: "absolute",
    alignItems: "stretch",
    flexDirection: "column",
    background: COLOR_WHITE,
    top: `${HEADER_HEIGHT_PORTRAIT}px`,
    bottom: 0,
    right: 0,
    left: 0,
  },
  [`& .${classes.navMenuHeader}`]: {
    display: "flex",
    minHeight: "48px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    margin: `${theme.spacing(1)} 0`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(9)}`,
  },
  [`& .${classes.backButton}`]: {
    position: "absolute",
    left: theme.spacing(2),
    top: theme.spacing(0.5),
  },
  [`& .${classes.subNavItem}`]: {
    height: "auto",
    display: "flex",
    minHeight: "48px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    columnGap: theme.spacing(2),
  },
  [`& .${classes.navMenuList}`]: {
    display: "flex",
    overflow: "auto",
    alignItems: "stretch",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
  },
}));

interface NavMenuPortraitProps {
  title: string;
  level?: number;
  isOpen: boolean;
  itemList: NavItemList;
  anchorEl: HTMLDivElement;
  titleUrl?: Nullable<string>;
  onMenuClose: () => void;
}

export const NavMenuPortrait = ({
  title,
  isOpen,
  titleUrl,
  anchorEl,
  itemList,
  level = 1,
  onMenuClose,
}: NavMenuPortraitProps) => {
  const [activeSubNavMenu, setActiveSubNavMenu] = useState<Nullable<string>>(null);

  const { handleMenuClose } = useHeaderContext();

  const handleSubMenuOpen = useCallback(
    (id: string) => () => {
      setActiveSubNavMenu(id);
    },
    []
  );

  const handleSubMenuClose = useCallback(() => {
    setActiveSubNavMenu(null);
  }, []);

  return createPortal(
    <Slide in={isOpen} direction="right" mountOnEnter unmountOnExit>
      <StyledNavMenuPortrait
        className={classes.root}
        {...getTestAutomationProps(`nav-menu-portrait-level-${level}`)}
      >
        <Divider />
        <Box className={classes.navMenuHeader}>
          <IconButton
            onClick={onMenuClose}
            className={classes.backButton}
            {...getTestAutomationProps("nav-menu-back-btn")}
          >
            <Back24 />
          </IconButton>
          {titleUrl ? (
            <CombinedLink
              variant="button"
              underline="none"
              onClick={handleMenuClose}
              href={titleUrl}
              className={TypographyButtonClass.DESKTOP_BOLD}
              {...getTestAutomationProps("nav-menu-title")}
            >
              {title}
            </CombinedLink>
          ) : (
            <Typography
              variant="button"
              className={TypographyButtonClass.DESKTOP_BOLD}
              {...getTestAutomationProps("nav-menu-title")}
            >
              {title}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box
          className={classes.navMenuList}
          {...getTestAutomationProps("nav-menu-item-list")}
        >
          {filterNavItems(itemList)?.map((item, index) => {
            const { id, title } = item?.props ?? {};
            const navUrl = getNavUrl(item?.props);
            const itemId = id ?? String(index);
            const isSubMenuOpen = activeSubNavMenu === itemId;
            const hasSubMenu = Boolean(filterNavItems(item?.children)?.length);

            if (!hasSubMenu) {
              return (
                <CombinedButton
                  key={itemId}
                  onClick={navUrl ? handleMenuClose : undefined}
                  href={navUrl ?? undefined}
                  className={classes.subNavItem}
                >
                  {title}
                </CombinedButton>
              );
            }

            return (
              <React.Fragment key={itemId}>
                <Button
                  variant="text"
                  className={classes.subNavItem}
                  onClick={handleSubMenuOpen(itemId)}
                >
                  {title ?? ""}
                  <Right24 />
                </Button>
                <NavMenuPortrait
                  level={level + 1}
                  anchorEl={anchorEl}
                  isOpen={isSubMenuOpen}
                  onMenuClose={handleSubMenuClose}
                  itemList={item?.children ?? []}
                  title={title ?? ""}
                  titleUrl={navUrl}
                />
              </React.Fragment>
            );
          })}
        </Box>
      </StyledNavMenuPortrait>
    </Slide>,
    anchorEl
  );
};
