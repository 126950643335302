import React from "react";

import { styled } from "@mui/material";

import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { ArticlesCarouselProps } from "~/bff/components/ArticlesCarousel";

import { ARTICLE_CARD_CLASSES } from "../ArticleCards/components/ArticleCard";
import { Carousel } from "../Carousel";

const StyledArticlesCarousel = styled(Carousel)(({ theme }) => ({
  [`& div[data-testautomation-id="article-card"] .${ARTICLE_CARD_CLASSES.content}`]:
    {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2.75),
      paddingLeft: theme.spacing(2.75),
      paddingRight: theme.spacing(2.75),
    },
  [`& .${ARTICLE_CARD_CLASSES.title}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${ARTICLE_CARD_CLASSES.description}`]: {
    marginTop: theme.spacing(2),
  },
}));

export const ArticlesCarousel = ({
  link,
  title,
  subtitle,
  children,
  testAutomationId,
}: ArticlesCarouselProps) => {
  return (
    <StyledArticlesCarousel
      link={link}
      title={title}
      subtitle={subtitle}
      testAutomationId={testAutomationId ?? "articles-carousel"}
    >
      {children as unknown as React.ReactElement<ArticlePageProps>}
    </StyledArticlesCarousel>
  );
};
