import React from "react";

import { Typography, styled, Box } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

import { Image as ImageType } from "~/bff/types/Image";
import { ImageSource } from "~/bff/types/ImageSource";
import { Image } from "~/components/Image";
import { PageTitle } from "~/components/PageTitle";
import { COLOR_PRIMARY_WHITE } from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

interface GenericHeaderProps {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: ImageType;
}

const PREFIX = "StyledHeader";
const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  headerTexts: `${PREFIX}-headerTexts`,
  subTitle: `${PREFIX}-subTitle`,
  description: `${PREFIX}-description`,
};

const StyledHeader = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    [`& .${classes.image}`]: {
      width: "100%",
      objectFit: "cover",
      height: 257,
      [formatMedia.BREAKPOINT_TABLET]: {
        height: 550,
      },
    },
    [`& .${classes.headerTexts}`]: {
      "& p": {
        textAlign: "left",
      },
      paddingBlock: theme.spacing(3),
      backgroundColor: COLOR_PRIMARY_WHITE,
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingBlock: theme.spacing(4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(6),
        position: "absolute",
        top: theme.spacing(6),
        width: 533,
      },
    },
    [`& .${classes.subTitle}`]: {
      marginBottom: theme.spacing(2),
      fontSize: 16,
      [formatMedia.BREAKPOINT_TABLET]: {
        fontSize: 18,
      },
    },
    [`& .${classes.description}`]: {
      ...theme.typography.body1,
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_SMALL}`
      ] as CSSProperties),
      marginTop: theme.spacing(2),
      [formatMedia.BREAKPOINT_TABLET]: {
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_LARGE}`
        ] as CSSProperties),
      },
    },
  };
});

export const Header = (props: GenericHeaderProps) => {
  const { image, title, description, subtitle } = props;
  return (
    <StyledHeader
      className={classes.root}
      {...getTestAutomationProps("media-library-heading")}
    >
      <Image
        className={classes.image}
        src={getImageSrc(image?.source as ImageSource)}
        alt={prepareTextAttribute(image?.alt, true)}
        {...prepareImageA11yProps(image)}
      />
      <Box className={classes.headerTexts}>
        {subtitle && (
          <Typography
            className={classes.subTitle}
            component="p"
            variant="subtitle1"
            {...getTestAutomationProps("sub-title")}
          >
            {subtitle}
          </Typography>
        )}
        {title && (
          <PageTitle {...getTestAutomationProps("title")}>{title}</PageTitle>
        )}
        {description && (
          <Typography
            component="p"
            className={classes.description}
            {...getTestAutomationProps("description")}
          >
            {description}
          </Typography>
        )}
      </Box>
    </StyledHeader>
  );
};
