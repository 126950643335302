import React from "react";

import { Box, Typography, styled } from "@mui/material";
import { useParams } from "next/navigation";

import { BannerProps } from "~/bff/components/Banner";
import { ImageSource } from "~/bff/types/ImageSource";
import { CombinedLink } from "~/components/CombinedLink";
import { CTALinkButton } from "~/components/CTALinkButton";
import { Image } from "~/components/Image";
import {
  COLOR_GREYSCALE_BLACK_5,
  COLOR_PRIMARY_WHITE,
  COLOR_WHITE,
} from "~/theme/constants/colors";
import { TextColor } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { getLinkUrl } from "~/utils/locales";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { RESPONSIVE_IMAGE_CONFIG } from "./Banner.constants";

const PREFIX = "StyledBanner";
const classes = {
  root: `${PREFIX}-root`,
  infoLink: `${PREFIX}-infoLink`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  detailLink: `${PREFIX}-detailLink`,
};

const StyledBanner = styled(CombinedLink, {
  shouldForwardProp: (prop) => prop !== "textColor",
})<BannerProps>(({ theme, textColor }) => {
  const formatMedia = getFormatMedia(theme);
  const isWhite = textColor === "light";

  return {
    [`&.${classes.root}`]: {
      position: "relative",
      backgroundSize: "cover",
      backgroundColor: isWhite ? COLOR_GREYSCALE_BLACK_5 : COLOR_WHITE,
      padding: theme.spacing(3),
      height: 500,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(7),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(7),
        height: 550,
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(8),
      },
    },
    [`& .${classes.infoLink}`]: {
      zIndex: 1,
    },
    [`& .${classes.imageWrapper}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [`& .${classes.image}`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& .${classes.title}`]: {
      ...theme.typography.h3,
      paddingBottom: theme.spacing(1),
      color: isWhite ? COLOR_PRIMARY_WHITE : "unset",
      zIndex: 1,
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(2),
        ...theme.typography.h1,
        textAlign: "center",
      },
    },
    [`& .${classes.description}`]: {
      paddingBottom: theme.spacing(2.5),
      color: isWhite ? COLOR_PRIMARY_WHITE : "unset",
      zIndex: 1,
      ...theme.typography.body1,
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(3.5),
        ...theme.typography.h6,
        textAlign: "center",
      },
    },
    [`& .${classes.detailLink} p`]: {
      fontSize: 18,
    },
    [`& .${classes.detailLink}`]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  };
});

export const Banner = ({
  title,
  text,
  link,
  image,
  textColor = "white",
  testAutomationId = "banner",
}: BannerProps) => {
  const params = useParams();

  return (
    <StyledBanner
      className={classes.root}
      textColor={textColor}
      href={getLinkUrl(params.locale as string, link?.url as string)}
      underline="none"
      {...getTestAutomationProps(testAutomationId as string)}
    >
      <Box className={classes.imageWrapper}>
        <Image
          className={classes.image}
          responsiveImageConfig={image?.source ? RESPONSIVE_IMAGE_CONFIG : undefined}
          src={getImageSrc(image?.source as ImageSource)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
          {...prepareImageA11yProps(image)}
        />
      </Box>
      <Box className={classes.infoLink}>
        <Typography
          className={classes.title}
          variant="h2"
          {...getTestAutomationProps("title")}
        >
          {title}
        </Typography>
        {text && (
          <Typography
            className={classes.description}
            align="center"
            component="p"
            variant="h6"
            {...getTestAutomationProps("description")}
          >
            {text}
          </Typography>
        )}
        <CTALinkButton
          className={classes.detailLink}
          textColor={textColor as TextColor}
          typographyProps={{ variant: "h3" }}
        >
          {link?.label}
        </CTALinkButton>
      </Box>
    </StyledBanner>
  );
};
