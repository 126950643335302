import React from "react";

import { Avatar, Box, styled } from "@mui/material";

import { AuthorProps } from "~/bff/components/Author";
import { COLOR_GREYSCALE_BLACK_75 } from "~/theme/constants/colors";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { CombinedLink } from "../CombinedLink";

const PREFIX = "StyledAuthor";
const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  info: `${PREFIX}-info`,
};

const StyledAuthor = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(2),
  },
  [`& .${classes.avatar}`]: {
    width: 48,
    height: 48,
  },
  [`& .${classes.info}`]: {
    fontStyle: "normal",
    color: COLOR_GREYSCALE_BLACK_75,
    ...theme.typography.body2,
  },
}));

export interface AuthorPropsExtendedProps extends AuthorProps {
  onLinkClick?: () => void;
}

export const Author = ({
  name,
  link,
  image,
  testAutomationId,
  onLinkClick,
}: AuthorPropsExtendedProps) => {
  return (
    <StyledAuthor
      className={classes.root}
      {...getTestAutomationProps(testAutomationId ?? "author")}
    >
      {Boolean(image?.source) && (
        <Avatar
          className={classes.avatar}
          title={prepareTextAttribute(image?.title)}
          alt={prepareTextAttribute(image?.alt, true)}
          src={getImageSrc(image?.source ?? undefined)}
          {...getTestAutomationProps("author-avatar")}
        />
      )}
      <address className={classes.info} {...getTestAutomationProps("author-name")}>
        by{" "}
        {link?.url ? (
          <CombinedLink
            href={link.url}
            underline="hover"
            target={link.url.startsWith("http") ? "_blank" : "_parent"}
            onClick={onLinkClick}
            {...getTestAutomationProps("author-link")}
          >
            {name}
          </CombinedLink>
        ) : (
          name
        )}
      </address>
    </StyledAuthor>
  );
};
