import React, { useMemo, useCallback, useRef, useState, useEffect } from "react";

import { Stack, useMediaQuery, useTheme, styled } from "@mui/material";
import { usePathname, useSearchParams } from "next/navigation";

import { ArticlePage } from "~/bff/types/ArticlePage";
import { ArticleCard } from "~/components/ArticleCards/components/ArticleCard";
import { ArticlePageHeading } from "~/components/ArticlePage/ArticlePageHeading";
import { DEFAULT_PAGE_SIZE } from "~/constants/common";
import { useBrowserData } from "~/hooks/useBrowserData";
import {
  KEY_PREFIX as ANCHORING_KEY_PREFIX,
  ScrollPosData,
} from "~/hooks/useSaveScrollPosition";
import { useSessionStorage } from "~/hooks/useSessionStorage";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { updatePageNumberOnUrl } from "~/utils/updatePageNumberOnUrl";

import {
  DESKTOP_TILE_PATTERN,
  computeTabletTileLayout,
  computeDesktopTileLayout,
} from "../../helper/computeTileLayout";
import { NEWSROOM_RESTORATION_ID } from "../../NewsRoomPage.constants";

const PREFIX = "StyledArticleTiles";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledArticleTiles = styled(Stack)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      marginTop: theme.spacing(3),
      rowGap: theme.spacing(7),

      ".StyledArticleCard-root": {
        height: "auto",
        width: "100%",

        [formatMedia.BREAKPOINT_TABLET]: {
          width: `calc(50% - ${theme.spacing(3)} / 2)`,
        },
        [formatMedia.BREAKPOINT_DESKTOP]: {
          width: `calc(100% / 3 - ${theme.spacing(3)} / 3)`,
        },
      },

      ".StyledArticlePageHeading-root": {
        padding: 0,
        margin: 0,
      },
    },
  };
});

interface ArticleTilesProps {
  articles: Nullable<ArticlePage>[];
  fullArticleList: Nullable<ArticlePage>[];
}

export const ArticleTiles = ({ articles, fullArticleList }: ArticleTilesProps) => {
  const theme = useTheme();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { removeItem, getItem, setItem } = useSessionStorage();
  const { isBrowserNavigation, setIsBrowserNavigation } = useBrowserData();
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  const isTabletView = useMediaQuery(BREAKPOINT_TABLET);
  const isDesktopView = useMediaQuery(BREAKPOINT_DESKTOP);
  const [markerId, setMarkerId] = useState(getItem(NEWSROOM_RESTORATION_ID));
  const restorationRef = useRef<HTMLDivElement>(null);
  const fullAnchorKey = useMemo(
    () => [ANCHORING_KEY_PREFIX, pathname].join(":"),
    [pathname]
  );

  useEffect(() => {
    if (!restorationRef.current || !isBrowserNavigation) {
      return;
    }
    removeItem(NEWSROOM_RESTORATION_ID);
    removeItem(fullAnchorKey);
    setMarkerId(null);
    restorationRef?.current?.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
    setIsBrowserNavigation(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restorationRef, removeItem, fullAnchorKey]);

  useEffect(() => {
    if (restorationRef.current || !isBrowserNavigation) {
      return;
    }
    const json = getItem(fullAnchorKey);
    const scrollPos: ScrollPosData = json ? JSON.parse(json) : undefined;

    if (scrollPos) {
      window.scrollTo(scrollPos.x, scrollPos.y);
      removeItem(fullAnchorKey);
      setIsBrowserNavigation(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArticleCardClick = useCallback(
    (deliveryId: string) => {
      const index = fullArticleList?.findIndex(
        (article: Nullable<ArticlePage>) => article?._meta?.deliveryId === deliveryId
      );
      if (index > -1) {
        const targetPage = Math.floor(index / DEFAULT_PAGE_SIZE) + 1;
        updatePageNumberOnUrl({
          pageNumber: targetPage,
          searchParams,
          pathname,
        });
        setItem(NEWSROOM_RESTORATION_ID, deliveryId);
      }
    },
    [setItem, fullArticleList, searchParams, pathname]
  );

  const preparedTiles = useMemo(() => {
    if (!articles?.length) {
      return null;
    }

    if (isDesktopView) {
      const articleGroups = computeDesktopTileLayout(articles);
      let isOddLargeTile = true;

      return articleGroups.map((group, index) => {
        const numberOfTiles = DESKTOP_TILE_PATTERN[index % 5];

        if (numberOfTiles === 1) {
          isOddLargeTile = !isOddLargeTile;
        }

        return numberOfTiles === 1 ? (
          <ArticlePageHeading
            key={`${index}-${group[0]?._meta?.deliveryId}`}
            layout={isOddLargeTile ? "left" : "right"}
            titleVariant="h2"
            href={group[0]?._meta?.deliveryKey}
            forceImageDisplay
            meta={group[0]?._meta}
            {...group[0]?.props}
            onClick={handleArticleCardClick}
            restorationRef={
              markerId === group[0]?._meta?.deliveryId ? restorationRef : undefined
            }
          />
        ) : (
          <Stack key={index} direction="row" alignItems="stretch" columnGap={3}>
            {group.map((article, articleIndex) => (
              <ArticleCard
                key={`${articleIndex}-${article?._meta?.deliveryId}`}
                meta={article?._meta}
                {...article?.props}
                mode="full"
                onClick={handleArticleCardClick}
                restorationRef={
                  markerId === article?._meta?.deliveryId
                    ? restorationRef
                    : undefined
                }
              />
            ))}
          </Stack>
        );
      });
    }

    if (isTabletView) {
      const articlePairs = computeTabletTileLayout(articles);

      return articlePairs.map(([first, second], index) => (
        <Stack
          columnGap={3}
          direction="row"
          alignItems="stretch"
          key={`${index}-${first?._meta?.deliveryId}-${second?._meta?.deliveryId}`}
        >
          {first && (
            <ArticleCard
              mode="full"
              meta={first._meta}
              {...first.props}
              onClick={handleArticleCardClick}
              restorationRef={
                markerId === first?._meta?.deliveryId ? restorationRef : undefined
              }
            />
          )}
          {second && (
            <ArticleCard
              mode="full"
              meta={second._meta}
              {...second.props}
              onClick={handleArticleCardClick}
              restorationRef={
                markerId === second?._meta?.deliveryId ? restorationRef : undefined
              }
            />
          )}
        </Stack>
      ));
    }

    return articles.map((article, index) => (
      <ArticleCard
        key={`${index}-${article?._meta?.deliveryId}`}
        meta={article?._meta}
        {...article?.props}
        mode="full"
        onClick={handleArticleCardClick}
        restorationRef={
          markerId === article?._meta?.deliveryId ? restorationRef : undefined
        }
      />
    ));
  }, [articles, isDesktopView, isTabletView, handleArticleCardClick, markerId]);

  return (
    <StyledArticleTiles
      direction="column"
      alignItems="stretch"
      className={classes.root}
    >
      {preparedTiles}
    </StyledArticleTiles>
  );
};
