"use client";
import React, { useMemo, useState } from "react";

import { kebabCase } from "lodash";
import { ErrorBoundary, ErrorBoundaryPropsWithRender } from "react-error-boundary";

import {
  BffComponent,
  BffComponentType,
  ComponentsConfig,
  Definition,
  IndependentDefinition,
} from "~/bff/ComponentsConfig";
import { ErrorFallback } from "~/components/ErrorBoundary/ErrorBoundary";
import { Nullable, Optional } from "~/types/utility.types";

import { ComponentNotFound } from "./components/ComponentNotFound";
import { prepareProps } from "./UniversalComponent.helpers";

export interface UniversalComponentProps {
  meta?: unknown;
  pages?: unknown;
  props?: unknown;
  elements?: BffComponent[];
  component?: BffComponent["component"] | Nullable<string>;
  componentsConfigs: ComponentsConfig[];
  parents?: BffComponent["component"][];
}

export const UniversalComponent = ({
  meta,
  pages,
  props,
  elements,
  component,
  componentsConfigs,
  parents,
}: UniversalComponentProps) => {
  const [componentStack, setComponentStack] = useState<string>("");
  const definition: Optional<Definition | IndependentDefinition> = useMemo(
    () =>
      component
        ? componentsConfigs?.find(
            (config) => config[component as BffComponentType]
          )?.[component as BffComponentType]
        : undefined,
    [componentsConfigs, component]
  );

  const Component = useMemo(
    () =>
      definition?.component ??
      ((props: Record<string, unknown>) => {
        return <ComponentNotFound component={component} {...props} />;
      }),
    [definition, component]
  );

  const shouldStopChildrenPreparation = useMemo(
    () =>
      (definition as IndependentDefinition)?.shouldStopChildrenPreparation ?? false,
    [definition]
  );

  const childConfigs = useMemo(
    () => [(definition as Definition)?.overrides ?? {}, ...componentsConfigs],
    [definition, componentsConfigs]
  );

  const preparedProps = useMemo(() => {
    if (shouldStopChildrenPreparation) {
      return Object.assign({}, props ?? {}, {
        meta: meta ?? null,
        pages: pages ?? null,
      });
    }

    return Object.assign({}, prepareProps(props, childConfigs) ?? {}, {
      meta: meta ?? null,
      pages: pages ?? null,
      testAutomationId: kebabCase(component ?? ""),
    });
  }, [shouldStopChildrenPreparation, childConfigs, component, meta, pages, props]);

  const handleError: ErrorBoundaryPropsWithRender["onError"] = (
    _,
    { componentStack }
  ) => {
    setComponentStack(componentStack);
  };

  return (
    <ErrorBoundary
      onError={handleError}
      fallbackRender={(fallbackProps) => (
        <ErrorFallback {...fallbackProps} componentStack={componentStack} />
      )}
    >
      <Component {...preparedProps}>
        {shouldStopChildrenPreparation
          ? elements
          : Array.isArray(elements)
          ? elements.map((child, index) => {
              if (!child) {
                return undefined;
              }
              const { component, props, _meta: meta, pages, children } = child;
              return (
                <UniversalComponent
                  key={index}
                  meta={meta}
                  pages={pages}
                  props={props}
                  elements={children}
                  component={component}
                  componentsConfigs={childConfigs}
                  parents={[...(parents ?? []), component]}
                />
              );
            })
          : undefined}
      </Component>
    </ErrorBoundary>
  );
};
