import { PARAMS } from "~/constants/request";
import { AzureConfigurator } from "~/services/azureConfiguration";

const hashRegex = /#.+$/;
const pathRegex = /^.+\?/;

interface GetPaginateUrlInput {
  direction: "prev" | "next";
  currentPage: number;
  fullPath: string;
  pageLocale: string;
}

export const getPaginateUrl = ({
  direction,
  currentPage,
  fullPath,
  pageLocale,
}: GetPaginateUrlInput): string => {
  const appConfig = AzureConfigurator.getConfig(pageLocale);
  const appBaseUrl = appConfig?.frontEndBaseUrl;

  if (!appBaseUrl) {
    return "";
  }

  const asPathWithoutHash = fullPath.replace(hashRegex, "");
  const [path] = asPathWithoutHash.match(pathRegex) ?? [asPathWithoutHash];
  const queryString = asPathWithoutHash.replace(path, "");

  const searchParams = new URLSearchParams(queryString);
  const offset = direction === "next" ? 1 : -1;
  const targetPage = currentPage + offset;

  searchParams.set(PARAMS.PAGE, `${targetPage}`);
  if (targetPage < 2) {
    searchParams.delete(PARAMS.PAGE);
  }

  const searchString = searchParams.toString();
  const questionMark = searchString ? "?" : "";
  const pageUrl = `${appBaseUrl.replace(/\/$/, "")}/${pageLocale}${path.replace(
    "?",
    ""
  )}`;

  return pageUrl + decodeURIComponent(questionMark + searchString);
};
