import React, { useMemo } from "react";

import { Typography, Grid, Container, styled } from "@mui/material";

import { NotFoundProps } from "~/bff/components/NotFound";
import { CombinedButton } from "~/components/CombinedButton";
import { Image } from "~/components/Image";
import { COLOR_WHITE } from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { RESPONSIVE_IMAGE_CONFIG } from "./Page404.constants";
import { convertNotFoundToProps } from "./Page404.helper";

const PREFIX = "StylePage404";
const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
};

const StyledPage404 = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      background: COLOR_WHITE,
    },
    [`& .${classes.grid}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(16),
      margin: "0 auto",
      background: COLOR_WHITE,
    },
    [`& .${classes.image}`]: {
      marginBottom: theme.spacing(4),
      width: 64,
      height: 64,
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(5),
        width: 80,
        height: 80,
      },
    },
    [`& .${classes.title}`]: {
      ...theme.typography.h4,
      marginBottom: theme.spacing(2),
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        ...theme.typography.h3,
        textAlign: "center",
        marginBottom: theme.spacing(3),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        ...theme.typography.h2,
        textAlign: "center",
        marginBottom: theme.spacing(4),
      },
    },
    [`& .${classes.text}`]: {
      marginBottom: theme.spacing(4),
      ...theme.typography.body1,
      textAlign: "center",
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(5),
        ...theme.typography.h6,
        textAlign: "center",
      },
    },
  };
});

export const Page404 = (data: NotFoundProps) => {
  const { title, text, image, buttonLabel } = useMemo(
    () =>
      convertNotFoundToProps(data) ?? {
        title: "",
        text: "",
        buttonLabel: "",
        image: {
          src: "",
          alt: "",
          title: "",
        },
      },
    [data]
  );

  return (
    <StyledPage404
      className={classes.root}
      {...getTestAutomationProps("not-found-page")}
    >
      <Grid item xs={12} sm={8} className={classes.grid}>
        {image && (
          <Image
            className={classes.image}
            responsiveImageConfig={image?.src ? RESPONSIVE_IMAGE_CONFIG : undefined}
            alt={prepareTextAttribute(image?.alt, true)}
            title={prepareTextAttribute(image?.title)}
            src={image?.src}
            {...getTestAutomationProps("primark-logo")}
          />
        )}
        {title && (
          <Typography className={classes.title} {...getTestAutomationProps("title")}>
            {title}
          </Typography>
        )}
        {text && (
          <Typography className={classes.text} {...getTestAutomationProps("text")}>
            {text}
          </Typography>
        )}
        {buttonLabel && (
          <CombinedButton href="/" size="large" variant="contained">
            {buttonLabel}
          </CombinedButton>
        )}
      </Grid>
    </StyledPage404>
  );
};
