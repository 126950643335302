import React from "react";

import { GlobalLayoutProps } from "~/bff/components/GlobalLayout";
import { BackToTopButton } from "~/components/BackToTopButton";

export const GlobalLayout = ({ header, children, footer }: GlobalLayoutProps) => {
  return (
    <>
      <BackToTopButton />
      {header}
      {children}
      {footer}
    </>
  );
};
