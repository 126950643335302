import { format } from "url";

import { VideoSource } from "~/bff/types/VideoSource";

export const getVideoSrc = (video: VideoSource): string =>
  format({
    protocol: "https:",
    host: video?.defaultHost,
    pathname: `/v/${video?.endpoint}/${video?.name}/${video?.profile}`,
  });
