import { ResponsiveImageConfig } from "~/components/Image";
import { AmplienceDynamicImageConfigKeys } from "~/components/Image/Image.types";
import { WindowFormat } from "~/hooks/useBreakpoints";

export const RESPONSIVE_IMAGE_CONFIG: ResponsiveImageConfig = {
  [WindowFormat.WIDE_DESKTOP]: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 80,
  },
  [WindowFormat.DESKTOP]: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 80,
  },
  [WindowFormat.TABLET]: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 64,
  },
  [WindowFormat.MOBILE]: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 64,
  },
};
