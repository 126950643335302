"use client";
import React, { useCallback, useState } from "react";

import { cx } from "@emotion/css";
import { Box, Button, styled } from "@mui/material";

import { getNavUrl } from "~/components/GlobalNavigation/helper/getNavUrl";
import { TypographyButtonClass } from "~/theme/constants/typography";
import { Right24 } from "~/theme/icons";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

import { GlobalNavigationProps } from "../../GlobalNavigation.types";
import { filterNavItems } from "../../helper/filterNavItems";

import { NavMenuPortrait } from "./NavMenuPortrait";

const PREFIX = "StyledGlobalNavigationPortrait";
const classes = {
  root: `${PREFIX}-root`,
  rootNavItem: `${PREFIX}-rootNavItem`,
};

const StyledGlobalNavigationPortrait = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    padding: `${theme.spacing(1)} 0`,
  },
  [`& .${classes.rootNavItem}`]: {
    height: "auto",
    display: "flex",
    minHeight: "48px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    textAlign: "left",

    "& > span": {
      textAlign: "left",
    },
  },
}));

export const GlobalNavigationPortrait = ({
  globalNavigation,
}: GlobalNavigationProps) => {
  const [activeNavMenu, setActiveNavMenu] = useState<Nullable<string>>(null);
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement>();

  const handleMenuOpen = useCallback(
    (id: string) => () => {
      setActiveNavMenu(id);
    },
    []
  );

  const handleMenuClose = useCallback(() => {
    setActiveNavMenu(null);
  }, []);

  return (
    <StyledGlobalNavigationPortrait
      ref={setAnchorRef}
      className={classes.root}
      {...getTestAutomationProps("global-nav-portrait")}
    >
      {filterNavItems(globalNavigation)?.map((item, index) => {
        const { id, title } = item?.props ?? {};
        const navUrl = getNavUrl(item?.props);
        const itemId = id ?? String(index);

        return (
          <React.Fragment key={itemId}>
            <Button
              variant="text"
              onClick={handleMenuOpen(itemId)}
              className={cx(classes.rootNavItem, TypographyButtonClass.DESKTOP_BOLD)}
              {...getTestAutomationProps("root-nav-item")}
            >
              {title ?? ""}
              <Right24 />
            </Button>
            {!!anchorRef && (
              <NavMenuPortrait
                titleUrl={navUrl}
                title={title ?? ""}
                anchorEl={anchorRef}
                onMenuClose={handleMenuClose}
                isOpen={activeNavMenu === itemId}
                itemList={item?.children ?? []}
              />
            )}
          </React.Fragment>
        );
      })}
    </StyledGlobalNavigationPortrait>
  );
};
