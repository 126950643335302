/* eslint-disable */
/* @ts-ignore */
/**
 * DO NOT MODIFY IT BY HAND.
 * This file was automatically generated.
 */

import React from "react";
import { Nullable } from "~/types/utility.types";
import { Accordion } from "~/bff/components/Accordion";
import { ArticleCards } from "~/bff/components/ArticleCards";
import { ArticlePage } from "~/bff/components/ArticlePage";
import { ArticlesCarousel } from "~/bff/components/ArticlesCarousel";
import { AssetCategory } from "~/bff/components/AssetCategory";
import { Author } from "~/bff/components/Author";
import { Banner } from "~/bff/components/Banner";
import { Copyright } from "~/bff/components/Copyright";
import { CountrySelector } from "~/bff/components/CountrySelector";
import { CountrySelectorChildren } from "~/bff/components/CountrySelectorChildren";
import { Dictionary } from "~/bff/components/Dictionary";
import { DidYouKnow } from "~/bff/components/DidYouKnow";
import { Footer } from "~/bff/components/Footer";
import { FooterNavChildren } from "~/bff/components/FooterNavChildren";
import { FooterNavChildrenLinks } from "~/bff/components/FooterNavChildrenLinks";
import { FooterNavigation } from "~/bff/components/FooterNavigation";
import { FooterSocialLinks } from "~/bff/components/FooterSocialLinks";
import { FooterSocialLinksItem } from "~/bff/components/FooterSocialLinksItem";
import { GCP } from "~/bff/components/GCP";
import { GenericHeading } from "~/bff/components/GenericHeading";
import { GenericMediaLibrary } from "~/bff/components/GenericMediaLibrary";
import { GlobalLayout } from "~/bff/components/GlobalLayout";
import { Header } from "~/bff/components/Header";
import { HeroBanner } from "~/bff/components/HeroBanner";
import { HomePage } from "~/bff/components/HomePage";
import { ImageComponent } from "~/bff/components/ImageComponent";
import { Images } from "~/bff/components/Images";
import { JsonLd } from "~/bff/components/JsonLd";
import { JsonLdArray } from "~/bff/components/JsonLdArray";
import { JsonLdContainer } from "~/bff/components/JsonLdContainer";
import { MediaAsset } from "~/bff/components/MediaAsset";
import { MediaAssets } from "~/bff/components/MediaAssets";
import { MediaEnquiries } from "~/bff/components/MediaEnquiries";
import { MiddleTextLink } from "~/bff/components/MiddleTextLink";
import { NavItem } from "~/bff/components/NavItem";
import { NewsroomPage } from "~/bff/components/NewsroomPage";
import { NotFound } from "~/bff/components/NotFound";
import { NotFoundChildren } from "~/bff/components/NotFoundChildren";
import { NotFoundItemChildren } from "~/bff/components/NotFoundItemChildren";
import { PDFDownload } from "~/bff/components/PDFDownload";
import { PartnerLogoRichText } from "~/bff/components/PartnerLogoRichText";
import { PartnerLogos } from "~/bff/components/PartnerLogos";
import { PrimarkJourney } from "~/bff/components/PrimarkJourney";
import { RichTextBlock } from "~/bff/components/RichTextBlock";
import { RobotsTxt } from "~/bff/components/RobotsTxt";
import { SeoLinkCanonicalMetaTag } from "~/bff/components/SeoLinkCanonicalMetaTag";
import { SeoMetaTag } from "~/bff/components/SeoMetaTag";
import { SeoSocialMetaTag } from "~/bff/components/SeoSocialMetaTag";
import { SeoTitleMetaTag } from "~/bff/components/SeoTitleMetaTag";
import { Theme } from "~/bff/components/Theme";
import { TwoColumnContent } from "~/bff/components/TwoColumnContent";
import { TwoColumnContentSection } from "~/bff/components/TwoColumnContentSection";
import { Video } from "~/bff/components/Video";
export interface Definition<T = React.FC> {
  component: T;
  overrides?: ComponentsConfig;
}
export interface IndependentDefinition {
  component: React.FC;
  /*
   * THIS RENDERING METHOD HAS MADE JUST IN CASE
   * AND WE SHOULDN'T USE IT IN SIMPLE CASES.
   *
   * You can ask advice from:
   * - nkornilov@primark.onmicrosoft.com
   * - iivashchenko@primark.onmicrosoft.com
   * - volodymyr.forfutdinov@primark.onmicrosoft.com
   * - orodzevich@primark.onmicrosoft.com
   *
   * @deprecated
   * @experimental
   */
  shouldStopChildrenPreparation: true;
}
export interface BffComponent {
  _meta?: unknown;
  pages?: unknown;
  props?: unknown;
  children?: BffComponent[];
  component?: Nullable<BffComponentType>;
}
export enum BffComponentType {
  ACCORDION = "Accordion",
  ARTICLE_CARDS = "ArticleCards",
  ARTICLE_PAGE = "ArticlePage",
  ARTICLES_CAROUSEL = "ArticlesCarousel",
  ASSET_CATEGORY = "AssetCategory",
  AUTHOR = "Author",
  BANNER = "Banner",
  COPYRIGHT = "Copyright",
  COUNTRY_SELECTOR = "CountrySelector",
  COUNTRY_SELECTOR_CHILDREN = "CountrySelectorChildren",
  DICTIONARY = "Dictionary",
  DID_YOU_KNOW = "DidYouKnow",
  FOOTER = "Footer",
  FOOTER_NAV_CHILDREN = "FooterNavChildren",
  FOOTER_NAV_CHILDREN_LINKS = "FooterNavChildrenLinks",
  FOOTER_NAVIGATION = "FooterNavigation",
  FOOTER_SOCIAL_LINKS = "FooterSocialLinks",
  FOOTER_SOCIAL_LINKS_ITEM = "FooterSocialLinksItem",
  GCP = "GcpPage",
  GENERIC_HEADING = "GenericHeading",
  GENERIC_MEDIA_LIBRARY = "GenericMediaLibrary",
  GLOBAL_LAYOUT = "GlobalLayout",
  HEADER = "Header",
  HERO_BANNER = "HeroBanner",
  HOME_PAGE = "HomePage",
  IMAGE_COMPONENT = "ImageComponent",
  IMAGES = "Images",
  JSON_LD = "JSON-LD",
  JSON_LD_ARRAY = "JsonLdArray",
  JSON_LD_CONTAINER = "JSON-LD/Container",
  MEDIA_ASSET = "MediaAsset",
  MEDIA_ASSETS = "MediaAssets",
  MEDIA_ENQUIRIES = "MediaEnquiries",
  MIDDLE_TEXT_LINK = "MiddleTextLink",
  NAV_ITEM = "NavItem",
  NEWSROOM_PAGE = "NewsroomPage",
  NOT_FOUND = "404Page",
  NOT_FOUND_CHILDREN = "NotFoundChildren",
  NOT_FOUND_ITEM_CHILDREN = "NotFoundItemChildren",
  PDF_DOWNLOAD = "PDFDownload",
  PARTNER_LOGO_RICH_TEXT = "PartnerLogoRichText",
  PARTNER_LOGOS = "PartnerLogos",
  PRIMARK_JOURNEY = "PrimarkJourney",
  RICH_TEXT_BLOCK = "RichTextBlock",
  ROBOTS_TXT = "RobotsTxt",
  SEO_LINK_CANONICAL_META_TAG = "SeoLinkCanonical",
  SEO_SOCIAL_META_TAG = "SeoSocial",
  SEO_TITLE_META_TAG = "SeoTitle",
  THEME = "Theme",
  TWO_COLUMN_CONTENT = "TwoColumnContent",
  TWO_COLUMN_CONTENT_SECTION = "TwoColumnContentSection",
  VIDEO = "Video",
  SEO_DESCRIPTION = "SeoDescription",
  SEO_ROBOTS = "SeoRobots",
}
export interface ComponentsConfig {
  [BffComponentType.ACCORDION]?: Definition<Accordion> | IndependentDefinition;
  [BffComponentType.ARTICLE_CARDS]?:
    | Definition<ArticleCards>
    | IndependentDefinition;
  [BffComponentType.ARTICLE_PAGE]?: Definition<ArticlePage> | IndependentDefinition;
  [BffComponentType.ARTICLES_CAROUSEL]?:
    | Definition<ArticlesCarousel>
    | IndependentDefinition;
  [BffComponentType.ASSET_CATEGORY]?:
    | Definition<AssetCategory>
    | IndependentDefinition;
  [BffComponentType.AUTHOR]?: Definition<Author> | IndependentDefinition;
  [BffComponentType.BANNER]?: Definition<Banner> | IndependentDefinition;
  [BffComponentType.COPYRIGHT]?: Definition<Copyright> | IndependentDefinition;
  [BffComponentType.COUNTRY_SELECTOR]?:
    | Definition<CountrySelector>
    | IndependentDefinition;
  [BffComponentType.COUNTRY_SELECTOR_CHILDREN]?:
    | Definition<CountrySelectorChildren>
    | IndependentDefinition;
  [BffComponentType.DICTIONARY]?: Definition<Dictionary> | IndependentDefinition;
  [BffComponentType.DID_YOU_KNOW]?: Definition<DidYouKnow> | IndependentDefinition;
  [BffComponentType.FOOTER]?: Definition<Footer> | IndependentDefinition;
  [BffComponentType.FOOTER_NAV_CHILDREN]?:
    | Definition<FooterNavChildren>
    | IndependentDefinition;
  [BffComponentType.FOOTER_NAV_CHILDREN_LINKS]?:
    | Definition<FooterNavChildrenLinks>
    | IndependentDefinition;
  [BffComponentType.FOOTER_NAVIGATION]?:
    | Definition<FooterNavigation>
    | IndependentDefinition;
  [BffComponentType.FOOTER_SOCIAL_LINKS]?:
    | Definition<FooterSocialLinks>
    | IndependentDefinition;
  [BffComponentType.FOOTER_SOCIAL_LINKS_ITEM]?:
    | Definition<FooterSocialLinksItem>
    | IndependentDefinition;
  [BffComponentType.GCP]?: Definition<GCP> | IndependentDefinition;
  [BffComponentType.GENERIC_HEADING]?:
    | Definition<GenericHeading>
    | IndependentDefinition;
  [BffComponentType.GENERIC_MEDIA_LIBRARY]?:
    | Definition<GenericMediaLibrary>
    | IndependentDefinition;
  [BffComponentType.GLOBAL_LAYOUT]?:
    | Definition<GlobalLayout>
    | IndependentDefinition;
  [BffComponentType.HEADER]?: Definition<Header> | IndependentDefinition;
  [BffComponentType.HERO_BANNER]?: Definition<HeroBanner> | IndependentDefinition;
  [BffComponentType.HOME_PAGE]?: Definition<HomePage> | IndependentDefinition;
  [BffComponentType.IMAGE_COMPONENT]?:
    | Definition<ImageComponent>
    | IndependentDefinition;
  [BffComponentType.IMAGES]?: Definition<Images> | IndependentDefinition;
  [BffComponentType.JSON_LD]?: Definition<JsonLd> | IndependentDefinition;
  [BffComponentType.JSON_LD_ARRAY]?: Definition<JsonLdArray> | IndependentDefinition;
  [BffComponentType.JSON_LD_CONTAINER]?:
    | Definition<JsonLdContainer>
    | IndependentDefinition;
  [BffComponentType.MEDIA_ASSET]?: Definition<MediaAsset> | IndependentDefinition;
  [BffComponentType.MEDIA_ASSETS]?: Definition<MediaAssets> | IndependentDefinition;
  [BffComponentType.MEDIA_ENQUIRIES]?:
    | Definition<MediaEnquiries>
    | IndependentDefinition;
  [BffComponentType.MIDDLE_TEXT_LINK]?:
    | Definition<MiddleTextLink>
    | IndependentDefinition;
  [BffComponentType.NAV_ITEM]?: Definition<NavItem> | IndependentDefinition;
  [BffComponentType.NEWSROOM_PAGE]?:
    | Definition<NewsroomPage>
    | IndependentDefinition;
  [BffComponentType.NOT_FOUND]?: Definition<NotFound> | IndependentDefinition;
  [BffComponentType.NOT_FOUND_CHILDREN]?:
    | Definition<NotFoundChildren>
    | IndependentDefinition;
  [BffComponentType.NOT_FOUND_ITEM_CHILDREN]?:
    | Definition<NotFoundItemChildren>
    | IndependentDefinition;
  [BffComponentType.PDF_DOWNLOAD]?: Definition<PDFDownload> | IndependentDefinition;
  [BffComponentType.PARTNER_LOGO_RICH_TEXT]?:
    | Definition<PartnerLogoRichText>
    | IndependentDefinition;
  [BffComponentType.PARTNER_LOGOS]?:
    | Definition<PartnerLogos>
    | IndependentDefinition;
  [BffComponentType.PRIMARK_JOURNEY]?:
    | Definition<PrimarkJourney>
    | IndependentDefinition;
  [BffComponentType.RICH_TEXT_BLOCK]?:
    | Definition<RichTextBlock>
    | IndependentDefinition;
  [BffComponentType.ROBOTS_TXT]?: Definition<RobotsTxt> | IndependentDefinition;
  [BffComponentType.SEO_LINK_CANONICAL_META_TAG]?:
    | Definition<SeoLinkCanonicalMetaTag>
    | IndependentDefinition;
  [BffComponentType.SEO_SOCIAL_META_TAG]?:
    | Definition<SeoSocialMetaTag>
    | IndependentDefinition;
  [BffComponentType.SEO_TITLE_META_TAG]?:
    | Definition<SeoTitleMetaTag>
    | IndependentDefinition;
  [BffComponentType.THEME]?: Definition<Theme> | IndependentDefinition;
  [BffComponentType.TWO_COLUMN_CONTENT]?:
    | Definition<TwoColumnContent>
    | IndependentDefinition;
  [BffComponentType.TWO_COLUMN_CONTENT_SECTION]?:
    | Definition<TwoColumnContentSection>
    | IndependentDefinition;
  [BffComponentType.VIDEO]?: Definition<Video> | IndependentDefinition;
  [BffComponentType.SEO_ROBOTS]?: Definition<SeoMetaTag> | IndependentDefinition;
  [BffComponentType.SEO_DESCRIPTION]?:
    | Definition<SeoMetaTag>
    | IndependentDefinition;
}
