import React, { useMemo } from "react";

import { Box, styled } from "@mui/material";

import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { useScrollRestoration } from "~/hooks/useScrollRestoration";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { SeoMetaTags } from "../SeoMetaTags";

import { ArticlePageHeading } from "./ArticlePageHeading";
import { getJsonLdConfig } from "./helpers/getJsonLdConfig";

const PREFIX = "StyledArticlePage";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledArticlePage = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(0),

      "& > div": {
        marginBottom: theme.spacing(7),
        marginTop: theme.spacing(0),
        width: "100%",

        "&:last-child": {
          marginBottom: theme.spacing(0),
        },

        [formatMedia.BREAKPOINT_DESKTOP]: {
          marginBottom: theme.spacing(8),
        },
      },
    },

    "&>:not([hidden])~:not([hidden])": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    [`& div[data-testautomation-id="pdf-download"]
    ,& div[data-testautomation-id="accordion-container"]`]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      [formatMedia.BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        maxWidth: "644px",
      },
    },
  };
});

export const ArticlePage = ({
  children,
  testAutomationId,
  seo,
  localizedPages,
  ...props
}: ArticlePageProps) => {
  useScrollRestoration();

  const jsonLDConfig = useMemo(() => getJsonLdConfig(props), [props]);

  return (
    <StyledArticlePage
      className={classes.root}
      {...getTestAutomationProps(testAutomationId ?? "article-page")}
    >
      {seo && <SeoMetaTags {...seo} extraProps={{ jsonLDConfig }} />}
      <ArticlePageHeading {...props} />
      {children as React.ReactElement[]}
    </StyledArticlePage>
  );
};
