import React, { useCallback } from "react";

import { IconButton, Stack } from "@mui/material";
import { noop } from "lodash";

import { SOCIAL_MEDIA_ICONS, SocialMedia } from "~/constants/socialMedia";
import { useAzureConfig } from "~/services/azureConfiguration";
import { LinkPic24 } from "~/theme/icons";
import { getTestAutomationProps } from "~/utils/testUtils";

import { getSocialMediaLink } from "../../helper/getSocialMediaLink";

export interface ShareTooltipProps {
  url: string;
  description?: string;
  socialMediaList?: SocialMedia[];
}

export const ShareTooltip = ({
  url,
  description = "Check it out!",
  socialMediaList = [
    SocialMedia.FACEBOOK,
    SocialMedia.TWITTER,
    SocialMedia.PINTEREST,
  ],
}: ShareTooltipProps) => {
  const { appConfig } = useAzureConfig();
  const encodedUrl = encodeURIComponent(url);

  const handleLinkCopy = useCallback(() => {
    navigator?.clipboard.writeText(url).catch(noop);
  }, [url]);

  return (
    <Stack
      py={0.5}
      spacing={1}
      direction="row"
      {...getTestAutomationProps("share-tooltip")}
    >
      {socialMediaList.map((media) => {
        const link = getSocialMediaLink({
          facebookAppId: appConfig?.facebookAppId as string,
          socialMedia: media,
          url: encodedUrl,
          description,
        });

        return link ? (
          <a
            key={media}
            href={link}
            target="_blank"
            rel="noreferrer"
            {...getTestAutomationProps("social-media-link")}
          >
            <IconButton color="inherit">{SOCIAL_MEDIA_ICONS[media]}</IconButton>
          </a>
        ) : null;
      })}
      <IconButton
        color="inherit"
        onClick={handleLinkCopy}
        {...getTestAutomationProps("copy-link-button")}
      >
        <LinkPic24 />
      </IconButton>
    </Stack>
  );
};
