import React from "react";

import {
  Facebook24,
  Instagram24,
  Pinterest24,
  Tiktok24,
  Twitter24,
  WhatsApp24,
  Youtube24,
} from "~/theme/icons";

export enum SocialMedia {
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  WHATSAPP = "whatsapp",
  PINTEREST = "pinterest",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  YOUTUBE = "youtube",
}

export const SOCIAL_MEDIA_ICONS = {
  [SocialMedia.FACEBOOK]: <Facebook24 />,
  [SocialMedia.TWITTER]: <Twitter24 />,
  [SocialMedia.WHATSAPP]: <WhatsApp24 />,
  [SocialMedia.PINTEREST]: <Pinterest24 />,
  [SocialMedia.INSTAGRAM]: <Instagram24 />,
  [SocialMedia.TIKTOK]: <Tiktok24 />,
  [SocialMedia.YOUTUBE]: <Youtube24 />,
};
