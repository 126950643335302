import React from "react";

import { AppBar, Slide, useScrollTrigger, styled } from "@mui/material";

import { Image } from "~/bff/types/Image";
import { GlobalNavigationPortrait } from "~/components/GlobalNavigation/components/GlobalNavigationPortrait";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { HEADER_HEIGHT_PORTRAIT } from "../../Header.constants";
import { useHeaderContext } from "../../Header.hooks";
import { HeaderExtendedProps } from "../../Header.types";
import { HeaderNavLinksPortrait } from "../HeaderNavLinksPortrait";

import { HeaderSideMenu } from "./HeaderSideMenu";
import { HeaderToolbar } from "./HeaderToolbar";

const StyledHeaderPortrait = styled(AppBar)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.1);",
    height: HEADER_HEIGHT_PORTRAIT,

    [`@media (orientation: portrait)`]: {
      display: "flex",
    },

    [`${formatMedia.BREAKPOINT_DESKTOP} and (orientation: landscape)`]: {
      display: "none",
    },
  };
});

export const HeaderPortrait = ({
  logo,
  navigationLinks,
  globalNavigation,
}: HeaderExtendedProps) => {
  const isTriggered = useScrollTrigger();
  const isScrollOnTheTop = !useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const { isMenuOpen, handleMenuOpen, handleMenuClose } = useHeaderContext();

  return (
    <Slide appear={false} direction="down" in={!isTriggered}>
      <StyledHeaderPortrait
        position="sticky"
        elevation={isScrollOnTheTop ? 0 : 4}
        {...getTestAutomationProps("header-portrait")}
      >
        <HeaderToolbar
          logo={logo as Image}
          isMenuOpen={isMenuOpen}
          onMenuOpen={handleMenuOpen}
        />
        <HeaderSideMenu
          logo={logo as Image}
          isOpen={isMenuOpen}
          onClose={handleMenuClose}
        >
          <GlobalNavigationPortrait globalNavigation={globalNavigation} />
          {Boolean(navigationLinks?.length) && (
            <HeaderNavLinksPortrait links={navigationLinks} />
          )}
        </HeaderSideMenu>
      </StyledHeaderPortrait>
    </Slide>
  );
};
