import React from "react";

import { styled, Container } from "@mui/material";

import { TwoColumnContentProps } from "~/bff/components/TwoColumnContent";
import { TwoColumnContentSectionProps } from "~/bff/components/TwoColumnContentSection";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledTwoColumnContent";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledTwoColumnContent = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(7),
      maxWidth: "1440px",
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(16),
      },
    },
  };
});

export const TwoColumnContent = ({
  children,
  testAutomationId = "TwoColumnContent",
}: TwoColumnContentProps) => {
  return (
    <StyledTwoColumnContent
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      {children as React.ReactElement<TwoColumnContentSectionProps>[]}
    </StyledTwoColumnContent>
  );
};
