import React, { memo, MouseEvent, useCallback, useContext, useRef } from "react";

import { MenuItem } from "@mui/material";

import { NavItem } from "~/bff/types/NavItem";
import { CombinedLink } from "~/components/CombinedLink";
import { getNavUrl } from "~/components/GlobalNavigation/helper/getNavUrl";
import { Right24 } from "~/theme/icons";
import { Nullable } from "~/types/utility.types";
import { getTestAutomationProps } from "~/utils/testUtils";

import { NavMenuLandscape } from "../NavMenuLandscape";
import { NavMenuLandscapeContext } from "../NavMenuLandscapeContext";

interface SubMenuTriggerProps {
  level?: number;
  isOpen: boolean;
  anchorId: string;
  className?: string;
  data: Nullable<NavItem>;
  onMenuClose: (event: React.SyntheticEvent) => void;
  setActiveSubMenu: React.Dispatch<React.SetStateAction<Nullable<string>>>;
}

export const SubMenuTrigger = memo(
  ({
    data,
    level,
    isOpen,
    anchorId,
    className,
    onMenuClose,
    setActiveSubMenu,
  }: SubMenuTriggerProps) => {
    const { setNavigationMode } = useContext(NavMenuLandscapeContext);
    const anchorRef = useRef<Nullable<HTMLLIElement>>(null);

    const { props, children } = data ?? {};
    const { title } = props ?? {};
    const navUrl = getNavUrl(props);

    const handleSubMenuClose = useCallback(
      (event: React.SyntheticEvent) => {
        event.stopPropagation();

        if ((event as React.KeyboardEvent).code === "ArrowLeft") {
          setActiveSubMenu(null);
          anchorRef.current?.focus();
          setNavigationMode("keyboard");
          return;
        }

        onMenuClose(event);
        setActiveSubMenu(null);
      },
      [onMenuClose, setActiveSubMenu, setNavigationMode]
    );

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<HTMLLIElement>) => {
        setNavigationMode("mouse");
        setActiveSubMenu(anchorId);
        event.currentTarget.focus();
        event.stopPropagation();
      },
      [anchorId, setActiveSubMenu, setNavigationMode]
    );

    const handleMouseLeave = useCallback(() => {
      setActiveSubMenu(null);
    }, [setActiveSubMenu]);

    const handleKeydown = useCallback(
      (event: React.KeyboardEvent) => {
        if (["Enter", "Space", "ArrowRight"].includes(event.code)) {
          event.stopPropagation();
          setActiveSubMenu(anchorId);
          setNavigationMode("keyboard");
        }
      },
      [anchorId, setActiveSubMenu, setNavigationMode]
    );

    const handleOnClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
    }, []);

    return (
      <MenuItem
        id={anchorId}
        className={className}
        data-menu-active={isOpen}
        onKeyDown={handleKeydown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={anchorRef as unknown as React.RefObject<HTMLLIElement>}
        aria-haspopup="menu"
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={isOpen ? `nav-menu-level-${level}` : undefined}
        {...getTestAutomationProps("sub-menu-trigger")}
      >
        <CombinedLink
          display="flex"
          variant="body1"
          underline="none"
          href={navUrl}
          {...getTestAutomationProps("sub-menu-item")}
          sx={{
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
          onClick={handleOnClick}
        >
          {title ?? ""}
        </CombinedLink>
        <Right24 />
        <NavMenuLandscape
          level={level}
          isOpen={isOpen}
          itemList={children}
          anchorId={anchorId}
          anchorEl={anchorRef.current}
          onMenuClose={handleSubMenuClose}
        />
      </MenuItem>
    );
  }
);

SubMenuTrigger.displayName = "SubMenuTrigger";
