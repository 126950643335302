import React from "react";

import { Container, styled } from "@mui/material";

import { GenericMediaLibraryProps } from "~/bff/components/GenericMediaLibrary";
import { AssetCategory } from "~/bff/types/AssetCategory";
import { Image } from "~/bff/types/Image";
import { MediaAsset } from "~/bff/types/MediaAsset";
import { getTestAutomationProps } from "~/utils/testUtils";

import { Categories } from "./components/Categories";
import { Header } from "./components/Header";

const PREFIX = "StyleMediaLibrary";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledMediaLibrary = styled(Container)(() => {
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      flexDirection: "column",
    },
  };
});

export const MediaLibrary = (props: GenericMediaLibraryProps) => {
  const {
    title,
    subtitle,
    description,
    image,
    assetCategory,
    allAssetsTabText,
    loadMoreButtonText,
    loadPreviousButtonText,
    progressBarText,
    mediaAssets,
  } = props;

  return (
    <StyledMediaLibrary
      className={classes.root}
      {...getTestAutomationProps("media-library")}
    >
      <Header
        title={title ?? ""}
        subtitle={subtitle ?? ""}
        description={description ?? ""}
        image={image?.props as Image}
      />
      <Categories
        mediaAssets={mediaAssets as MediaAsset[]}
        assetCategory={assetCategory as AssetCategory[]}
        progressBarText={progressBarText ?? ""}
        loadMoreButtonText={loadMoreButtonText ?? ""}
        loadPreviousButtonText={loadPreviousButtonText ?? ""}
        allAssetsTabText={allAssetsTabText ?? ""}
      />
    </StyledMediaLibrary>
  );
};
