import React, { Children, cloneElement, isValidElement, ReactNode } from "react";

import { Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { CombinedLink } from "~/components/CombinedLink";

interface TableProps {
  children: ReactNode;
}

const isAllText = (children: ReactNode): boolean =>
  Children.map(children, (child) => typeof child === "string")?.filter(Boolean)
    .length === Children.count(children);

export const Table = ({ children }: TableProps) => {
  const iterateOverChildren = (children: ReactNode): ReactNode => {
    return Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child;
      }

      if (child.type === "td" && isAllText(child.props.children)) {
        const texts = Children.map(child.props.children, (child) =>
          child?.toString()
        )?.join("\r\n\r\n");
        return (
          <td>
            <ReactMarkdown
              components={{
                /* @ts-expect-error Due to undefined*/
                a: CombinedLink,
                p: ({ children }) => (
                  <Typography>
                    {children[0] === "[br]" ? <br /> : children}
                  </Typography>
                ),
              }}
              skipHtml
              remarkPlugins={[remarkGfm]}
            >
              {texts}
            </ReactMarkdown>
          </td>
        );
      }

      return cloneElement(child, {
        ...child.props,
        children: iterateOverChildren(child.props.children),
      });
    });
  };

  return <table>{iterateOverChildren(children)}</table>;
};
