import React from "react";

import { Box, Typography } from "@mui/material";

import { COLOR_PRIMARY_BLACK, COLOR_PRIMARY_WHITE } from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

interface CopyrightProps {
  copyrightText: string;
}

export const Copyright = ({ copyrightText }: CopyrightProps) => {
  return (
    <Box
      sx={{
        height: 48,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: COLOR_PRIMARY_BLACK,
        color: COLOR_PRIMARY_WHITE,
      }}
    >
      <Typography variant="body2" {...getTestAutomationProps("copyright")}>
        {copyrightText}
      </Typography>
    </Box>
  );
};
