import React, { useCallback, useState, useMemo, useEffect } from "react";

import { styled, Tabs, Tab } from "@mui/material";
import { usePathname, useSearchParams } from "next/navigation";

import { AssetCategory } from "~/bff/types/AssetCategory";
import { MediaAsset } from "~/bff/types/MediaAsset";
import { DEFAULT_PAGE_SIZE } from "~/constants/common";
import { PARAMS } from "~/constants/request";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getQueryFromUrl } from "~/utils/getQueryFromUrl";
import { isSamePage } from "~/utils/isSamePage";
import { getTestAutomationProps } from "~/utils/testUtils";
import { updateRouterQuery } from "~/utils/updateRouterQuery";

import { filterAssets } from "../MediaLibrary.helpers";

import { Assets } from "./Assets";

interface CategoryProps {
  assetCategory: AssetCategory[];
  allAssetsTabText: string;
  loadMoreButtonText: string;
  loadPreviousButtonText: string;
  progressBarText: string;
  mediaAssets: MediaAsset[];
}

const PREFIX = "StyledCategories";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledCategories = styled(Tabs)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      marginTop: theme.spacing(2),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(3),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginTop: theme.spacing(8),
      },
    },
  };
});

export const Categories = ({
  assetCategory,
  allAssetsTabText,
  loadMoreButtonText,
  loadPreviousButtonText,
  progressBarText,
  mediaAssets,
}: CategoryProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const tabParam = searchParams.get(PARAMS.TAB);
  const pageParam = searchParams.get(PARAMS.PAGE);
  const [activeCategory, setActiveCategory] = useState<string>(
    String(tabParam ?? allAssetsTabText)
  );
  const [assets, setAssets] = useState(
    filterAssets(mediaAssets, activeCategory, allAssetsTabText)
  );
  const [initPage, setInitPage] = useState(Number(pageParam ?? 1));

  const categories = useMemo(() => {
    return [allAssetsTabText, ...assetCategory.map(({ props }) => props?.title)];
  }, [allAssetsTabText, assetCategory]);

  const start = (initPage - 1) * DEFAULT_PAGE_SIZE;
  const end = Math.min(start + DEFAULT_PAGE_SIZE, assets?.length);
  const defaultAssets = assets?.slice(start, end);

  useEffect(() => {
    const popstateHandler = (event: PopStateEvent) => {
      const {
        state: { as },
      } = event ?? {};
      if (!isSamePage(as, pathname)) {
        return;
      }

      const { tab, page } = getQueryFromUrl(window.location.href);
      const currentTab = String(tab ?? allAssetsTabText);
      const currentPage = Number(page ?? 1);

      setActiveCategory(currentTab);
      setInitPage(currentPage);
      setAssets(filterAssets(mediaAssets, currentTab, allAssetsTabText));

      if (document?.activeElement instanceof HTMLElement) {
        document?.activeElement?.blur();
      }
    };

    window.addEventListener("popstate", popstateHandler);
    return () => {
      window.removeEventListener("popstate", popstateHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = useCallback(
    (event: unknown, selectedTab: string) => {
      setActiveCategory(selectedTab);
      setInitPage(1);
      setAssets(filterAssets(mediaAssets, selectedTab, allAssetsTabText));

      updateRouterQuery({
        pathname,
        searchParams,
        selectedTab: selectedTab,
        allTabText: allAssetsTabText,
      });
    },
    [mediaAssets, allAssetsTabText, pathname, searchParams]
  );

  return (
    <>
      <StyledCategories
        className={classes.root}
        variant="scrollable"
        value={activeCategory}
        onChange={handleTabChange}
        scrollButtons={false}
        {...getTestAutomationProps("media-categories")}
      >
        {categories.map((category, index) => (
          <Tab
            key={`${index}-${category}`}
            value={category}
            label={category}
            {...getTestAutomationProps(`media-category-${category}`)}
          />
        ))}
      </StyledCategories>
      <Assets
        assets={assets}
        defaultAssets={defaultAssets}
        initPage={initPage}
        loadMoreButtonText={loadMoreButtonText}
        loadPreviousButtonText={loadPreviousButtonText}
        progressBarText={progressBarText}
      />
    </>
  );
};
