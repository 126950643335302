import React, { Children, useMemo } from "react";

import { Alert, AlertTitle, Divider, styled, Typography } from "@mui/material";
import { template } from "lodash";

import { BffComponent } from "~/bff/ComponentsConfig";
import { Nullable } from "~/types/utility.types";
import { isDebug } from "~/utils/isDebug";

import { COMPONENT_HASNT_BEEN_CONFIGURED_YET } from "./ComponentNotFound.constants";

export interface ComponentNotFoundProps {
  component: BffComponent["component"] | Nullable<string>;
  children?: React.ReactNode;
}

const PREFIX = "StyledNotFound";
const classes = {
  root: `${PREFIX}-root`,
  message: `${PREFIX}-message`,
  text: `${PREFIX}-text`,
};

const StyledNotFound = styled(Alert)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  [`&.${classes.message}`]: {
    padding: 0,
  },
  [`& .${classes.text}`]: {
    paddingBottom: theme.spacing(3),
  },
}));

export const ComponentNotFound = ({
  children,
  component,
}: ComponentNotFoundProps) => {
  const message = useMemo(
    () => template(COMPONENT_HASNT_BEEN_CONFIGURED_YET)({ component }),
    [component]
  );

  if (isDebug()) {
    return <>{children}</>;
  }

  return (
    <StyledNotFound
      classes={{ root: classes.root, message: classes.message }}
      severity="error"
    >
      <AlertTitle>Error</AlertTitle>
      <Typography className={classes.text}>{message}</Typography>
      {Children.count(children) !== 0 ? (
        <>
          <Divider />
          {children}
        </>
      ) : undefined}
    </StyledNotFound>
  );
};
