interface PaginateFnInput {
  total: number;
  current: number;
  size: number;
}

interface PaginateReturnType {
  start: number;
  end: number;
}

type PaginateFn = (input: PaginateFnInput) => PaginateReturnType;

export const paginate: PaginateFn = ({ total, current, size }) => {
  const pages = Math.ceil(total / size);

  if (current < 1) {
    current = 1;
  } else if (current > pages) {
    current = pages;
  }

  const start = (current - 1) * size;
  const end = Math.min(start + size - 1, total - 1);

  return {
    start,
    end,
  };
};
