import React from "react";

import { Box, Divider, Stack, styled } from "@mui/material";

import { HeaderProps } from "~/bff/components/Header";
import { CombinedButton } from "~/components/CombinedButton";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledHeaderNavLinksPortrait";
const classes = {
  divider: `${PREFIX}-divider`,
  link: `${PREFIX}-link`,
};
const StyledHeaderNavLinksPortrait = styled(Stack)(({ theme }) => {
  return {
    [`& .${classes.divider}`]: {
      "&:first-of-type": {
        marginBottom: theme.spacing(1),
      },

      "&:last-of-type": {
        marginTop: theme.spacing(1),
      },
    },
    [`& .${classes.link}`]: {
      height: "48px",
      display: "flex",
      alignItems: "center",
      padding: `0 ${theme.spacing(2)}`,
    },
  };
});

export interface HeaderNavLinksPortraitProps {
  links: HeaderProps["navigationLinks"];
}

export const HeaderNavLinksPortrait = ({ links }: HeaderNavLinksPortraitProps) => {
  return (
    <StyledHeaderNavLinksPortrait
      direction="column"
      alignItems="stretch"
      {...getTestAutomationProps("header-nav-links-portrait")}
    >
      <Divider className={classes.divider} />
      {links?.map((item, index) => (
        <Box key={index} className={classes.link}>
          <CombinedButton
            variant="text"
            className="body1"
            href={item?.link?.url ?? ""}
            {...getTestAutomationProps("header-nav-link")}
          >
            {item?.link?.label}
          </CombinedButton>
        </Box>
      ))}
      <Divider className={classes.divider} />
    </StyledHeaderNavLinksPortrait>
  );
};
