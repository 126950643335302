import React from "react";

import { Box, Grid, styled } from "@mui/material";
import { isEmpty } from "lodash";

import { ImagesProps } from "~/bff/components/Images";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { isLastItemOdd } from "./helpers";
import { ImageCard } from "./ImageCard";

const PREFIX = "StyledImageComponent";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledImageComponent = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [formatMedia.BREAKPOINT_TABLET]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [formatMedia.BREAKPOINT_DESKTOP]: {
      paddingLeft: theme.spacing(21.875),
      paddingRight: theme.spacing(21.875),
    },
    [`&.${classes.root}`]: {
      marginBottom: theme.spacing(5.5),

      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(6.5),
      },
    },
  };
});

export const Images = ({
  images,
  testAutomationId = "image-container",
}: ImagesProps) => {
  const totalImages = images?.length ?? 0;

  if (isEmpty(images)) {
    return null;
  }

  return (
    <StyledImageComponent
      className={classes.root}
      {...getTestAutomationProps(testAutomationId ?? "image-container")}
    >
      <Grid container spacing={{ xs: 2, sm: 3 }} justifyContent="center">
        {images?.map((imageCard, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={isLastItemOdd(index, totalImages) ? 12 : 6}
            md={totalImages === 1 ? 8 : 12 / totalImages}
          >
            <ImageCard key={index} {...imageCard} isSingle={totalImages === 1} />
          </Grid>
        ))}
      </Grid>
    </StyledImageComponent>
  );
};
