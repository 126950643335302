import React from "react";

import { Grid, styled } from "@mui/material";

import { CountrySelectorChildrenProps } from "~/bff/components/CountrySelectorChildren";
import { CountrySelectorPropsExtended } from "~/components/CountrySelector";
import { getFormatMedia } from "~/theme/utils/formatMedia";

import { NavItems } from "../../Footer.types";

import { GroupedLinksColumn } from "./components/GroupedLinksColumn";
import { GroupedLinksColumnMobile } from "./components/GroupedLinksColumnMobile";

interface GroupedLinksProps {
  isTabletView: boolean;
  setSelectedCountry: CountrySelectorPropsExtended["onChange"];
  selectedCountry?: CountrySelectorChildrenProps;
  countries: CountrySelectorChildrenProps[];
  groupedLinks: NavItems;
  title?: string;
  description?: string;
}

const PREFIX = "StyledGroupLinks";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledGroupLinks = styled(Grid)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      alignItems: "stretch",
      flexDirection: "column",
      marginBottom: theme.spacing(4),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        flexWrap: "nowrap",
        marginBottom: 0,
        flexDirection: "row",
        columnGap: theme.spacing(3),
      },
    },
  };
});

export const GroupedLinks = ({
  groupedLinks,
  selectedCountry,
  isTabletView,
  setSelectedCountry,
  countries,
  title,
  description,
}: GroupedLinksProps) => {
  return (
    <StyledGroupLinks container item className={classes.root} lg={12}>
      {groupedLinks?.map((category, index) =>
        isTabletView ? (
          <GroupedLinksColumnMobile
            headline={category.headline}
            links={category.links}
            key={index}
          />
        ) : (
          <GroupedLinksColumn
            key={index}
            title={title}
            links={category.links}
            headline={category.headline}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            showCountrySelector={index === 0}
            description={description}
            countries={countries}
          />
        )
      )}
    </StyledGroupLinks>
  );
};
