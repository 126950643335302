import React from "react";

import { Box, styled, Typography } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";

import { DidYouKnowProps } from "~/bff/components/DidYouKnow";
import { Markdown } from "~/components/Markdown";
import { COLOR_PASTEL_WILLOW_BROOK } from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledDidYouKnowComponent";
export const DID_YOU_KNOW_CLASSES = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  background: `${PREFIX}-background`,
};

const StyledDidYouKnowComponent = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [formatMedia.BREAKPOINT_TABLET]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    [formatMedia.BREAKPOINT_DESKTOP]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      maxWidth: "644px",
    },

    [`& .${DID_YOU_KNOW_CLASSES.title}`]: {
      textAlign: "left",
      zIndex: 1000,
      position: "relative",
      margin: "0px auto",
      ...theme.typography.h4,
      padding: "0 8px",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        ...theme.typography.h3,
      },
    },
    [`& .${DID_YOU_KNOW_CLASSES.description}`]: {
      zIndex: 0,
      padding: "16px",
      border: `8px solid ${COLOR_PASTEL_WILLOW_BROOK}`,
      position: "relative",
      marginTop: "-16px",
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_SMALL}`
      ] as CSSProperties),
      textAlign: "left",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginTop: "-24px",
        padding: "40px",
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_LARGE}`
        ] as CSSProperties),
      },
    },
    "& p": {
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_SMALL}`
      ] as CSSProperties),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_LARGE}`
        ] as CSSProperties),
      },
    },
    [`& .${DID_YOU_KNOW_CLASSES.background}`]: {
      display: "inline",
      padding: "0 16px",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: "0 40px",
      },
      backgroundColor: "white",
    },
  };
});

export const DidYouKnowComponent = ({ title, description }: DidYouKnowProps) => {
  return (
    <StyledDidYouKnowComponent
      {...getTestAutomationProps("did-you-know-component")}
      className={DID_YOU_KNOW_CLASSES.container}
    >
      <Typography className={DID_YOU_KNOW_CLASSES.title} variant="h2">
        <Box className={DID_YOU_KNOW_CLASSES.background}>{title}</Box>
      </Typography>
      {description && (
        <Markdown className={DID_YOU_KNOW_CLASSES.description} text={description} />
      )}
    </StyledDidYouKnowComponent>
  );
};
