import { ResponsiveImageConfig } from "~/components/Image";
import { AmplienceDynamicImageConfigKeys } from "~/components/Image/Image.types";

export const RESPONSIVE_IMAGE_CONFIG: ResponsiveImageConfig = {
  wideDesktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 700,
  },
  desktop: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 500,
  },
  tablet: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 500,
  },
  mobile: {
    [AmplienceDynamicImageConfigKeys.WIDTH]: 500,
  },
};

export const IMAGE_COMPONENT_NAME = "Image";

export const PDF_IMAGE_PATH = "/assets/images/pdf.png";

export const IMAGE_EXT = "png";

export const PDF_EXT = "pdf";

export const PUBLISH_DATE_FORMAT = "DD MMM YYYY";
