import React from "react";

import { Typography, TypographyProps } from "@mui/material";

export const PageTitle = ({ children, ...props }: TypographyProps) => {
  return (
    <Typography variant="h1Regular" {...props}>
      {children}
    </Typography>
  );
};
