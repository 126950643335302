import React from "react";

import { Box, Typography, styled } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";

import { HomePageProps } from "~/bff/components/HomePage";
import { ImageSource } from "~/bff/types/ImageSource";
import { Image } from "~/components/Image";
import { COLOR_WHITE } from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { PageTitle } from "../PageTitle";

const StyledHeadingImage = styled(Image)({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
});

const StyledDesc = styled(Typography)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    ...(theme.typography.body1[
      `&.${TypographyBodyClass.ARTICLE_SMALL}`
    ] as CSSProperties),
    [formatMedia.BREAKPOINT_TABLET]: {
      ...(theme.typography.body1[
        `&.${TypographyBodyClass.ARTICLE_LARGE}`
      ] as CSSProperties),
    },
  };
});

export const HeadingBanner = ({
  image,
  title,
  description,
  testAutomationId,
}: HomePageProps) => {
  const imageProps = image?.props ?? {};

  return (
    <Box
      {...getTestAutomationProps(testAutomationId ?? "heading-banner")}
      sx={{
        backgroundColor: COLOR_WHITE,
        position: "relative",
        paddingX: { xs: 2, sm: 4, lg: 8 },
      }}
    >
      <StyledHeadingImage
        src={getImageSrc(imageProps.source as ImageSource)}
        alt={prepareTextAttribute(imageProps.alt, true)}
        title={prepareTextAttribute(imageProps.title)}
        sx={{ height: { xs: 257, sm: 550 } }}
      />
      <Box
        sx={{
          backgroundColor: COLOR_WHITE,
          position: { xs: "relative", md: "absolute" },
          width: { xs: "100%", md: "37%" },
          paddingX: { xs: 0, md: 6 },
          paddingY: { xs: 3, sm: 4, md: 6 },
          top: { xs: 0, md: 48 },
        }}
      >
        <PageTitle sx={{ mb: 2 }}>{title}</PageTitle>
        <StyledDesc variant="body1">{description}</StyledDesc>
      </Box>
    </Box>
  );
};
