"use client";
import { useCallback, useEffect, useRef } from "react";

import { useRouter, usePathname } from "next/navigation";

import {
  offRouteChangeComplete,
  onRouteChangeComplete,
} from "~/components/RouterEventsHandler/RouterEvents.service";
import { useBrowserData } from "~/hooks/useBrowserData";
import { ScrollPosData, KEY_PREFIX } from "~/hooks/useSaveScrollPosition";
import { useSessionStorage } from "~/hooks/useSessionStorage";

export const useScrollRestoration = () => {
  const { getItem, removeItem } = useSessionStorage();
  const { isBrowserNavigation, setIsBrowserNavigation } = useBrowserData();
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const router = useRouter();
  const pathname = usePathname;
  const fullAnchorKey = [KEY_PREFIX, pathname].join(":");

  const restoreScrollPos = useCallback((): boolean => {
    if (!isBrowserNavigation) {
      return false;
    }

    const json = getItem(fullAnchorKey);
    const scrollPos: ScrollPosData = json ? JSON.parse(json) : undefined;
    let isPositionRestored = false;
    if (scrollPos) {
      window.scrollTo(scrollPos.x, scrollPos.y);
      removeItem(fullAnchorKey);
      isPositionRestored = true;
    }

    setIsBrowserNavigation(false);
    return isPositionRestored;
  }, [
    fullAnchorKey,
    getItem,
    removeItem,
    isBrowserNavigation,
    setIsBrowserNavigation,
  ]);

  const triggerScrollRestore = useCallback(() => {
    // This short delay helps React scroll to correct position after initial hydration
    timeout.current = setTimeout(() => {
      restoreScrollPos();
    }, 0);
  }, [restoreScrollPos]);

  const clearCurrentTimeout = useCallback(() => {
    clearTimeout(timeout.current as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    triggerScrollRestore();

    return () => {
      clearCurrentTimeout();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onRouteChangeComplete(triggerScrollRestore);

    return () => {
      clearCurrentTimeout();
      offRouteChangeComplete(triggerScrollRestore);
    };
  }, [
    router,
    removeItem,
    restoreScrollPos,
    triggerScrollRestore,
    clearCurrentTimeout,
  ]);

  return { setIsBrowserNavigation };
};
