import { isEmpty, unset, set, get, cloneDeep } from "lodash";

import { JsonLdConfig } from "~/components/JsonLd/JsonLd.types";

import { PLACEHOLDER_PATTERN } from "./jsonLd.constant";

export type ObjectType = Record<string, unknown>;

export const removeEmptyFields = (object: ObjectType): ObjectType => {
  for (const prop in object) {
    if (isEmpty(object[prop]) && typeof object[prop] !== "number") {
      unset(object, prop);
    }
    if (typeof object[prop] === "object") {
      object[prop] = removeEmptyFields(object[prop] as ObjectType);
    }
  }
  return object;
};

export const replacePlaceholders = (
  object: ObjectType,
  config?: JsonLdConfig
): ObjectType => {
  const clonedObject = cloneDeep(object);
  const validObject = removeEmptyFields(clonedObject);

  if (!config) {
    return validObject;
  }

  for (const key in config.placeholders) {
    const objectValue = get(validObject, key);
    const placeholderValue = get(config.placeholders, key);

    if (!placeholderValue || !objectValue) {
      continue;
    }

    if (objectValue === PLACEHOLDER_PATTERN) {
      set(validObject, key, placeholderValue);
      continue;
    }

    if (Array.isArray(objectValue)) {
      set(
        validObject,
        key,
        objectValue.map((value) => {
          if (typeof value === "string") {
            return (value as string).replace(PLACEHOLDER_PATTERN, placeholderValue);
          }
          if (typeof value === "object") {
            return replacePlaceholders(value, config);
          }
          return value;
        })
      );
      continue;
    }
  }

  Object.entries(validObject).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      set(validObject, key, replacePlaceholders(value as ObjectType, config));
    }
  });

  for (const key in config.overrides) {
    if (get(validObject, key)) {
      set(validObject, key, config.overrides[key]);
    }
  }

  return validObject;
};
