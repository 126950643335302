import React from "react";

import { styled, Container } from "@mui/material";

import { PDFDownloadProps } from "~/bff/components/PDFDownload";
import { getTestAutomationProps } from "~/utils/testUtils";

import { Item } from "./components/Item";

const PREFIX = "StyledPdfDownload";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledPdfDownload = styled(Container)(({ theme }) => {
  return {
    [`&.${classes.root}`]: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };
});

export const PdfDownload = ({ mediaAssets }: PDFDownloadProps) => {
  return (
    <StyledPdfDownload
      className={classes.root}
      {...getTestAutomationProps("pdf-download")}
    >
      {mediaAssets?.map((asset, index) => (
        <Item key={index} title={asset?.props?.title} {...asset?.props?.pdfProps} />
      ))}
    </StyledPdfDownload>
  );
};
