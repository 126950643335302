import React, { isValidElement, ReactElement, useCallback, useMemo } from "react";

import { styled, Container, Box, Typography, Divider } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import dayjs from "dayjs";
import { useParams } from "next/navigation";

import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { AuthorProps } from "~/bff/components/Author";
import { ImageComponent } from "~/bff/types/ImageComponent";
import { ImageSource } from "~/bff/types/ImageSource";
import { Author as AuthorComponent } from "~/components/Author";
import { CombinedLink } from "~/components/CombinedLink";
import { Image } from "~/components/Image";
import { PageTitle } from "~/components/PageTitle";
import { Share } from "~/components/Share";
import { DEFAULT_LOCALE } from "~/constants/i18n";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { TypographyBodyClass } from "~/theme/constants/typography";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Nullable } from "~/types/utility.types";
import { getImageSrc } from "~/utils/image";
import { getLinkUrl } from "~/utils/locales";
import { prepareImageA11yProps } from "~/utils/prepareImageA11yProps";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { RESPONSIVE_IMAGE_CONFIG } from "./ArticlePageHeading.constants";

export type ArticlePageHeadingProps = Omit<ArticlePageProps, "children"> & {
  layout?: "left" | "right";
  forceImageDisplay?: boolean;
  href?: Nullable<string>;
  onClick?: (deliveryId: string) => void;
  restorationRef?: React.RefObject<HTMLDivElement>;
  titleVariant?: "h1" | "h2";
};

const PREFIX = "StyledArticlePageHeading";
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  background: `${PREFIX}-background`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  description: `${PREFIX}-description`,
  author: `${PREFIX}-author`,
  footer: `${PREFIX}-footer`,
  divider: `${PREFIX}-divider`,
};

const StyledArticlePageHeading = styled(Container, {
  shouldForwardProp: (props) => !["hasImage", "layout"].includes(props as string),
})<{ hasImage: boolean; layout: ArticlePageHeadingProps["layout"] }>(
  ({ theme, hasImage, layout }) => {
    const formatMedia = getFormatMedia(theme);

    return {
      [`&.${classes.root}`]: {
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
        flexDirection: "column",
        padding: theme.spacing(0),
        marginBottom: theme.spacing(7),

        [formatMedia.BREAKPOINT_DESKTOP]: {
          flexDirection: layout === "left" ? "row-reverse" : "row",
          marginBottom: theme.spacing(8),
          padding: `0 ${theme.spacing(4)}`,
        },

        [formatMedia.BREAKPOINT_LARGE_DESKTOP]: {
          padding: `0 ${theme.spacing(8)}`,
        },
      },
      [`& .${classes.background}`]: {
        width: "100%",
        display: "flex",
        position: "relative",
        padding: `0 ${theme.spacing(2)}`,
        backgroundColor: hasImage
          ? COLOR_ADDITIONAL_WARM_GRAY_3U
          : COLOR_PRIMARY_WHITE,

        [formatMedia.BREAKPOINT_TABLET]: {
          padding: `0 ${theme.spacing(4)}`,
        },

        [formatMedia.BREAKPOINT_DESKTOP]: {
          flex: `0 0 calc(50% + ${theme.spacing(hasImage ? 1.5 : -1.5)})`,
          padding: hasImage ? `${theme.spacing(3)} 0` : 0,
        },
      },
      [`& .${classes.content}`]: {
        flex: 1,
        position: "relative",
        backgroundColor: COLOR_PRIMARY_WHITE,
        padding: hasImage ? theme.spacing(3) : 0,
        top: hasImage ? theme.spacing(-3) : 0,

        [formatMedia.BREAKPOINT_TABLET]: {
          padding: hasImage ? theme.spacing(6) : 0,
        },

        [formatMedia.BREAKPOINT_DESKTOP]: {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: hasImage ? theme.spacing(6) : 0,
          left: hasImage ? theme.spacing(layout === "left" ? 3 : -3) : 0,
          top: 0,
        },
      },
      [`& .${classes.title}`]: {
        ...theme.typography.h3,
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h2,
        },
      },
      [`& .${classes.subtitle}`]: {
        marginBottom: theme.spacing(2),
      },
      [`& .${classes.description}`]: {
        marginTop: theme.spacing(2),
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.ARTICLE_SMALL}`
        ] as CSSProperties),

        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.ARTICLE_LARGE}`
          ] as CSSProperties),
        },
      },
      [`& .${classes.image}`]: {
        flex: 1,
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
        backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,

        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },

        [formatMedia.BREAKPOINT_DESKTOP]: {
          height: "auto",
          aspectRatio: "1 / 1",
        },
      },
      [`& .${classes.author}`]: {
        marginTop: theme.spacing(2),

        [formatMedia.BREAKPOINT_TABLET]: {
          marginTop: theme.spacing(4),
        },
      },
      [`& .${classes.footer}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: theme.spacing(2.75),

        [formatMedia.BREAKPOINT_TABLET]: {
          marginTop: theme.spacing(4.25),
        },
      },
      [`& .${classes.divider}`]: {
        width: "100%",
        marginBottom: theme.spacing(3),

        [formatMedia.BREAKPOINT_DESKTOP]: {
          marginBottom: theme.spacing(6),
        },
      },
    };
  }
);

export const ArticlePageHeading = ({
  meta,
  href,
  image,
  title,
  titleVariant = "h1",
  author,
  subtitle,
  description,
  dateDisplay,
  publishDate,
  readingTime,
  shareButtonText,
  shareLinkDisplay,
  layout = "right",
  forceImageDisplay = false,
  restorationRef,
  onClick,
}: ArticlePageHeadingProps) => {
  const params = useParams();

  const articleUrl = IS_SERVER ? "" : window.location.href;
  const linkedUrl = href?.trim()
    ? getLinkUrl((params?.locale as string) ?? DEFAULT_LOCALE, href)
    : undefined;

  const imageProps = useMemo(() => {
    if (isValidElement(image)) {
      return (image as React.ReactElement<ImageComponent>)?.props?.props;
    }

    return (image as ImageComponent)?.props;
  }, [image]);

  const hasImage = forceImageDisplay || Boolean(imageProps);

  const footerText = useMemo(() => {
    let result = "";

    if (dateDisplay && publishDate) {
      result += dayjs(publishDate).format("MMM D, YYYY");
    }

    if (dateDisplay && publishDate && readingTime) {
      result += " · ";
    }

    if (readingTime) {
      result += `${readingTime} min read`;
    }

    return result;
  }, [dateDisplay, publishDate, readingTime]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(meta?.deliveryId as string);
    }
  }, [onClick, meta?.deliveryId]);

  const withLinkedUrl = useCallback(
    (element: ReactElement) => {
      if (!linkedUrl) {
        return element;
      }

      return (
        <CombinedLink
          href={linkedUrl}
          underline="none"
          {...getTestAutomationProps("heading-article-link")}
          onClick={handleOnClick}
        >
          {element}
        </CombinedLink>
      );
    },
    [linkedUrl, handleOnClick]
  );

  return (
    <StyledArticlePageHeading
      layout={layout}
      hasImage={hasImage}
      className={classes.root}
      ref={restorationRef}
      {...getTestAutomationProps("article-page-heading")}
    >
      {hasImage ? (
        <Box className={classes.image}>
          {withLinkedUrl(
            <Image
              loading="lazy"
              src={getImageSrc(imageProps?.source as ImageSource)}
              alt={prepareTextAttribute(imageProps?.alt as string, true)}
              title={prepareTextAttribute(imageProps?.title as string)}
              responsiveImageConfig={
                imageProps?.source ? RESPONSIVE_IMAGE_CONFIG : undefined
              }
              {...prepareImageA11yProps(imageProps)}
            />
          )}
        </Box>
      ) : null}
      <Box className={classes.background}>
        <Box className={classes.content}>
          {!hasImage && <Divider className={classes.divider} />}
          {withLinkedUrl(
            <>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.subtitle}
                {...getTestAutomationProps("heading-subtitle")}
              >
                {subtitle}
              </Typography>
              {/* in future updates tag of page title should be changed based on titleVariant as well */}
              <PageTitle
                className={titleVariant === "h1" ? undefined : classes.title}
                {...getTestAutomationProps("heading-title")}
              >
                {title}
              </PageTitle>
              {description && (
                <Typography
                  className={classes.description}
                  {...getTestAutomationProps("heading-description")}
                >
                  {description}
                </Typography>
              )}
            </>
          )}
          {author ? (
            <Box className={classes.author}>
              {isValidElement(author) ? (
                (author as React.ReactElement<AuthorProps>)
              ) : (
                <AuthorComponent {...author.props} onLinkClick={handleOnClick} />
              )}
            </Box>
          ) : null}
          <Box className={classes.footer}>
            <Typography
              variant="body2"
              color={COLOR_GREYSCALE_BLACK_54}
              {...getTestAutomationProps("heading-footer-text")}
            >
              {footerText}
            </Typography>
            {Boolean(shareLinkDisplay && shareButtonText) && (
              <Share url={articleUrl} label={shareButtonText ?? undefined} />
            )}
          </Box>
        </Box>
      </Box>
    </StyledArticlePageHeading>
  );
};
