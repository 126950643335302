import React from "react";

import { Box, styled } from "@mui/material";

import { GCPProps } from "~/bff/components/GCP";
import { useScrollRestoration } from "~/hooks/useScrollRestoration";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getTestAutomationProps } from "~/utils/testUtils";

import { SeoMetaTags } from "../SeoMetaTags";

const PREFIX = "StyledGcpPage";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledGcpPage = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`&.${classes.root}`]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(0),

      "& > div": {
        marginBottom: theme.spacing(7),
        marginTop: theme.spacing(0),
        width: "100%",
        "&:last-child": {
          marginBottom: theme.spacing(0),
        },
        [formatMedia.BREAKPOINT_DESKTOP]: {
          marginBottom: theme.spacing(8),
        },
      },
    },

    [`& div[data-testautomation-id="articles-carousel"]`]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },

    [`& div[data-testautomation-id="generic-heading"]`]: {
      padding: theme.spacing(0),
      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(0, 4),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(0, 8),
      },
    },

    [formatMedia.BREAKPOINT_DESKTOP]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },

    [`& div[data-testautomation-id="pdf-download"]
    ,& div[data-testautomation-id="accordion-container"]`]: {
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(0),
        maxWidth: "644px",
      },
    },
  };
});

export const GcpPage = ({
  children,
  genericHeading,
  seo,
  localizedPages,
  testAutomationId = "gcp",
}: GCPProps) => {
  useScrollRestoration();

  return (
    <StyledGcpPage
      className={classes.root}
      {...getTestAutomationProps(testAutomationId as string)}
    >
      {seo && <SeoMetaTags {...seo} />}
      {genericHeading as React.ReactElement}
      {children as React.ReactElement[]}
    </StyledGcpPage>
  );
};
