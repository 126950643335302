import React from "react";

import { styled, Box, Snackbar, SnackbarProps } from "@mui/material";

import {
  COLOR_ERROR_BACKGROUND,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";

export interface ToastProps extends SnackbarProps {
  variant?: "regular" | "error";
}

const PREFIX = "StyledToast";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledToast = styled(Box)<ToastProps>(({ theme, variant }) => {
  const formatMedia = getFormatMedia(theme);
  const backgroundColor =
    variant === "error" ? COLOR_ERROR_BACKGROUND : COLOR_PRIMARY_BLACK;
  return {
    [`&.${classes.root}`]: {
      padding: theme.spacing(1.5, 2),
      color: COLOR_PRIMARY_WHITE,
      display: "flex",
      backgroundColor,
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(2),
      },
    },
  };
});

export const Toast = ({
  variant = "regular",
  children,
  ...SnackbarProps
}: ToastProps) => {
  return (
    <Snackbar {...SnackbarProps}>
      <StyledToast className={classes.root}>{children}</StyledToast>
    </Snackbar>
  );
};
