import React, { createContext, useState } from "react";

export type NavigationMode = "mouse" | "keyboard";

interface NavMenuLandscapeContextType {
  navigationMode: NavigationMode;
  setNavigationMode: (mode: NavigationMode) => void;
}

export const DEFAULT_CONTEXT: NavMenuLandscapeContextType = {
  navigationMode: "mouse",
  setNavigationMode: () => {
    return;
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NavMenuLandscapeContext =
  createContext<NavMenuLandscapeContextType>(DEFAULT_CONTEXT);

interface NavMenuLandscapeProviderProps {
  children: React.ReactNode;
}

export const NavMenuLandscapeProvider = ({
  children,
}: NavMenuLandscapeProviderProps) => {
  const [context, setContext] = useState<NavMenuLandscapeContextType>({
    navigationMode: DEFAULT_CONTEXT.navigationMode,
    setNavigationMode: (mode) =>
      setContext((state) => {
        if (state.navigationMode !== mode) {
          return { ...state, navigationMode: mode };
        }

        return state;
      }),
  });

  return (
    <NavMenuLandscapeContext.Provider value={context}>
      {children}
    </NavMenuLandscapeContext.Provider>
  );
};
